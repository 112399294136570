import React from 'react';
import styled from 'styled-components';

import IconButton from '../libs/IconButtonV2';

import app from '../appData';
import SamModal from '../libs/SamModalV2';
import { formatImageUrl } from '../libs/ImageFormatter';

const width = window.innerWidth;

const Container = styled.div`
    width: ${width}px;
    max-width: 500px;
    padding: 16px;
    background-color: ${app.themes.backColor25}; 
`
const TextContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font: 40px "Dancing Script";
    line-height: 50px;
`
export interface ThankYouProps {
    isGiftCertificate: boolean;
    freightQuoteRequested?: boolean;
}
const ThankYouModal: React.FC<ThankYouProps> = (props) => {
    return (
        <SamModal component={ThankYou as React.FC} componentProps={props} />
    )
}
// top={(window.innerHeight - 560) / 2} left={(window.innerWidth - width - 32) / 2}
const ThankYou: React.FC<ThankYouProps> = (props) => {
    const onSubmit = () => {
        window.location.href = "/";
    }
    return (
        <Container>
            <TextContainer>
                {props.freightQuoteRequested ? (
                    <p>Your order has been submitted. You will receive a freight quote shortly with a link to complete the order.
                        Thank you for supporting the artists of America and the world.
                    </p>
                ) : (
                    <p>
                        {"Your " + (props.isGiftCertificate ? "gift certificate purchase" : "order")
                            + " has been submitted. You will receive a confirming email shortly, and "
                            + (props.isGiftCertificate ? "the gift certificate will be emailed to the recipient." : "another email upon shipment.")
                            + " Thank you for supporting the artists of America and the world."}
                    </p>
                )}
                <img src={formatImageUrl("fern-sig.gif", { graphicsSubfolder: "other" })} alt="Signed by Fern" />
                <IconButton style={{ marginTop: "32px", backgroundColor: app.themes.backColor10 }} caption="Return to home page" icon="fas fa-home" onClick={onSubmit} />
            </TextContainer>
        </Container>
    )
}
export default ThankYouModal;
