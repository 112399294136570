import React from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import MasterPage from '../components/MasterPage';
import { useFetchApi, genericApiError } from '../libs/useDataApiV2';
import useFGShoppingCart from '../libs/shopping-cart/useFGShoppingCart';
import useFGOrder from '../libs/shopping-cart/useFGOrder';
import { StyledErrorText } from '../libs/libSupport';
import { FGFreightQuoteRecord, FGOrderWithCartRecord, ShipMethodEnum } from '../interfaces/fg-api-interfaces';
import IconButton from '../libs/IconButtonV2';

import api from '../api-url';
import app from '../appData';

const MasterContainer = styled.div`
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
`
const Selections = styled.div`
    display: flex;
    flex-direction: column;
`
const SelectionRow = styled.div`
    display: flex;
    height: 50px;
    align-content: center;
    p {
        margin: 0;
        margin-top: 8px;
    }
`
const NoteToCust = styled.div`
    width: 100%;
    background-color: ${app.themes.backColor25};
    box-shadow: 3px 3px 5px 6px #ccc;
    padding: 16px;
    margin-bottom: 24px;
    p {
        margin: 8px;
        text-align: left;
    }
`

const FreightQuote: React.FC = () => {
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const [freightQuotes, setFreightQuotes] = React.useState<FGFreightQuoteRecord>();
    const [chosenShipMethod, setChosenShipMethod] = React.useState<ShipMethodEnum>();

    const { fetch } = useFetchApi();
    const order = useFGOrder();
    const cart = useFGShoppingCart();
    const navigate = useNavigate();
    const params = useParams();
    const orderId = params.orderId as string;

    const orderReceived = (result: FGOrderWithCartRecord) => {
        order.setOrder(result.order);
        cart.setItems(result.shopping_cart);
        setFreightQuotes(result.order.extras.freight_quotes);
    }

    React.useEffect(() => {
        fetch(api.loadAnyOrder + orderId, null, orderReceived,
            () => setErrorMsg('Order not found -- please double check the browser address and try again;<br />'
                + 'If you still have trouble please <a href="/contact-us"><u>contact us</u></a> or call 510-529-4848'));
    }, []);

    React.useEffect(() => {
        if (chosenShipMethod) {
            order.setShipMethod(chosenShipMethod);
            navigate('/checkout');
        }
    }, [chosenShipMethod]);

    const quoteClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
        const id = (e.target as HTMLButtonElement).id;
        if (id === "expedited") {
            setChosenShipMethod(ShipMethodEnum.expedited);
        } else {
            setChosenShipMethod(ShipMethodEnum.regular);
        }
    }

    const buttonStyles = { marginLeft: "8px", height: "30px" };

    return (
        <MasterPage>
            <h1>Choose a Shipping Method for Your Order</h1>
            {errorMsg && <StyledErrorText dangerouslySetInnerHTML={{ __html: errorMsg }} />}
            <MasterContainer>
                {freightQuotes &&
                    <Selections>
                        <p><b>Please select one of the following shipping methods:</b></p>
                        <SelectionRow>
                            <p>Regular shipping will cost ${freightQuotes.regular.toFixed(2)}</p>
                            <IconButton style={buttonStyles} id="regular" caption="Use regular shipping" onClick={quoteClicked} />
                        </SelectionRow>
                        <SelectionRow>
                            <p>Expedited shipping will cost ${freightQuotes.expedited.toFixed(2)}</p>
                            <IconButton style={buttonStyles} id="expedited" caption="Use expedited shipping" onClick={quoteClicked} />
                        </SelectionRow>
                        {freightQuotes.comment &&
                            <NoteToCust>
                                <p><b>Note from Fern's Garden:</b></p>
                                <p>{freightQuotes.comment}</p>
                            </NoteToCust>
                        }
                    </Selections>
                }
            </MasterContainer>
        </MasterPage>
    )
}
export default FreightQuote;