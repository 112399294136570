import React from 'react'
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

import MasterPage from '../../components/MasterPage';
import useFGShoppingCart from './useFGShoppingCart';
import useFGOrder from './useFGOrder';
import CartPopup, { CartSummaryBox } from './CartPopup';
import IconButton, { ButtonsRow } from '../IconButtonV2';
import SamForm from '../forms/SamFormV5';
import { StyledErrorText, iPhoneWidth, iPadMaxWidth } from '../libSupport';
import { doLogout, LoginForm } from '../../components/FGCustomer';
import AddressEditor, { renderAddressSummary } from '../forms/AddressEditor';
import addr from '../api-shared/address-support';
import useAddressBook, { AddressChooser } from '../useAddressBook';
import { useTokens, useBookmarks, useSessionStore, useGlobalContext } from '../SamState';
import { usePostApi, genericApiError } from '../useDataApiV2';
import useCreditCards from '../forms/CreditCardsV2';
import useFGCustomer from '../../components/FGCustomer';
import PayAndSubmit from './PayAndSubmit';
import ThankYou, { ThankYouProps } from '../../components/ThankYou';

import { VoucherRecord, CouponRecord, ExtrasRecord, SubmitOrderResult, SubmitOrderReturnStatus, VoucherError, ShipMethodEnum, SubmitOrderRecord } from '../../interfaces/fg-api-interfaces';
import { AddressType, FormFieldType, FormFieldRecord, CouponStatus } from '../../interfaces/lib-api-interfaces';

import app from '../../appData';
import api from '../../api-url';
import deepcopy from 'deepcopy';
import FormMgr from '../forms/FormMgr';

const allowFreightQuoteRequests = false;

//const innerWidth = window.innerWidth;

// checkout container on left, order summary on right
const MasterContainer = styled.div`
    max-width: 890px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;
`
// checkout steps as rows
const CheckoutContainer = styled.div<{ $fontFamily: string }>`
    max-width: ${window.innerWidth - 10}px;
    display: flex;
    flex-direction: column;
    font-family: ${props => props.$fontFamily};      /* app.themes.sansFonts */
    font-size: 12px;
    p {
        text-align: left;
        margin-left: 8px;
    }
    input {
        font-size: 14px;
    }
    h3 {
        font-size: 24px;
        text-align: left;
        font-weight: regular;
        margin-left: 4px;
    }
`
// holds step # in circle on left, form on right
const CheckoutStepContainer = styled.div<{ $noBorder?: boolean; $flexDirection: string }>`
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: flex-start;
    flex-direction: ${props => props.$flexDirection};
    align-items: left;
    margin-bottom: 16px;
    border-bottom: ${props => props.$noBorder ? "none" : "1px solid #ccc"};
`
// child editors use the following to derive step nos. and use caption as id in Form Mgr
enum StepId { cust = '1', ship = '2', bill = '3', extras = '4', payAndSubmit = '5' };
interface StepStatusRecord {
    id: StepId;
    caption: string;
    isOpen?: boolean;
    allowEditing?: boolean;     // if any other form is open do not allow editing on this one
}
interface StepCaptionRecord {
    id: StepId;
    caption: string;
}
const stepCaptions: StepCaptionRecord[] = [
    { id: StepId.cust, caption: "Customer" },
    { id: StepId.ship, caption: "Shipping" },
    { id: StepId.bill, caption: "Billing" },
    { id: StepId.extras, caption: "Extras" },
    { id: StepId.payAndSubmit, caption: "Pay and Submit" }
];

interface StepsRecord {
    [key: string]: StepStatusRecord;      // all steps as a single object
}

const Checkout: React.FC = () => {
    const [steps, setSteps] = React.useState<StepsRecord>();
    // const [currentStep, setCurrentStep] = React.useState<StepId>(StepId.cust);         // step.id; e.g. "cust"
    const [dummy, setForceReRender] = React.useState(0);
    const [errorMsg, setErrorMsg] = React.useState<string | null>(null);
    const [orderSubmitted, setOrderSubmitted] = React.useState(false);
    const [showFullPageCart, setShowFullPageCart] = React.useState(false);      // mobile devices only

    const { post } = usePostApi();
    const cc = useCreditCards();
    const cust = useFGCustomer();
    const order = useFGOrder();
    const cart = useFGShoppingCart();
    const addressBook = useAddressBook();

    const isVerySmallWindow = window.matchMedia("(max-width: " + iPhoneWidth + "px)").matches;
    const isSmallWindow = window.matchMedia("(max-width: " + iPadMaxWidth + "px)").matches;

    // this happens first, so init code goes here
    const orderTotalsVerified = (result: SubmitOrderResult) => {
        if (result.status === SubmitOrderReturnStatus.totalsMismatch) {
            order.setOrder(result.order!);
            cart.setItems(result.shopping_cart!);
        }
        order.recalcTotals();
        const _steps = {} as StepsRecord;
        for (const step of stepCaptions) {
            _steps[step.id] = { id: step.id, caption: step.caption };     // isOpen initialized to false by keeping it undefined
        }
        findAndSetNextStep(_steps);
    }

    React.useEffect(() => {
        post(api.verifyOrderTotals, { order: order.getOrder(), shopping_cart: cart.getItems() }, orderTotalsVerified, () => setErrorMsg(genericApiError));
    }, []);

    // // returns open form; if no open forms returns null
    // const getStepEditing = (): StepId | null => {
    //     for (const id in steps) {
    //         if (steps[id].isOpen) {
    //             return id as StepId;
    //         }
    //     }
    //     return null;
    // }

    const updateEditingState = (id: StepId, isEditing: boolean) => {
        // console.log("updateEditingState (" + id + "," + isEditing + "), currentStep=" + currentStep)
        // console.log("values=", forms.getFormValues(id))
        // console.log("calling handleCompleted for " + currentStep)

        const newSteps = deepcopy(steps) as StepsRecord;
        newSteps[id].isOpen = isEditing;
        if (isEditing) {
            // user wants to open this one so disallow editing on others
            updateReadOnlyStatus(newSteps);
            setSteps(newSteps);
        } else {
            // user just closed this one so automatically open next one (if any)
            findAndSetNextStep(newSteps);
        }
    }

    // make sure all steps are editable if none are currently open
    const updateReadOnlyStatus = (newSteps: StepsRecord) => {
        let allowEditing = true;
        for (const step in newSteps) {
            if (newSteps[step].isOpen) {
                allowEditing = false;
            }
        }
        for (const step in newSteps) {
            newSteps[step].allowEditing = allowEditing;
        }
    }

    const handleLogin = () => {
        updateEditingState(StepId.cust, false);
    }

    // call this upon entry or re-entry to automatically open the next needed editing step
    // make sure newSteps is a copy of steps stored in state
    const findAndSetNextStep = (newSteps: StepsRecord) => {
        const id = findNextStep();
        if (id) {
            // there is a next step needed so open it
            newSteps[id].isOpen = true;
        }
        updateReadOnlyStatus(newSteps);
        setSteps(newSteps);
    }

    const findNextStep = (): StepId | null => {
        // this determines which step is set to "edit" mode; user must always initiate edit on "Extras"
        //    console.log("order has results: " + order.getBillEmail() + ", " + order.haveAddress(AddressType.ship) + ", " + order.haveAddress(AddressType.bill) + ", " + order.haveExtras())
        let nextStep: StepId | null;
        if (!order.getBillEmail()) {
            nextStep = StepId.cust;
        } else if (!order.haveAddress(AddressType.ship)) {
            nextStep = StepId.ship;
        } else if (!order.haveAddress(AddressType.bill)) {
            nextStep = StepId.bill;
            // extras could never open for editing by default -- as of now it does open automatically
        } else {
            nextStep = null;
        }
        return nextStep;
    }
    const forceRedisplay = () => {
        setForceReRender(state => state + 1);
    }
    return (
        <MasterPage>
            <h1>Check Out</h1>
            <MasterContainer>
                {showFullPageCart ? (
                    <CartPopup scrollToTop={true} closeClicked={() => setShowFullPageCart(false)} totalChanged={forceRedisplay} />
                ) : (
                    steps &&
                    <React.Fragment>
                        <CheckoutContainer $fontFamily={app.themes.sansFonts!}>
                            {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
                            <CustForm updateEditingState={updateEditingState} status={steps[StepId.cust]}
                                handleLogin={handleLogin} />
                            <AddressForm updateEditingState={updateEditingState} status={steps[StepId.ship]}
                                addressType={AddressType.ship} />
                            <AddressForm updateEditingState={updateEditingState} status={steps[StepId.bill]}
                                addressType={AddressType.bill} />
                            <ExtrasForm updateEditingState={updateEditingState} status={steps[StepId.extras]}
                                forceRedisplay={forceRedisplay} />
                            <PayAndSubmitForm updateEditingState={updateEditingState} status={steps[StepId.payAndSubmit]} />
                        </CheckoutContainer>
                        {isSmallWindow ? (
                            <CartSummaryBox viewCartClicked={() => setShowFullPageCart(true)} />
                        ) : (
                            <CartPopup totalChanged={forceRedisplay} />
                        )}
                    </React.Fragment>
                )}
            </MasterContainer>
        </MasterPage>
    )
}

/* all form components props:
    currentStep: string -- used by component to determine if his editor should be open
    id: string (also used as caption)
    updateEditingState(id, isEditing) -- true if user clicked "edit" button, false if user clicked "continue" button

    form values:
        CustForm
            email: string
        AddressForm
            address: Address
        ExtrasForm
            { comments: string, gift_message: string, coupon: string }
        PayAndSubmitForm
            { cardNumber: string, expiry: string, cvv: string, zip: string }
*/
interface StepFormProps {
    status: StepStatusRecord;
    updateEditingState: (id: StepId, isEditing: boolean) => void;
}
const CheckoutFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    h4 {
        font-size: 20px;
        text-align: left;
        margin-left: 16px;
        margin-bottom: 4px;
    }
`
// holds label, form summary, edit button
const FormClosedContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`
// displays next to label and in middle of box if form has been completed and is not being edited
const FormValuesSummary = styled.div`
    text-align: left;
    margin-top: 12px;
    margin-bottom: 8px;
    margin-left: 24px;
    p {
        margin-top: 0;
        margin-bottom: 0;
    }
`
const editButtonStyle = { width: "100px" };

//-----------------------------------------------------------------
/* props: 
    (see above)
    handleLogin() -- called after user logs in
*/
interface CustFormProps extends StepFormProps {
    handleLogin: () => void;
}
const CustForm: React.FC<CustFormProps> = (props) => {
    const [showLoginForm, setShowLoginForm] = React.useState(false);
    const [loginError, setLoginError] = React.useState<number>(200);

    const navigate = useNavigate();
    const order = useFGOrder();
    const { getToken, clearToken } = useTokens();
    const { setBookmark } = useBookmarks();
    const { deleteSessionStore } = useSessionStore();
    const { setContext } = useGlobalContext();
    const forms = new FormMgr(setContext);

    const emailFormFields: FormFieldRecord[] = [{ name: "email", label: "Email address", validator: { required: true, maxLength: 150 } }];
    const buttonStyle = { marginRight: "8px", marginLeft: "8px", height: "40px", fontSize: "14px", padding: "4px" };
    const submitButtons = [
        { id: "guest", caption: "Continue as guest", icon: "", validate: true, style: buttonStyle },
        { id: "login", caption: "Sign in", icon: "", validate: false, style: buttonStyle },
        { id: "register", caption: "Register now", icon: "", validate: false, style: buttonStyle }
    ];
    const handleSubmit = (values: Record<string, any> | null, id: string) => {
        if (values) {
            if (id === "guest") {
                // wants to continue as guest
                order.setBillEmail(values!.email);
                props.updateEditingState(props.status.id, false);
            } else {
                // wants to login
                setBookmark();
                if (id === "register") {
                    navigate("/register");
                } else {
                    setShowLoginForm(true);
                }
            }
        }
    }

    const editOrSignOut = () => {
        if (getToken()) {
            doLogout(clearToken, deleteSessionStore);
        } else {
            props.updateEditingState(props.status.id, true);
        }
    }
    const handleLoginResult = (result: boolean) => {
        setShowLoginForm(false);
        if (result) {
            setLoginError(200);
            // user is now logged in
            props.handleLogin();
        }
    }
    return (
        <CheckoutStepContainer $flexDirection={props.status.isOpen ? "column" : "row"}>
            <StepIcon stepNum={props.status.id} caption={props.status.caption}
                isCompleted={!!order.getBillEmail()}
                allowEditing={props.status.allowEditing}
                editCaption={getToken() ? "Sign out" : "Edit or log in"}
                editClicked={props.status.isOpen ? undefined : editOrSignOut}
            />
            <CheckoutFormContainer>
                {props.status.isOpen ? (
                    <React.Fragment>
                        {showLoginForm ? (
                            <LoginForm handleResult={handleLoginResult} />
                        ) : (
                            <React.Fragment>
                                <SamForm id={props.status.id + ''} fields={emailFormFields} initialValues={{ email: order.getBillEmail() }}
                                    submitButtons={submitButtons} handleSubmit={handleSubmit} />
                                <p>
                                    If you are registered, sign in now to speed up checkout and apply this purchase to <Link to="/rewards"><u>Fern's Garden Rewards</u></Link>. If
                                    you have not registered,
                                    you can do so now and enjoy the benefits, including Fern's Garden Rewards, quicker checkout, multiple ship addresses, order tracking and more.
                                </p>
                            </React.Fragment>
                        )}

                    </React.Fragment>
                ) : (
                    <FormClosedContainer>
                        <FormValuesSummary>
                            {order.getBillEmail()}
                        </FormValuesSummary>
                    </FormClosedContainer>
                )}
            </CheckoutFormContainer>
        </CheckoutStepContainer>
    )
}
//-----------------------------------------------------------------
const ExpeditedCheckbox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    margin-top: 8px;
    margin-left: 4px;
`
interface AddressFormProps extends StepFormProps {
    addressType: AddressType;
}
const AddressForm: React.FC<AddressFormProps> = (props) => {
    const [expeditedChecked, setExpeditedChecked] = React.useState(false);

    const order = useFGOrder();
    const addressBook = useAddressBook();
    const { getToken } = useTokens();
    const { setContext } = useGlobalContext();
    const forms = new FormMgr(setContext);

    const savedAddressChosen = (addressId: number) => {
        //      console.log("chose address id " + addressId + ":", addressBook.getAddress(props.addressType, addressId));
        const saveAddress = forms.getValue(props.status.id, "saveAddress");
        const useAsBillAddress = forms.getValue(props.status.id, "useAsBillAddress");
        forms.setFormValues(props.status.id, { ...addressBook.getAddress(props.addressType, addressId), saveAddress, useAsBillAddress });
    }

    const customAddressValidator = (field: string, values: Record<string, any>): string | null => {
        if (field === "phone" && values.useAsBillAddress && !values.phone) {
            return "Phone is required on billing address";
        }
        return null;
    }
    const handleContinue = () => {
        if (!forms.validateForm(props.status.id, customAddressValidator)) {
            return;
        }
        const values = forms.getFormValues(props.status.id);
        const address = addr.collect(values);
        const addressType = props.status.id === StepId.ship ? AddressType.ship : AddressType.bill;
        if (values.saveAddress) {
            if (!addressBook.includes(addressType, address)) {
                addressBook.addNewAddress(addressType, address);
                addressBook.saveAddressBook(addressType, getToken()!.token, null, () => alert("We were unable to save your addess to your address book"));
            }
        }
        if (values.useAsBillAddress) {
            order.setAddress(address, AddressType.bill);
        }
        order.setAddress(address, addressType);
        if (expeditedChecked || (addressType === AddressType.ship && addr.isNotUSA(address))) {
            order.setShipMethod(ShipMethodEnum.quoteRequested);
        } else if (order.getExtras().ship_method === ShipMethodEnum.quoteRequested) {
            order.setShipMethod(ShipMethodEnum.regular);
        }
        props.updateEditingState(props.status.id, false);
    }
    const handleCancel = () => {
        props.updateEditingState(props.status.id, false);
    }
    const excludedFields = ["storefront", "res_del", "email"];
    if (!getToken()) {
        excludedFields.push("is_default");
    }
    const addedFields = [] as FormFieldRecord[];
    if (props.addressType === AddressType.ship) {
        addedFields.push({ type: FormFieldType.checkbox, name: "useAsBillAddress", label: "Use this as my billing address" });
    }
    if (getToken()) {
        addedFields.push({ type: FormFieldType.checkbox, name: "saveAddress", label: "Save this address in my address book" });
    }
    const editClicked = () => {
        props.updateEditingState(props.status.id, true);
    }
    const wantsExpeditedCheckedChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target as any;
        setExpeditedChecked(target.checked as boolean);
    }

    return (
        <CheckoutStepContainer $flexDirection={props.status.isOpen ? "column" : "row"}>
            <StepIcon stepNum={props.status.id} caption={props.status.caption} isCompleted={!addr.isEmpty(order.getAddress(props.addressType))} allowEditing={props.status.allowEditing}
                editClicked={props.status.isOpen ? undefined : editClicked} />
            <CheckoutFormContainer>
                {props.status.isOpen ? (
                    <React.Fragment>
                        {props.addressType === AddressType.ship &&
                            <p>Free or $7 shipping does not apply outside U.S.A.; for shipments abroad please submit your order and we will email you with
                                a freight quote and link to choose your shipping method and complete the order.
                            </p>
                        }
                        {getToken() && addressBook.getAddresses(props.addressType).length > 1 &&
                            <AddressChooser type={props.addressType} onSubmit={savedAddressChosen} />
                        }
                        <AddressEditor id={props.status.id} values={order.getAddress(props.addressType)} excludedFields={excludedFields}
                            addedFields={addedFields} addressType={props.addressType} phoneRequired={props.addressType === AddressType.bill} />
                        {props.addressType === AddressType.ship && allowFreightQuoteRequests &&
                            <ExpeditedCheckbox>
                                <input type="checkbox" value='' checked={expeditedChecked} onChange={wantsExpeditedCheckedChanged} />
                                &nbsp;&nbsp;<span>Check here for expedited shipping. After you submit your order, we will email you with a freight quote
                                    and a link to choose your shipping method and complete the order.</span>
                            </ExpeditedCheckbox>
                        }
                        <ButtonsRow>
                            <IconButton caption={"CONTINUE"} onClick={handleContinue} />
                            <IconButton caption={"Cancel changes"} onClick={handleCancel} />
                        </ButtonsRow>
                    </React.Fragment>
                ) : (
                    <FormClosedContainer>
                        <FormValuesSummary>
                            {renderAddressSummary(order.getAddress(props.addressType))}
                        </FormValuesSummary>
                    </FormClosedContainer>
                )}
            </CheckoutFormContainer>
        </CheckoutStepContainer>
    )
}
// style={{ fontWeight: "bold", width: "200px", height: "34px", margin: "20px auto 20px auto" }} 
//-----------------------------------------------------------------
/* props:
    (see above)
    forceRedisplay() - force redisplay when coupon/voucher applied
*/
const ConfirmationText = styled.p`
    text-align: center;
    width: 100%;
    font-size: 18px;
`
const RewardsBar = styled.div`
    font-size: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 36px;
`
interface ExtrasFormProps extends StepFormProps {
    forceRedisplay: () => void;
}
const ExtrasForm: React.FC<ExtrasFormProps> = (props) => {
    const [errorMsg, setErrorMsg] = React.useState<string>('');
    const [confirmationMsg, setConfirmationMsg] = React.useState<string>('');

    const navigate = useNavigate();
    const { post } = usePostApi();
    const { getToken } = useTokens();
    const { setBookmark } = useBookmarks();
    const order = useFGOrder();
    const { setContext } = useGlobalContext();
    const forms = new FormMgr(setContext);

    const inlineClicked = (fieldName: string, value: string) => {
        if (fieldName === "coupon") {
            applyCoupon(value);
        } else {
            applyVoucher(value);
        }
    }
    const decodeCouponError = (status: CouponStatus): string => {
        if (status === CouponStatus.expired) {
            return "Coupon expired";
        } else if (status === CouponStatus.invalidCode) {
            return "Coupon invalid";
        }
        return "OK";
    }
    const decodeVoucherError = (status: VoucherError): string => {
        if (status === VoucherError.voucherUsed) {
            return "Gift certificate has been redeemed";
        } else if (status === VoucherError.notFound || status === VoucherError.invalidCode) {
            return "Invalid gift certificate"
        }
        return "OK";
    }
    const applyCoupon = (coupon: string) => {
        setErrorMsg('');
        setConfirmationMsg('');
        post(api.applyCoupon, { coupon }, couponApplied, () => setErrorMsg(genericApiError));
    }
    const applyVoucher = (voucher_code: string) => {
        setErrorMsg('');
        setConfirmationMsg('');
        post(api.applyVoucher, { voucher_code }, voucherApplied, () => setErrorMsg(genericApiError));
    }

    const extrasFields: FormFieldRecord[] = [
        { name: "coupon", label: "Coupon code:", type: FormFieldType.button, width: 99, validator: { maxLength: 12 }, button: { caption: "Apply", icon: "fas fa-check" } },
        //   { name: "voucher", label: "Gift certificate:", type: FormFieldType.voucher, button: { caption: "Apply" } },
        { name: "voucher", label: "Gift certificate:", type: FormFieldType.button, width: 99, validator: { maxLength: 15 }, button: { caption: "Apply", icon: "fas fa-check" } },
        { name: "gift_wrap", label: "Gift wrap this order", type: FormFieldType.checkbox },
        {
            name: "gift_message", label: '<span style="font-weight: bold; font-size: 1.2em">Gift message</span> (up to 500 characters):', type: FormFieldType.multiLine,
            width: 99, size: { height: 100 }, validator: { maxLength: 500 }, placeholder: "Enter an optional gift message here"
        },
        {
            name: "comments", label: "Notes to Fern's Garden (up to 500 characters):", type: FormFieldType.multiLine, size: { height: 100 },
            width: 99, validator: { maxLength: 500 }, placeholder: "Enter any notes or comments for us here"
        }
    ];
    const extras = order.getExtras();
    const extrasValues = {
        coupon: extras.coupon && extras.coupon.coupon,
        gift_wrap: extras.gift_wrap,
        gift_message: extras.gift_message,
        voucher: extras.voucher_applied && extras.voucher_applied.voucher_code,
        comments: extras.comments
    }
    const formatSummary = (text: string | undefined) => {
        let summary = text ? text.substring(0, 100) : "None";
        if (summary.length > 50) {
            summary += "...";
        }
        return summary;
    }
    const couponApplied = (result: any, status: number | undefined) => {
        if (status! > 200) {
            forms.setValue(props.status.id, "coupon", '');
            order.cancelCoupon();
            setErrorMsg(decodeCouponError(status as CouponStatus));
        } else {
            order.applyCoupon(result as CouponRecord);
            setConfirmationMsg("Your coupon has been applied");
        }
        props.forceRedisplay();
    }
    const voucherApplied = (result: any, status: number | undefined) => {
        if (status! > 200) {
            forms.setValue(props.status.id, "voucher", '');
            order.cancelVoucher();
            setErrorMsg(decodeVoucherError(status as VoucherError))
        } else {
            order.applyVoucher(result as VoucherRecord);
            setConfirmationMsg("Your gift certificate has been applied");
            props.forceRedisplay();
        }
    }
    async function handleContinue(e: React.MouseEvent<HTMLButtonElement>) {
        const values = forms.getFormValues(props.status.id + '');
        order.setExtras(values as ExtrasRecord);
        props.updateEditingState(props.status.id, false);
    }
    let rewardsStatus;
    let showJoinButton = false;
    if (!getToken()) {
        rewardsStatus = "Not logged in";
    } else if (!extras.rewards) {
        rewardsStatus = "Not a member";
        showJoinButton = true;
    } else {
        rewardsStatus = "Active";
    }
    const editClicked = () => {
        props.updateEditingState(props.status.id, true);
    }
    const rewardsButtonClicked = () => {
        setBookmark('/checkout');
        navigate("/rewards");
    }
    return (
        <CheckoutStepContainer $flexDirection={props.status.isOpen ? "column" : "row"}>
            <StepIcon stepNum={props.status.id} caption={props.status.caption} isCompleted={order.haveExtras()} allowEditing={props.status.allowEditing}
                editClicked={props.status.isOpen ? undefined : editClicked} />
            <CheckoutFormContainer>
                {props.status.isOpen ? (
                    <React.Fragment>
                        {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
                        {confirmationMsg && <ConfirmationText>{confirmationMsg}</ConfirmationText>}
                        <RewardsBar>
                            <span>Rewards status: {rewardsStatus}</span>
                            {showJoinButton && <IconButton style={{ marginLeft: "8px", height: "28px" }} caption="Join now" icon="fas fa-check" onClick={rewardsButtonClicked} />}
                        </RewardsBar>
                        <SamForm id={props.status.id + ''} fields={extrasFields} initialValues={extrasValues}
                            inlineButtonClicked={inlineClicked} />
                        <IconButton style={{ fontWeight: "bold", width: "200px", height: "34px", margin: "20px auto 20px auto" }} caption={"CONTINUE"}
                            onClick={handleContinue} />
                    </React.Fragment>
                ) : (
                    <FormClosedContainer>
                        <FormValuesSummary>
                            <p><b>Rewards status:</b> {rewardsStatus}</p>
                            <p><b>Coupon code:</b> {formatSummary(extrasValues.coupon)}</p>
                            <p><b>Gift certificate:</b> {formatSummary(extrasValues.voucher)}</p>
                            <p><b>Gift wrap:</b> {extrasValues.gift_wrap ? "Yes" : "No"}</p>
                            <p><b>Gift message:</b> {formatSummary(extrasValues.gift_message)}</p>
                            <p><b>Order comments:</b> {formatSummary(extrasValues.comments)}</p>
                        </FormValuesSummary>
                    </FormClosedContainer>
                )}
            </CheckoutFormContainer>
        </CheckoutStepContainer >
    )
}
//-----------------------------------------------------------------
/* props:
    (see above)

    fields usePaypal, saveCard, useExisting will be in Form Mgr storage
*/
const QuoteRequested = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    margin-bottom: 16px;
`
const OrderTotalText = styled.p`
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    margin: 4px;
`
const PayAndSubmitForm: React.FC<StepFormProps> = (props) => {
    const [showThankYou, setShowThankYou] = React.useState<ThankYouProps>();
    const [postOrderWithFreightRequest, setPostOrderWithFreightRequest] = React.useState(false);

    const order = useFGOrder();
    const cart = useFGShoppingCart();
    const { getToken } = useTokens();
    const { post } = usePostApi();
    const orderTotal = order.getTotals().inv_tot;
    const extras = order.getExtras();

    const shipAddress = order.getAddress(AddressType.ship);
    if (addr.isNotUSA(shipAddress) && !order.getTotals().fixed_freight) {
        order.setShipMethod(ShipMethodEnum.quoteRequested);
    }

    let quoteRequestedText = '';
    if (extras.ship_method && extras.ship_method === ShipMethodEnum.quoteRequested) {
        const shipAddress = order.getAddress(AddressType.ship);
        quoteRequestedText = (addr.isNotUSA(shipAddress) ? "This order is shipping outside of USA" : "You requested a quote for expedited shipment")
            + ". Upon placing your order we will send you an email with freight quote and link to choose your shipping method, pay for and complete the order. "
            + "Please allow a day or so to receive the email. You will not be charged until that time.";
    }

    const orderCompleted = () => {
        setShowThankYou({ isGiftCertificate: false, freightQuoteRequested: !!(extras.ship_method && extras.ship_method == ShipMethodEnum.quoteRequested) });
        order.clear();
    }

    React.useEffect(() => {
        if (postOrderWithFreightRequest) {
            setPostOrderWithFreightRequest(false);
            post(api.saveOrder, { order: order.getOrder(), shopping_cart: cart.getItems() },
                orderCompleted, () => alert("We are so sorry but our server is misbehaving. Please try again later."), getToken() ? getToken()!.token : null);
        }
    }, [postOrderWithFreightRequest]);

    return (
        <CheckoutStepContainer $flexDirection="column" $noBorder={true}>
            <StepIcon stepNum={props.status.id} caption={props.status.caption} isCompleted={false} allowEditing={props.status.allowEditing} />
            <CheckoutFormContainer>
                <OrderTotalText>Order total: ${orderTotal.toFixed(2)}</OrderTotalText>
                {quoteRequestedText ? (
                    <QuoteRequested>
                        <p>{quoteRequestedText}</p>
                        <IconButton caption="Submit my order and send me a freight quote" onClick={() => setPostOrderWithFreightRequest(true)} />
                    </QuoteRequested>
                ) : (
                    <ShowMissingInfoOrPaymentForm {...props} setShowThankYou={setShowThankYou} />
                )}
            </CheckoutFormContainer>
            {showThankYou && <ThankYou {...showThankYou} />}
        </CheckoutStepContainer>
    )
}
// following is part of PayAndSubmitForm: if order is ready for payment, the payment form is shown, else a list of missing info is shown
interface ShowMissingInfoOrPaymentFormProps extends StepFormProps {
    setShowThankYou: (state: ThankYouProps) => void;
}
const ShowMissingInfoOrPaymentForm: React.FC<ShowMissingInfoOrPaymentFormProps> = (props) => {
    const order = useFGOrder();
    const cart = useFGShoppingCart();
    const orderTotal = order.getTotals().inv_tot;
    const missing = order.validateOrder();
    const orderCompleted = () => {
        order.clear();
        props.setShowThankYou({ isGiftCertificate: false, freightQuoteRequested: false });
    }
    
    if (missing.length > 0) {
        return (
            <PaymentMissingItems missing={missing} />
        )
    } else if (cart.itemCount() === 0) {
        return (
            <PaymentMessage>Your cart is empty. Please continue shopping and return to checkout.</PaymentMessage>
        )
    } else {
        return (
            <>
                {orderTotal < 0 && <PaymentCreditBalance billEmail={order.getBillEmail()} />}
                <PayAndSubmit isGiftCertificate={false} orderCompleted={orderCompleted} />
            </>
        )
    }
}
const PaymentMessage = styled.p`
    font-size: 16px;
    line-height: 22px;
    margin-top: 0;
`
interface PaymentCreditBalanceProps {
    billEmail: string;
}
const PaymentCreditBalance: React.FC<PaymentCreditBalanceProps> = (props) => {
    return (
        <PaymentMessage>Your order has a credit balance. When you place the order a gift certificate for the balance will be emailed to you at {props.billEmail},
            to use at a later date.
        </PaymentMessage>
    )
}
const MissingBox = styled.ul`
    margin-top: 0;
    margin-bottom: 16px;
    text-align: left;
`
const MissingHeader = styled.p`
    font-weight: bold;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 6px;
`
const MissingMessage = styled.li`
    font-size: 16px;
    line-height: 24px;
`
interface PaymentMissingItemsProps {
    missing: string[];
}
const PaymentMissingItems: React.FC<PaymentMissingItemsProps> = (props) => {
    return (
        <MissingBox>
            <MissingHeader>The following are needed before your order can be submitted:</MissingHeader>
            {props.missing.map(text => {
                return (
                    <MissingMessage key={text}>{text}</MissingMessage>
                )
            })}
        </MissingBox>
    )
}
//-----------------------------------------------------------------
const IconCaptionButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8px;
    width: 182px;
`
const StepIconContainer = styled.div`
    position: relative;
    width: 50px;
    height: 50px;
`
const StyledStepIcon = styled.div`
    font-size: 34px;
`
const StepCounter = styled.span`
    color: white;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
`
const CaptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`
interface StepIconProps {
    stepNum: string;
    isCompleted: boolean;
    caption: string;
    allowEditing?: boolean;
    editClicked?: () => void;      // if provided, the edit button is shown
    editCaption?: string;           // used only if editClicked is given; otherwise defaults to "edit"
}
const StepIcon: React.FC<StepIconProps> = (props) => {
    return (
        <IconCaptionButtonContainer>
            <StepIconContainer>
                {props.isCompleted ? (
                    <StyledStepIcon><i className="fas fa-check-circle" /></StyledStepIcon>
                ) : (
                    <React.Fragment>
                        <StepCounter>{props.stepNum}</StepCounter>
                        <StyledStepIcon>
                            <i className="fas fa-circle" />
                        </StyledStepIcon>
                    </React.Fragment>
                )}
            </StepIconContainer>
            <CaptionContainer>
                <h3>{props.caption}</h3>
                {props.editClicked && <IconButton style={{ height: "28px", width: props.editCaption ? "130px" : "96px", padding: "4px" }} isDisabled={!props.allowEditing}
                    caption={props.editCaption ? props.editCaption : "Edit"} icon="fas fa-edit" onClick={props.editClicked} />}
            </CaptionContainer>
        </IconCaptionButtonContainer>
    )
}

export default Checkout;
