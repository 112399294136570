import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { getNearestParentId } from '../../libs/libSupport';
import MasterPage from '../MasterPage';
import { formatImageUrl } from '../../libs/ImageFormatter';

class NavItemRecord {
    id: string;
    icon: string;
    text: string;
    url: string;
    constructor(id: string, icon: string, text: string, url: string) {
        this.id = id;
        this.icon = icon;
        this.text = text;
        this.url = url;
    }
}
const navItems: NavItemRecord[] = [
    new NavItemRecord("orders", "account-order.jpg", "My orders and Rewards", "/my-orders"),
    new NavItemRecord("billing", "account-three_houses_150.jpg", "Manage my billing addresses", "/my-bill-addresses"),
    new NavItemRecord("shipping", "account-mail_truck_150.jpg", "Manage my shipping addresses", "/my-ship-addresses"),
    new NavItemRecord("wallet", "account-credit_card_logos_150.jpg", "Manage my credit card on file", "/my-wallet")
];

const graphicsSubfolder = "other";

const MainContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    display: flex;
    max-width: 800px;
    flex-wrap: wrap;
    justify-content: center;
`
const NavContainer = styled.div`
    width: 280px;
    height: 140px;
    margin: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    cursor: pointer;
`
const NavText = styled.p`
    width: 50%;
    font-size: 18px;
    line-height: 28px;
    margin-left: 8px;
    margin-right: 8px;
`
const NavImage = styled.img`
    margin-left: 16px;
`

const AccountMain: React.FC = () => {
    const navigate = useNavigate();
    
    const navClicked = (e: React.MouseEvent<HTMLDivElement>) => {
        const id = getNearestParentId(e.target as HTMLElement).id;
        const url = navItems.find(item => item.id === id)!.url;
        navigate(url);
    }

    return (
        <MasterPage>
            <h1>Your Account</h1>
            <MainContainer>
                {navItems.map(item => {
                    return (
                        <NavContainer key={item.id} id={item.id} onClick={navClicked}>
                            <NavImage src={formatImageUrl(item.icon, { graphicsSubfolder })} alt={item.text} />
                            <NavText>{item.text}</NavText>
                        </NavContainer>
                    )
                })}
            </MainContainer>
        </MasterPage>
    )
}
export default AccountMain;
