import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useTokens } from '../../libs/SamState';
import useAddressBook, { AddressBookEditor } from '../../libs/useAddressBook';
import { genericApiError, useFetchApi } from '../../libs/useDataApiV2';
import MasterPage from '../MasterPage';
import { AddressSchemaType } from '../../libs/forms/AddressEditor';
import { StyledErrorText } from '../../libs/libSupport';

import { FGCustInfoRecord } from '../../interfaces/fg-api-interfaces';
import { AddressType } from '../../interfaces/lib-api-interfaces';

import api from '../../api-url';

interface AddressesProps {
    addressType: AddressType;
}
const MyAddresses: React.FC<AddressesProps> = (props) => {
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const { fetch } = useFetchApi();
    const addressBook = useAddressBook();
    const { getToken } = useTokens();
    const navigate = useNavigate();

    const token = getToken()!.token;

    const fetchError = () => {
        setErrorMsg(genericApiError);
    }
    const gotAddresses = (result: FGCustInfoRecord) => {
        addressBook.setAddresses(AddressType.bill, result.bill_addresses);
        addressBook.setAddresses(AddressType.ship, result.ship_addresses);
    }

    React.useEffect(() => {
        setErrorMsg(undefined);
        if (addressBook.isEmpty()) {
            fetch(api.getAddresses, token, gotAddresses, fetchError);
        }
    }, []);

    const title = props.addressType == AddressType.ship ? "Shipping" : "Billing";
    const submitAddresses = () => {
        navigate("/account");
    }
    return (
        <MasterPage>
            <h1>Manage {title} Addresses</h1>
            {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
            {addressBook.getAddresses(props.addressType) &&
                <AddressBookEditor showAsModal={false} showTitle={false} showUseButton={false} type={props.addressType} schemaType={AddressSchemaType.retail} onSubmit={submitAddresses} />
            }
        </MasterPage>
    )
}
export default MyAddresses;
