import React, { MutableRefObject } from 'react';
import styled from 'styled-components';

import app from '../appData';
import { iPhoneWidth } from './libSupport';

const openWidth = 400;
const closedWidth = 32;
const borderBottom = "1px solid " + app.themes.color;

const AnchorContainer = styled.div<{ $isOpen: boolean }>`
    position: relative;
    margin-right: 12px;
    max-width: 80vw;
    section {
        color: ${app.themes.color};
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        width: ${props => props.$isOpen ? openWidth : closedWidth}px;
        border-bottom: ${props => props.$isOpen ? borderBottom : "none"};
        font-size: 18px;
        transition: width 0.3s ease;
        @media (max-width: ${iPhoneWidth}px) {
            font-size: 12px;
            line-height: 14px;
            font-weight: normal;
        }
    }
    input {
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 16px;
    }
`
const MessageText = styled.p`
    margin: 0;
    width: 100%;
    color: red;
    position: absolute;
    top: 50px;
    font-size: 18px;
    z-index: 1000;
    background-color: white;
    padding: 8px;
`
const StyledSearchIcon = styled.div`
    font-size: 32px;
`
interface AnimatedSearchProps {
    message?: string;
    searchText?: string;
    center?: boolean;
    doSearch: (key: string) => void;
    clearSearch: () => void;
}
const AnimatedSearch: React.FC<AnimatedSearchProps> = (props) => {
    const masterRef = React.useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
    const inputRef = React.useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;

    const doSearch = () => {
        props.doSearch(inputRef.current.value);
    }
    const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === "Enter") {
            doSearch();
        }
    }

    const iconClicked = () => {
        props.clearSearch();
        if (masterRef.current.style.width === openWidth + "px") {
            masterRef.current.style.width = closedWidth + "px";
            masterRef.current.style.borderBottom = "none";
        } else {
            masterRef.current.style.width = openWidth + "px";
            masterRef.current.style.borderBottom = borderBottom;
            inputRef.current.focus();
        }
    }

    // const noMessageHeight = window.matchMedia("(max-width: 1225px)").matches ? 40 : (props.height ?? 80);
    // const height = props.message ? noMessageHeight + 22 : noMessageHeight;

    return (
        <AnchorContainer $isOpen={!!props.searchText}>
            <section ref={masterRef}>
                <StyledSearchIcon id="search" onClick={iconClicked}>
                    <i className="fas fa-search" />
                </StyledSearchIcon>
                <input ref={inputRef} placeholder="Enter search text" defaultValue={props.searchText} onKeyDown={handleSearchKeyDown} />
            </section>
            {props.message &&
                <MessageText>{props.message}</MessageText>
            }
        </AnchorContainer>
    )
}
export default AnimatedSearch;