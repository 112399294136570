import deepcopy from 'deepcopy';

import mc from './money-calc';

import { ExtrasRecord, FGOrddtlRecord, FGOrderRecord, ShipMethodEnum, TotalsRecord } from '../../interfaces/fg-api-interfaces';

export const freeFreightRules = { minimum: 25, freight: 7 };
export const caTaxRate = 7.25;     // %

// following could be placed in its own file -- it is shared between api and client
// don't call this if order or shopping cart are null
// returns copies of order.totals and shopping cart array
export const calcOrderTotals = (order: FGOrderRecord, cart: FGOrddtlRecord[]): any => {
    //   export const calcOrderTotals = (order: FGOrderRecord, cart: FGOrddtlRecord[]): [TotalsRecord, FGOrddtlRecord[]] => {
    const totals: TotalsRecord = {
        inv_net: 0,
        discount: 0,
        sales_tax: 0,
        freight: 0,
        fixed_freight: order.totals.fixed_freight,      // used for expedited charge; once set this does not change
        voucher_applied: 0,
        reward_applied: 0,
        nontaxable: 0,
        inv_tot: 0
    }
    const shoppingCart = deepcopy(cart) as FGOrddtlRecord[];

    if (order.voucher_tot) {
        totals.inv_net = totals.inv_tot = totals.nontaxable = order.voucher_tot;
        order.totals = totals;
        return [totals, shoppingCart];
    }

    shoppingCart.forEach(item => {
        const price = item.price;
        item.ext = mc.mul(item.qty, item.price!);
        totals.inv_net = mc.add(totals.inv_net, item.ext);
    });
    const rewardsBal = order.extras.rewards && order.extras.rewards.balance ? order.extras.rewards.balance : 0;
    if (rewardsBal >= 100) {
        totals.reward_applied = calcRewardApplied(rewardsBal);
    }

    totals.freight = calcFreight(order.extras, totals.inv_net, totals.fixed_freight);
    if (order.extras.coupon && order.extras.coupon.discount) {
        totals.discount = mc.div(mc.mul(totals.inv_net, order.extras.coupon.discount), 100);
    }
    if (order.extras.voucher_applied && order.extras.voucher_applied.balance) {
        totals.voucher_applied = order.extras.voucher_applied.balance;
    }
    if (totals.inv_net - totals.discount < totals.reward_applied) {
        totals.reward_applied = mc.mul(Math.floor((totals.inv_net - totals.discount) / 10) * 10, 1);
    }
    const state = order.ship_address.state;
    if (state && state.toLowerCase() === 'ca') {
        totals.sales_tax = mc.div(mc.mul(caTaxRate, mc.subtract(mc.subtract(totals.inv_net, totals.discount), totals.nontaxable)), 100);
    }
    totals.inv_tot = mc.mul(totals.inv_net - totals.discount + totals.sales_tax + totals.freight - totals.voucher_applied - totals.reward_applied, 1);
    return [totals, shoppingCart];
}
const calcFreight = (extras: ExtrasRecord, invNet: number, fixedFreight: number): number => {
    if (fixedFreight) {
        return fixedFreight;
    }
    const shipMethod = extras.ship_method ?? ShipMethodEnum.regular;
    if (shipMethod === ShipMethodEnum.quoteRequested) {
        return 0;
    } else if (extras.freight_quotes?.expedited) {
        return shipMethod === ShipMethodEnum.regular ? extras.freight_quotes.regular : extras.freight_quotes.expedited;
    } else {
        return invNet > freeFreightRules.minimum ? 0 : freeFreightRules.freight;
    }
}
export const calcRewardApplied = (rewardsBal: number): number => {
    return mc.mul(Math.floor(rewardsBal / 100) * 10, 1);
}

