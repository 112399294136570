import React from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';

import MasterPage from './MasterPage';
import ProductGrid from './ProductGrid';
import ProductPage from './ProductPage';
import { useFetchApi, genericApiError } from '../libs/useDataApiV2';
import { isNumber } from '../libs/libSupport';
import { insertLeadingSlash } from '../libs/api-shared/api-shared-helpers';

import { ApiGetProductsRecord, FGBaseProductRecord, FGProductRecord } from '../interfaces/fg-api-interfaces';

import api from '../api-url';

const ErrorText = styled.p`
    font-size: 14px;
    color: red;
`
interface ApiArgs {
    u?: string;     // url (passed to api to load item from url found in url path to fernsgarden.com/)
    s?: string;     // sku
    l?: string;     // links (not sure what this is used for; may be obsolete as of 1/27/23)
}
// location.pathname is the url to load
// if there are url args (?u=xxx&l=xxx) then we are loading a linked menu item; pass those directly to api
const Products: React.FC = () => {
    const [products, setProducts] = React.useState<ApiGetProductsRecord>();
    //   const [urlParams, setUrlParams] = React.useState<string>();
    const [errorMsg, setErrorMsg] = React.useState<string>();

    const { fetch } = useFetchApi();
    const [searchParams] = useSearchParams();

    // console.log("PRODUCTS: urlParams=" + urlParams + ", pathname=" + window.location.pathname)
    // if (!urlParams || urlParams !== window.location.pathname) {
    //         setUrlParams(window.location.pathname);
    // }

    React.useEffect(() => {
        const args: ApiArgs = isNumber(window.location.pathname.slice(1)) ? { s: window.location.pathname.slice(1) } : { u: encodeURIComponent(window.location.pathname) };
        if (searchParams.get('l')) {
            args.l = searchParams.get('l') as string;
        }
        setErrorMsg(undefined);
        fetch(api.getProducts, null, (result: ApiGetProductsRecord) => {
            //         console.log("result:"); console.log(result);
            setProducts(result);
            if (result.url) {
                const url = insertLeadingSlash(result.url);
                //                setUrlParams(url);
                history.replaceState(null, '', url);        // show the url being loaded in browser field
            }
        }, () => setErrorMsg(genericApiError), args);
        //    }, [urlParams]);
    }, [window.location.pathname]);

    //    console.log("Products: products", products)

    return (
        <MasterPage>
            {errorMsg && <ErrorText>{errorMsg}</ErrorText>}
            {products && (
                products.count === 1 && products.data ?
                    (
                        <ProductPage product={products.data as FGProductRecord} />
                    ) : (
                        products.count > 1 ? (
                            <React.Fragment>
                                <h1>{products.categoryCaption}</h1>
                                <ProductGrid products={products.data as FGBaseProductRecord[]} />
                            </React.Fragment>
                        ) : (
                            <ErrorText>
                                We're sorry but we can't find the product you are looking for.
                            </ErrorText>
                        )
                    )
            )}
        </MasterPage>
    )
}

export default Products;