import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import MasterPage from '../components/MasterPage';
import { useTokens, useBookmarks } from '../libs/SamState';
import SamForm from '../libs/forms/SamFormV5';
import { usePostApi, genericApiError } from '../libs/useDataApiV2';
import { deformat, StyledErrorText } from '../libs/libSupport';
import { ModalMessageBox, ModalMessageResponseFlags } from '../libs/SamModalV2';
import useFGOrder from '../libs/shopping-cart/useFGOrder';

import { FormFieldRecord, FormFieldType } from '../interfaces/lib-api-interfaces';
import { RewardsRecord } from '../interfaces/fg-api-interfaces';

import api from '../api-url';
import app from '../appData';

const width = window.innerWidth;

const MasterContainer = styled.div`
    width: ${width}px;
    max-width: 740px;
    font-size: 16px;
    line-height: 24px;
    margin: 0 auto 0 auto;
    text-align: left;
    a:link {
        text-decoration: underline;
    }
    p {
        padding-bottom: 6px;
    }
    h3 {
        font-size: 18px;
        font-weight: bold;
        text-align: left;
    }
`
const Rewards: React.FC = () => {
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const [signupSuccessful, setSignupSuccessful] = React.useState(false);
    const [msg, setMsg] = React.useState<string>();
    const { getToken } = useTokens();
    const { post } = usePostApi();
    const { navigateToBookmark } = useBookmarks();
    const order = useFGOrder();

    const rewardsSubmitted = (phone: string) => {
        setErrorMsg(undefined);
        post(api.registerForRewards, { phone }, postSuccess, () => setErrorMsg(genericApiError), getToken()!.token);
    }
    const postSuccess = (result: RewardsRecord) => {
        order.setRewards(result);
        setSignupSuccessful(true);
    }

    return (
        <MasterPage>
            {signupSuccessful && <ModalMessageBox
                caption="Thank you for signing up for Fern's Garden Rewards!" responseFlags={ModalMessageResponseFlags.continue}
                onSubmit={() => navigateToBookmark()} />
            }
            <MasterContainer>
                <h1>Fern's Garden Rewards</h1>
                <p>
                    Fern's Garden Rewards is our customer gratitude program that offers you a $10 credit for every $100 you spend at Fern's Garden.
                </p>
                <p>
                    <b>The way it works</b>: You register for rewards with your phone number. Your rewards account is tied to your fernsgarden.com email and
                    password registration. As long as you are logged in to your Fern's Garden account, the amount of every order you place is added to your Rewards account balance.
                    When your balance reaches $100 or more, $10 is automatically deducted from your next order.
                    Your balance is then decreased accordingly. There are no rules or time limits, and your Rewards account works
                    the same way at our brick and mortar store in Berkeley, California.
                </p>

                <h3>To make sure your purchase is added to your balance:</h3>
                <ul>
                    <li><b>When shopping online:</b> Always log in with your email and password.</li>
                    <li><b>When checking out at one of our stores:</b> Give the sales associate your phone number.</li>
                </ul>
                <p>
                    Your phone number is used strictly to identify you in our system. It will never be called, texted or shared.
                    This is just our way of saying "thank you" to our best and most loyal customers!
                </p>
                <div style={{ marginBottom: "32px" }}>
                    {msg && <p>{msg}</p>}
                    {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
                    {getToken() ? (
                        order.getExtras().rewards ? (
                            <h3>Thank you for being a Rewards member!</h3>
                        ) : (
                            <RewardsSignUp showSubmitButton={true} onSubmit={rewardsSubmitted} />
                        )) : (
                        <p>
                            <Link to="/register">Register now for a Rewards account.</Link>
                        </p>
                    )}
                </div>
            </MasterContainer>
        </MasterPage>
    )
}

const SignUpContainer = styled.div`
    width: ${width}px;
    max-width: 500px;
    padding: 16px;
    box-shadow: 3px 3px 5px 6px #ccc;
    margin: 16px auto 16px auto;
    h3 {
        font-weight: bold;
        margin: 0;
        text-align: center;
        font-size: 18px;
    }
`
interface RewardsSignUpProps {
    addOptional?: boolean;                  // add "(optional) after "
    showSubmitButton: boolean;              // omit if user is registering now
    onSubmit?: (phone: string) => void;     // if passed a submit button is shown and this is called upon click
}
export const RewardsSignUp: React.FC<RewardsSignUpProps> = (props) => {
    const fields: FormFieldRecord[] = [
        { name: "phone", type: FormFieldType.phone, label: "10-digit phone", width: 48, validator: { maxLength: 14 } },
        { name: "phone_verify", type: FormFieldType.phone, label: "Confirm phone", width: 48, validator: { maxLength: 14 } }
    ];
    const validator = (name: string, values: Record<string, any>): string | null => {
        if (name === "phone") {
            if (deformat(values[name]).length !== 10) {
                return "Enter a 10 digit phone number";
            }
        }
        if (name === "phone_verify" && deformat(values[name]) !== deformat(values["phone"])) {
            return "Phone numbers do not match";
        }
        return null;
    }
    const handleSubmit = (values: Record<string, any> | null, id: string) => {
        if (values) {
            props.onSubmit!(deformat(values["phone"]));
        }
    }

    return (
        <SignUpContainer>
            <h3>{"Sign Up for Fern's Garden Rewards" + (props.addOptional ? " (optional)" : '')}</h3>
            <SamForm id="rewards" fields={fields} submitButtons={props.showSubmitButton ? [{ id: "submit", caption: "Sign me up now", validate: true }] : undefined}
                customValidator={validator} handleSubmit={props.onSubmit ? handleSubmit : undefined} />
        </SignUpContainer>
    )
}
export default Rewards;
