import React from 'react';
import styled from 'styled-components';

import MasterPage from '../components/MasterPage';
import { useSessionStore, useGlobalContext } from '../libs/SamState';

import { FGBaseProductRecord } from '../interfaces/fg-api-interfaces';
import { SliderRecord } from '../interfaces/lib-react-interfaces';
import FeaturedProductGrid from '../components/FeaturedProductGrid';
import { useNavigate } from 'react-router-dom';
import { formatImageUrl } from '../libs/ImageFormatter';

import app from "../appData";

const ErrorText = styled.p`
    font-size: 18px;
`
const StyledImage = styled.img`
    width: 100%;
    height: auto;
`
const AboutUsContainer = styled.div`
    margin-top: 24px;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${app.themes.color};
`
const AboutUsImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    p {
        font-style: italic;
        text-align: center;
        margin-left: 24px;
        margin-right: 24px;
    }
`
const AboutUsImage = styled.img`
    width: 300px;
    height: auto;
`
const AboutUsText = styled.div`
    text-align: left;
    margin: 16px;
    line-height: 24px;
    font-size: 16px; 
    p {
        margin-top: 4px;
        margin-bottom: 4px;
    }
`
const Home: React.FC = () => {
    const [dummy, setForceRedisplay] = React.useState<number>(0);

    const navigate = useNavigate();
    const { getSessionStore } = useSessionStore();
    const { getContext } = useGlobalContext();

    const carousel = getSessionStore("carousel") as SliderRecord[];
    const featured = getSessionStore("featured") as FGBaseProductRecord[];
    const popular = getSessionStore("popular") as FGBaseProductRecord[];
    const newItems = getSessionStore("newItems") as FGBaseProductRecord[];
console.log("HOME")
    if (getContext("fatalError")) {
        return (
            <ErrorText>
                Thanks for visiting Fern's Garden. Our server is down right now. Please try again later.
            </ErrorText>
        )
    }
    const forceRedisplay = () => {
        setForceRedisplay(state => state + 1);
    }

    return (
        <MasterPage forceRedisplay={forceRedisplay}>
            {carousel && featured && popular && newItems &&
                <React.Fragment>
                    <StyledImage src={formatImageUrl("store_with_shopping_bag.jpg", { graphicsSubfolder: "other" })} />
                    <AboutUsContainer>
                        <AboutUsImageContainer>
                        <AboutUsImage src="/graphics/other/fern--lia-in-the-garden.jpg" alt="Fern and daughter Lia co-managing Fern's Garden, Berkeley" />
                        <p>Fern with daughter Lia at Fern&apos;s Garden, Berkeley</p>
                        </AboutUsImageContainer>
                        <AboutUsText>
                            <p>Welcome to Fern&apos;s Garden, a place to find beautifully hand crafted, affordable items that are made in the U.S.A. or abroad in an ethical manner. 
                                The artisans represented here earn a fair wage for their beautiful creations, and we support many American made companies. For creations that are 
                                made abroad, we seek out makers that pay fair wages and give back to their communities. When you shop at Fern&apos;s Garden, you can purchase something beautiful and feel good about 
                                helping artists who really put their hands and heart into their work.
                            </p>
                            <p style={{textAlign: "center"}}><a href="/about-us"><u>Read more...</u></a></p>
                        </AboutUsText>
                    </AboutUsContainer>
                    <FeaturedProductGrid header="Featured Products" featured={featured} />
                    <FeaturedProductGrid header="Most Popular Products" featured={popular} />
                    <FeaturedProductGrid header="New Products" featured={newItems} />
                </React.Fragment>}
        </MasterPage>
    );
}

export default Home;