import React from 'react';
import styled from 'styled-components';

import { iPhoneWidth, iPadMaxWidth, getNearestParentId, StyledErrorText, isNumber } from '../libs/libSupport';
import { formatImageUrl } from '../libs/ImageFormatter';

import { FGBaseProductRecord } from '../interfaces/fg-api-interfaces';
import { ImageSizeEnum } from '../interfaces/lib-api-interfaces';

import app from '../appData';

const maxImgSize = 270;        // max size for image height and width: 4 across = 1080 plus padding
const maxImgiPadSize = 250;     // iPad: 3 across = 750
const maxImgiPhoneSize = 190   // iPhone: 2 across = 410

const StyledProductGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`
// includes product div, image and caption
const ProductCard = styled.div<{ $cellPadding: number;  $sizePct?: number; $maxImgSize: number }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: ${props => props.$cellPadding}px;
    margin-right: ${props => props.$cellPadding}px;
    margin-bottom: ${props => props.$cellPadding}px;
    flex-basis: ${props => props.$maxImgSize}px;
    cursor: pointer;
    img {
        max-height: ${props => props.$sizePct ? props.$sizePct * props.$maxImgSize / 100 : props.$maxImgSize}px;
        max-width: ${props => props.$sizePct ? props.$sizePct * props.$maxImgSize / 100 : props.$maxImgSize}px;
            height: auto; 
        width: auto;
        cursor: pointer;
    }
    @media (max-width: ${iPadMaxWidth}px) {
        img {
            max-width: ${maxImgiPadSize}px;
            max-height: ${maxImgiPadSize}px;
        }
        flex-basis: ${maxImgiPadSize}px;
        margin-left: ${props => props.$cellPadding * .75}px;
        margin-right: ${props => props.$cellPadding * .75}px;
    }
    @media (max-width: ${iPhoneWidth}px) {
        img {
            max-width: ${maxImgiPhoneSize}px;
            max-height: ${maxImgiPhoneSize}px;
        }
        flex-basis: ${maxImgiPhoneSize}px;
        margin-left: ${props => props.$cellPadding * .5}px;
        margin-right: ${props => props.$cellPadding * .5}px;
    }
`

const StyledCaption = styled.p`
    font-size: 15px;
    font-family: ${app.themes.serifFonts};
    line-height: 20px;
    text-align: center;
    margin-top: 4px;
    margin-bottom: 0;
    font-weight: bold;
    width: 100%;
    @media (max-width: ${iPhoneWidth}px) {
        font-size: 12px;
        line-height: 14px;
        font-weight: normal;
    }
`
const StyledPrice = styled(StyledCaption)`
    margin-top: 4px;
    font-weight: normal; 
    margin-bottom: 16px;   
`
/* props: 
    products : [{ caption, filename, price, url }]
*/
interface ProductGridProps {
    products: FGBaseProductRecord[];
    cellPadding?: number;       // defaults to 48 (pixel space between images)
    imageSize?: number;      // pixels; images are squared; default to maxImgSize, above (currently 270)
}
const ProductGrid: React.FC<ProductGridProps> = (props) => {
    const [errorMsg, setErrorMsg] = React.useState<string>();

    const cellPadding = props.cellPadding ? props.cellPadding / 2 : 24;
    const imgSize = props.imageSize ? props.imageSize : maxImgSize;

    const productClicked = (e: React.MouseEvent<HTMLElement>) => {
        const url = getNearestParentId(e.target as HTMLElement).id;
        window.scrollTo(0, 0);
        // if url is a SKU we need to to go to "/SKU"
        // if url is a product url we need to append it to current path: pathname/URL
        // pathname contains leading slash, url does not
        const newLocation = ((!isNumber(url) ? window.location.pathname : '') + "/" + url).replaceAll("//", "/");
        //   console.log("productClicked: pathname=" + window.location.pathname + ", url=" + url + ", pushing " + newLocation)
        window.location.href = newLocation;
    }
    const formatSku = (sku: number): string => {
        const productId = Math.floor(sku / 100000);
        const spec1Id = Math.floor((sku - productId * 100000) / 100);
        const spec2Id = sku - productId * 100000 - spec1Id * 100;
        let formatted = productId + '';
        if (spec2Id > 0) {
            formatted += '-' + spec1Id + '-' + spec2Id;
        } else if (spec1Id > 0) {
            formatted += '-' + spec1Id;
        }
        return formatted;
    }
    // for (const product of props.products) {
    //     if (!product.image) {
    //         console.log(product.caption + ": image is undefined");
    //     }
    // }
    const showIdAsSku = true;       // should be props.showIdAsSku

    return (
        <StyledProductGrid>
            {errorMsg && <StyledErrorText dangerouslySetInnerHTML={{ __html: errorMsg }} />}
            {props.products.map((item: FGBaseProductRecord) => {
                return (
                    <ProductCard key={item.caption} id={showIdAsSku ? (item.sku_long + '') : item.url} $maxImgSize={imgSize} $cellPadding={cellPadding} $sizePct={item.image!.size_pct}
                        data-sku={formatSku(item.sku_long)} onClick={productClicked}>
                        <img src={formatImageUrl(item.image!.filename!, { sizeDesignator: ImageSizeEnum.full })}
                            alt={item.caption} />
                        <StyledCaption>
                            {item.caption}
                        </StyledCaption>
                        <StyledPrice>
                            ${item.price!.toFixed(2)}
                        </StyledPrice>
                    </ProductCard>
                )
            })}
        </StyledProductGrid>
    )
}

export default ProductGrid;
