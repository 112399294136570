// interfaces shared by all React apps (not Node)
import React from 'react';
import { StyleRecord } from './lib-api-interfaces';

export interface SliderRecord {
    src: string;
    userData?: string;      // passed if onClick given
    id?: number;            // for Slider use only
}

  export interface ButtonHoverStylesRecord {
    color: string;
    backColor: string;
    [key: string]: any
}
export interface NavbarStylesRecord {
    bannerStyles?: StyleRecord;
    popupStyles?: StyleRecord;
    bannerHoverStyles?: StyleRecord;
    popupHoverStyles?: StyleRecord;
    [key: string]: any;
}
   // found on appData
   export interface AppThemesRecord {
    serifFonts?: string;
    sansFonts?: string;
    fontSize?: number;
    color?: string;
    backColor?: string;
    linkColor?: string;
    backColor50?: string;    // 50%
    backColor25?: string;    // 25%
    backColor10?: string;    // almost white (for reverse text)
    navbarStyles?: NavbarStylesRecord;
    buttonStyles?: StyleRecord;
    buttonHoverStyles?: ButtonHoverStylesRecord;
    modalStyles?: StyleRecord;
    modalFonts?: string;
    infoPageStyles?: StyleRecord;
}
export interface MessageCallback {
    (message: string): void;
}
export interface AnyCallback {
    (prop: any): void;
}
export interface OnClickCallback {
    (e: React.MouseEvent<HTMLElement>): void;
}

/*
export interface IconButtonProps {
    id?: string;
    caption: string;
    fontSize?: number;
    height?: number;
    marginLeft?: number;
    padding?: number;       // default to 4
    style?: Record<string, string>;
    hoverStyle?: Record<string, string>;
    isDisabled?: boolean;
    icon?: string;
    iconPosition?: number;
    validate?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onInlineClick?: (value: string) => void;     // special button attached to an input passes the input's value
}
*/
export enum IconButtonAlignEnum { left = 'L', center = 'C', right = 'R', spaceBetween = 'S' }
export interface IconButtonV2Props {
    id?: string;
    caption: string;
    marginLeft?: number;
    fontSizeOverride?: number;            // px; all other numbers are calculated if this is given
    style?: Record<string, string>;
    hoverStyle?: Record<string, string>;
    isDisabled?: boolean;
    icon?: string;
    iconPosition?: IconButtonAlignEnum;
    validate?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onInlineClick?: (value: string) => void;     // special button attached to an input passes the input's value
}
export interface ShoppingCartListRecord {
    id: string;     // sku_long converted to string
    caption: string;
    imageFilename: string;
    qty: number;
    price: number;
}
export interface PhotoRecord {
    filename: string;
    sizePct?: number;        // percentage; usually 50 or 100
}
