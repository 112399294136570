import React from 'react'
import styled from 'styled-components';

import SamForm from './SamFormV5';
import addr from '../api-shared/address-support';
import IconButton from '../IconButtonV2';

import { FormFieldRecord, FormFieldType, AddressRecord, AddressType } from '../../interfaces/lib-api-interfaces';
import FormMgr from './FormMgr';
import { useGlobalContext } from '../SamState';

/* AddressEditor props:
    id: string (required, unique)
    values: Address object
    suppressEmail: true to not show email field (for Register form)
*/
export enum AddressSchemaType { wholesale = 'w', retail = 'r' } // wholesale includes name, contact_name, excludes of fname,lname ; retail is opposite
export interface AddressEditorProps {
    id: string;             // must be unique
    addressType?: AddressType;  // required only if is_default is shown; it will allow for proper caption on checkbox
    values?: AddressRecord | null;        // this must be passed with all fields to be edited
    excludedFields?: string[];
    schemaType?: AddressSchemaType;     // default to retail; if wholesale include storefront, res_del and is_default checkboxes
    submitCaption?: string;     // "save" or "submit" or "use this address" etc. -- only if onSubmit is passed; defaults to "Save address"
    companyRequired?: boolean;
    phoneRequired?: boolean;
    addedFields?: FormFieldRecord[];
    addedValues?: Record<string, any>[];
    onSubmit?: (values: AddressRecord | null) => void;      // causes buttons to be displayed, callback with saved values or null to cancel
}
const StyledButtonsRow = styled.div`
    margin-top: 8px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`
const AddressEditor: React.FC<AddressEditorProps> = (props) => {
    const { setContext } = useGlobalContext();

    const allFields: FormFieldRecord[] = [
        { name: "fname", label: "First name", width: 46, validator: { required: true, maxLength: 50 } },
        { name: "lname", label: "Last name", width: 46, validator: { maxLength: 50 } },
        { name: "email", label: "Email", validator: { required: true, maxLength: 100 } },
        { name: "company", label: "Company", width: 46, validator: { required: props.companyRequired, maxLength: 100 } },
        { name: "attn", label: "Attn:", width: 46, validator: { maxLength: 50 } },
        { name: "contact_name", label: "Contact", validator: { maxLength: 50 } },
        { name: "address1", label: "Address", width: 46, validator: { required: true, maxLength: 100 } },
        { name: "address2", label: "Address", width: 46, validator: { maxLength: 100 } },
        { name: "city", label: "City", width: 46, validator: { required: true, maxLength: 50 } },
        { name: "zip", label: "Zip or postal code", width: 46, validator: { required: true, maxLength: 20 } },
        { name: "state", type: FormFieldType.combo, label: "State (if USA)", width: 46, comboSource: selectStateList, validator: { maxLength: 2 } },
        { name: "province", label: "Province (non USA)", width: 46, validator: { maxLength: 50 } },
        { name: "country", type: FormFieldType.combo, label: "Country", placeholder: "Select a country", width: 50, comboSource: selectCountryList, validator: { required: true, maxLength: 3 } },
        { name: "phone", type: FormFieldType.phone, label: "Phone", width: 46, validator: { required: props.phoneRequired, maxLength: 20 } },
        { name: "storefront", type: FormFieldType.checkbox, label: "This is a storefront; protect the zip code" },
        { name: "res_del", type: FormFieldType.checkbox, label: "This is a residence" },
        { name: "is_default", type: FormFieldType.checkbox, label: "Make this my default " + (props.addressType === AddressType.ship ? "ship" : "billing") + " address for future orders" },
        { name: "address_id", type: FormFieldType.int, visible: false },
    ];

    const schemaType = props.schemaType ? props.schemaType : AddressSchemaType.retail;
    let excludedFields = schemaType === AddressSchemaType.retail ? ["contact_name"] : ["fname", "lname"];
    if (props.excludedFields) {
        excludedFields = excludedFields.concat(props.excludedFields);
    }
    let fields: FormFieldRecord[] = allFields.filter(field => !excludedFields.includes(field.name!));
    let initialValues = props.values as Record<string, any>;
    if (props.addedFields) {
        fields = fields.concat(props.addedFields);
        if (props.addedValues) {
            initialValues = { ...props.values, ...props.addedValues };
        }
    }

    const buttonStyle = { marginLeft: "16px" };

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        const target = e.target as HTMLButtonElement;
        let values = null;
        if (target.id === "save") {
            const forms = new FormMgr(setContext);
            values = forms.getFormValues(props.id) as AddressRecord;
        }
        props.onSubmit!(values);
    }

    console.log("AddressEditor: id=" + props.id)

    return (
        <React.Fragment>
            <SamForm id={props.id} fields={fields} initialValues={initialValues} />
            {props.onSubmit &&
                <StyledButtonsRow>
                    <IconButton id="save" caption={props.submitCaption ? props.submitCaption : "Save address"} icon="fas fa-check" style={buttonStyle} onClick={handleSubmit} />
                    <IconButton id="cancel" caption="Cancel" icon={"fas fa-ban"} style={buttonStyle} onClick={handleSubmit} />
                </StyledButtonsRow>
            }
        </React.Fragment>
    )
}
export const renderAddressSummary = (address: AddressRecord): any => {
    //   console.log("rendering address:", address)
    const result: any = [];
    const formatted = addr.toArray(address);
    formatted.forEach((text: string, index: number) => {
        text && result.push(<p key={index}>{text}</p>)
    });
    if (address.res_del) {
        result.push(<p key={"resdel"}><i className="fas fa-check-square"></i>&nbsp;Residence</p>);
    }
    if (address.storefront) {
        result.push(<p key={"storefront"}><i className="fas fa-check-square"></i>&nbsp;Storefront</p>);
    }
    if (address.is_default) {
        result.push(<p key={"default"}><i className="fas fa-check-square"></i>&nbsp;<b>Default</b></p>);
    }
    return result;
}


export const selectStateList = [
    { caption: "", value: "" },
    { caption: "Alabama", value: "AL" },
    { caption: "Alaska", value: "AK" },
    { caption: "American Samoa", value: "AS" },
    { caption: "Arizona", value: "AZ" },
    { caption: "Arkansas", value: "AR" },
    { caption: "California", value: "CA" },
    { caption: "Colorado", value: "CO" },
    { caption: "Connecticut", value: "CT" },
    { caption: "Delaware", value: "DE" },
    { caption: "District of Columbia", value: "DC" },
    { caption: "Florida", value: "FL" },
    { caption: "Georgia", value: "GA" },
    { caption: "Guam", value: "GU" },
    { caption: "Hawaii", value: "HI" },
    { caption: "Idaho", value: "ID" },
    { caption: "Illinois", value: "IL" },
    { caption: "Indiana", value: "IN" },
    { caption: "Iowa", value: "IA" },
    { caption: "Kansas", value: "KS" },
    { caption: "Kentucky", value: "KY" },
    { caption: "Louisiana", value: "LA" },
    { caption: "Maine", value: "ME" },
    { caption: "Marshall Islands", value: "MH" },
    { caption: "Maryland", value: "MD" },
    { caption: "Massachusetts", value: "MA" },
    { caption: "Michigan", value: "MI" },
    { caption: "Minnesota", value: "MN" },
    { caption: "Mississippi", value: "MS" },
    { caption: "Missouri", value: "MO" },
    { caption: "Montana", value: "MT" },
    { caption: "Nebraska", value: "NE" },
    { caption: "Nevada", value: "NV" },
    { caption: "New Hampshire", value: "NH" },
    { caption: "New Jersey", value: "NJ" },
    { caption: "New Mexico", value: "NM" },
    { caption: "New York", value: "NY" },
    { caption: "North Carolina", value: "NC" },
    { caption: "North Dakota", value: "ND" },
    { caption: "Northern Mariana Islands", value: "MP" },
    { caption: "Ohio", value: "OH" },
    { caption: "Oklahoma", value: "OK" },
    { caption: "Oregon", value: "OR" },
    { caption: "Palau", value: "PW" },
    { caption: "Pennsylvania", value: "PA" },
    { caption: "Puerto Rico", value: "PR" },
    { caption: "Rhode Island", value: "RI" },
    { caption: "South Carolina", value: "SC" },
    { caption: "South Dakota", value: "SD" },
    { caption: "Tennessee", value: "TN" },
    { caption: "Texas", value: "TX" },
    { caption: "Utah", value: "UT" },
    { caption: "Vermont", value: "VT" },
    { caption: "Virgin Islands", value: "VI" },
    { caption: "Virginia", value: "VA" },
    { caption: "Washington", value: "WA" },
    { caption: "West Virginia", value: "WV" },
    { caption: "Wisconsin", value: "WI" },
    { caption: "Wyoming", value: "WY" },
];
const selectCountryList = [
    { caption: "", value: "" },
    { value: "USA", caption: "United States" },
    { value: "AFG", caption: "Afghanistan" },
    { value: "ALA", caption: "Åland Islands" },
    { value: "ALB", caption: "Albania" },
    { value: "DZA", caption: "Algeria" },
    { value: "ASM", caption: "American Samoa" },
    { value: "AND", caption: "Andorra" },
    { value: "AGO", caption: "Angola" },
    { value: "AIA", caption: "Anguilla" },
    { value: "ATA", caption: "Antarctica" },
    { value: "ATG", caption: "Antigua and Barbuda" },
    { value: "ARG", caption: "Argentina" },
    { value: "ARM", caption: "Armenia" },
    { value: "ABW", caption: "Aruba" },
    { value: "AUS", caption: "Australia" },
    { value: "AUT", caption: "Austria" },
    { value: "AZE", caption: "Azerbaijan" },
    { value: "BHS", caption: "Bahamas" },
    { value: "BHR", caption: "Bahrain" },
    { value: "BGD", caption: "Bangladesh" },
    { value: "BRB", caption: "Barbados" },
    { value: "BLR", caption: "Belarus" },
    { value: "BEL", caption: "Belgium" },
    { value: "BLZ", caption: "Belize" },
    { value: "BEN", caption: "Benin" },
    { value: "BMU", caption: "Bermuda" },
    { value: "BTN", caption: "Bhutan" },
    { value: "BOL", caption: "Bolivia, Plurinational State of" },
    { value: "BES", caption: "Bonaire, Sint Eustatius and Saba" },
    { value: "BIH", caption: "Bosnia and Herzegovina" },
    { value: "BWA", caption: "Botswana" },
    { value: "BVT", caption: "Bouvet Island" },
    { value: "BRA", caption: "Brazil" },
    { value: "IOT", caption: "British Indian Ocean Territory" },
    { value: "BRN", caption: "Brunei Darussalam" },
    { value: "BGR", caption: "Bulgaria" },
    { value: "BFA", caption: "Burkina Faso" },
    { value: "BDI", caption: "Burundi" },
    { value: "KHM", caption: "Cambodia" },
    { value: "CMR", caption: "Cameroon" },
    { value: "CAN", caption: "Canada" },
    { value: "CPV", caption: "Cape Verde" },
    { value: "CYM", caption: "Cayman Islands" },
    { value: "CAF", caption: "Central African Republic" },
    { value: "TCD", caption: "Chad" },
    { value: "CHL", caption: "Chile" },
    { value: "CHN", caption: "China" },
    { value: "CXR", caption: "Christmas Island" },
    { value: "CCK", caption: "Cocos (Keeling) Islands" },
    { value: "COL", caption: "Colombia" },
    { value: "COM", caption: "Comoros" },
    { value: "COG", caption: "Congo" },
    { value: "COD", caption: "Congo, the Democratic Republic of the" },
    { value: "COK", caption: "Cook Islands" },
    { value: "CRI", caption: "Costa Rica" },
    { value: "CIV", caption: "Côte d'Ivoire" },
    { value: "HRV", caption: "Croatia" },
    { value: "CUB", caption: "Cuba" },
    { value: "CUW", caption: "Curaçao" },
    { value: "CYP", caption: "Cyprus" },
    { value: "CZE", caption: "Czech Republic" },
    { value: "DNK", caption: "Denmark" },
    { value: "DJI", caption: "Djibouti" },
    { value: "DMA", caption: "Dominica" },
    { value: "DOM", caption: "Dominican Republic" },
    { value: "ECU", caption: "Ecuador" },
    { value: "EGY", caption: "Egypt" },
    { value: "SLV", caption: "El Salvador" },
    { value: "GNQ", caption: "Equatorial Guinea" },
    { value: "ERI", caption: "Eritrea" },
    { value: "EST", caption: "Estonia" },
    { value: "ETH", caption: "Ethiopia" },
    { value: "FLK", caption: "Falkland Islands (Malvinas)" },
    { value: "FRO", caption: "Faroe Islands" },
    { value: "FJI", caption: "Fiji" },
    { value: "FIN", caption: "Finland" },
    { value: "FRA", caption: "France" },
    { value: "GUF", caption: "French Guiana" },
    { value: "PYF", caption: "French Polynesia" },
    { value: "ATF", caption: "French Southern Territories" },
    { value: "GAB", caption: "Gabon" },
    { value: "GMB", caption: "Gambia" },
    { value: "GEO", caption: "Georgia" },
    { value: "DEU", caption: "Germany" },
    { value: "GHA", caption: "Ghana" },
    { value: "GIB", caption: "Gibraltar" },
    { value: "GRC", caption: "Greece" },
    { value: "GRL", caption: "Greenland" },
    { value: "GRD", caption: "Grenada" },
    { value: "GLP", caption: "Guadeloupe" },
    { value: "GUM", caption: "Guam" },
    { value: "GTM", caption: "Guatemala" },
    { value: "GGY", caption: "Guernsey" },
    { value: "GIN", caption: "Guinea" },
    { value: "GNB", caption: "Guinea-Bissau" },
    { value: "GUY", caption: "Guyana" },
    { value: "HTI", caption: "Haiti" },
    { value: "HMD", caption: "Heard Island and McDonald Islands" },
    { value: "VAT", caption: "Holy See (Vatican City State)" },
    { value: "HND", caption: "Honduras" },
    { value: "HKG", caption: "Hong Kong" },
    { value: "HUN", caption: "Hungary" },
    { value: "ISL", caption: "Iceland" },
    { value: "IND", caption: "India" },
    { value: "IDN", caption: "Indonesia" },
    { value: "IRN", caption: "Iran, Islamic Republic of" },
    { value: "IRQ", caption: "Iraq" },
    { value: "IRL", caption: "Ireland" },
    { value: "IMN", caption: "Isle of Man" },
    { value: "ISR", caption: "Israel" },
    { value: "ITA", caption: "Italy" },
    { value: "JAM", caption: "Jamaica" },
    { value: "JPN", caption: "Japan" },
    { value: "JEY", caption: "Jersey" },
    { value: "JOR", caption: "Jordan" },
    { value: "KAZ", caption: "Kazakhstan" },
    { value: "KEN", caption: "Kenya" },
    { value: "KIR", caption: "Kiribati" },
    { value: "PRK", caption: "Korea, Democratic People's Republic of" },
    { value: "KOR", caption: "Korea, Republic of" },
    { value: "KWT", caption: "Kuwait" },
    { value: "KGZ", caption: "Kyrgyzstan" },
    { value: "LAO", caption: "Lao People's Democratic Republic" },
    { value: "LVA", caption: "Latvia" },
    { value: "LBN", caption: "Lebanon" },
    { value: "LSO", caption: "Lesotho" },
    { value: "LBR", caption: "Liberia" },
    { value: "LBY", caption: "Libya" },
    { value: "LIE", caption: "Liechtenstein" },
    { value: "LTU", caption: "Lithuania" },
    { value: "LUX", caption: "Luxembourg" },
    { value: "MAC", caption: "Macao" },
    { value: "MKD", caption: "Macedonia, the former Yugoslav Republic of" },
    { value: "MDG", caption: "Madagascar" },
    { value: "MWI", caption: "Malawi" },
    { value: "MYS", caption: "Malaysia" },
    { value: "MDV", caption: "Maldives" },
    { value: "MLI", caption: "Mali" },
    { value: "MLT", caption: "Malta" },
    { value: "MHL", caption: "Marshall Islands" },
    { value: "MTQ", caption: "Martinique" },
    { value: "MRT", caption: "Mauritania" },
    { value: "MUS", caption: "Mauritius" },
    { value: "MYT", caption: "Mayotte" },
    { value: "MEX", caption: "Mexico" },
    { value: "FSM", caption: "Micronesia, Federated States of" },
    { value: "MDA", caption: "Moldova, Republic of" },
    { value: "MCO", caption: "Monaco" },
    { value: "MNG", caption: "Mongolia" },
    { value: "MNE", caption: "Montenegro" },
    { value: "MSR", caption: "Montserrat" },
    { value: "MAR", caption: "Morocco" },
    { value: "MOZ", caption: "Mozambique" },
    { value: "MMR", caption: "Myanmar" },
    { value: "NAM", caption: "Namibia" },
    { value: "NRU", caption: "Nauru" },
    { value: "NPL", caption: "Nepal" },
    { value: "NLD", caption: "Netherlands" },
    { value: "NCL", caption: "New Caledonia" },
    { value: "NZL", caption: "New Zealand" },
    { value: "NIC", caption: "Nicaragua" },
    { value: "NER", caption: "Niger" },
    { value: "NGA", caption: "Nigeria" },
    { value: "NIU", caption: "Niue" },
    { value: "NFK", caption: "Norfolk Island" },
    { value: "MNP", caption: "Northern Mariana Islands" },
    { value: "NOR", caption: "Norway" },
    { value: "OMN", caption: "Oman" },
    { value: "PAK", caption: "Pakistan" },
    { value: "PLW", caption: "Palau" },
    { value: "PSE", caption: "Palestinian Territory, Occupied" },
    { value: "PAN", caption: "Panama" },
    { value: "PNG", caption: "Papua New Guinea" },
    { value: "PRY", caption: "Paraguay" },
    { value: "PER", caption: "Peru" },
    { value: "PHL", caption: "Philippines" },
    { value: "PCN", caption: "Pitcairn" },
    { value: "POL", caption: "Poland" },
    { value: "PRT", caption: "Portugal" },
    { value: "PRI", caption: "Puerto Rico" },
    { value: "QAT", caption: "Qatar" },
    { value: "REU", caption: "Réunion" },
    { value: "ROU", caption: "Romania" },
    { value: "RUS", caption: "Russian Federation" },
    { value: "RWA", caption: "Rwanda" },
    { value: "BLM", caption: "Saint Barthélemy" },
    { value: "SHN", caption: "Saint Helena, Ascension and Tristan da Cunha" },
    { value: "KNA", caption: "Saint Kitts and Nevis" },
    { value: "LCA", caption: "Saint Lucia" },
    { value: "MAF", caption: "Saint Martin (French part)" },
    { value: "SPM", caption: "Saint Pierre and Miquelon" },
    { value: "VCT", caption: "Saint Vincent and the Grenadines" },
    { value: "WSM", caption: "Samoa" },
    { value: "SMR", caption: "San Marino" },
    { value: "STP", caption: "Sao Tome and Principe" },
    { value: "SAU", caption: "Saudi Arabia" },
    { value: "SEN", caption: "Senegal" },
    { value: "SRB", caption: "Serbia" },
    { value: "SYC", caption: "Seychelles" },
    { value: "SLE", caption: "Sierra Leone" },
    { value: "SGP", caption: "Singapore" },
    { value: "SXM", caption: "Sint Maarten (Dutch part)" },
    { value: "SVK", caption: "Slovakia" },
    { value: "SVN", caption: "Slovenia" },
    { value: "SLB", caption: "Solomon Islands" },
    { value: "SOM", caption: "Somalia" },
    { value: "ZAF", caption: "South Africa" },
    { value: "SGS", caption: "South Georgia and the South Sandwich Islands" },
    { value: "SSD", caption: "South Sudan" },
    { value: "ESP", caption: "Spain" },
    { value: "LKA", caption: "Sri Lanka" },
    { value: "SDN", caption: "Sudan" },
    { value: "SUR", caption: "Suriname" },
    { value: "SJM", caption: "Svalbard and Jan Mayen" },
    { value: "SWZ", caption: "Swaziland" },
    { value: "SWE", caption: "Sweden" },
    { value: "CHE", caption: "Switzerland" },
    { value: "SYR", caption: "Syrian Arab Republic" },
    { value: "TWN", caption: "Taiwan, Province of China" },
    { value: "TJK", caption: "Tajikistan" },
    { value: "TZA", caption: "Tanzania, United Republic of" },
    { value: "THA", caption: "Thailand" },
    { value: "TLS", caption: "Timor-Leste" },
    { value: "TGO", caption: "Togo" },
    { value: "TKL", caption: "Tokelau" },
    { value: "TON", caption: "Tonga" },
    { value: "TTO", caption: "Trinidad and Tobago" },
    { value: "TUN", caption: "Tunisia" },
    { value: "TUR", caption: "Turkey" },
    { value: "TKM", caption: "Turkmenistan" },
    { value: "TCA", caption: "Turks and Caicos Islands" },
    { value: "TUV", caption: "Tuvalu" },
    { value: "UGA", caption: "Uganda" },
    { value: "UKR", caption: "Ukraine" },
    { value: "ARE", caption: "United Arab Emirates" },
    { value: "GBR", caption: "United Kingdom" },
    { value: "UMI", caption: "United States Minor Outlying Islands" },
    { value: "URY", caption: "Uruguay" },
    { value: "UZB", caption: "Uzbekistan" },
    { value: "VUT", caption: "Vanuatu" },
    { value: "VEN", caption: "Venezuela, Bolivarian Republic of" },
    { value: "VNM", caption: "Viet Nam" },
    { value: "VGB", caption: "Virgin Islands, British" },
    { value: "VIR", caption: "Virgin Islands, U.S." },
    { value: "WLF", caption: "Wallis and Futuna" },
    { value: "ESH", caption: "Western Sahara" },
    { value: "YEM", caption: "Yemen" },
    { value: "ZMB", caption: "Zambia" },
    { value: "ZWE", caption: "Zimbabwe" }
];

export default AddressEditor;