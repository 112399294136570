import { useCookies, useGlobalContext } from '../SamState';

import { FreeItemRecord, FGOrddtlRecord } from '../../interfaces/fg-api-interfaces';

const useFGShoppingCart = () => {
    const { getCookie, setCookie, removeCookie, areCookiesEnabled } = useCookies();
    const { getContext, setContext } = useGlobalContext();

    // call the following after every refresh
    const testCookies = () => {
        setContext("cookiesEnabled", areCookiesEnabled());
    }
    const isCartEnabled = (): boolean => {
        return getContext("cookiesEnabled");
    }
    // return value is immutable
    const getItems = (): FGOrddtlRecord[] => {
        const cart: FGOrddtlRecord[] = getCookie("cart");
        if (cart) {
            cart.forEach(item => {
                item.sku_long = parseInt(item.sku_long + '');
                item.price = parseFloat(item.price + '');
                item.qty = parseInt(item.qty + '');
                item.ext = item.price * item.qty;
            })
        }
        return cart ? cart : [];
    }
    const setItems = (items: FGOrddtlRecord[]) => {
        setCookie("cart", items, 30);
    }
    const clear = () => {
        removeCookie("cart");
    }
    const lineCount = (): number => {
        return getItems().length;
    }
    const itemCount = (): number => {
        const items = getItems();
        let count = 0;
        for (const item of items) {
            count += item.qty;
        }
        return count;
    }
    const pieceCount = (): number => {
        return getItems().reduce((tot, item) => tot += item.qty, 0);
    }
    const findItem = (sku: number) => {
        const items = getItems();
        for (let i = 0; i < items.length; i++) {
            if (items[i].sku_long + '' === sku + '') {
                return i;
            }
        }
        return -1;
    }
    const addItem = (item: FGOrddtlRecord) => {
        const newItems = getItems();
        const index = findItem(item.sku_long);
        if (index !== -1) {
            newItems[index].qty += item.qty;
            newItems[index].ext = newItems[index].qty * newItems[index].price!;
        } else {
            newItems.push(item);
        }
        setItems(newItems);
    }
    // item is as returned from api fgGetCouponValue: { sku_long, caption, filename }
    const addFreebie = (item: FreeItemRecord) => {
        const newItems = getItems();
        const index = findItem(item.sku_long);
        if (index === -1) {
            newItems.push({ sku_long: item.sku_long, caption: item.caption, price: 0, image: { filename: item.image, display_order: 0 }, qty: 1 });
        }
        setItems(newItems);
    }
    // called from login and register to merge saved cart (if any) with current cart
    // prices and qty's are overwritten
    const merge = (savedCart: FGOrddtlRecord[]) => {
        const newItems = getItems();
        savedCart.forEach(savedItem => {
            const item = newItems.find(item => item.sku_long + '' === savedItem.sku_long + '');
            if (item) {
                item.qty = savedItem.qty;
            } else {
                newItems.push(savedItem);
            }
        });
        setItems(newItems);
    }
    const removeItem = (sku_long: number) => {
        const index = findItem(sku_long);
        if (index !== -1) {
            const newItems = [...getItems()];
            newItems.splice(index, 1);
            setItems(newItems);
        }
    }
    const setQty = (sku_long: number, qty: number) => {
        const index = findItem(sku_long);
        if (index !== -1) {
            const newItems = [...getItems()];
            newItems[index].qty = qty;
            newItems[index].ext = newItems[index].qty * newItems[index].price!;
            setItems(newItems);
        }
    }
    return {
        testCookies,
        isCartEnabled,
        clear,
        lineCount,
        itemCount,
        pieceCount,
        addItem,
        addFreebie,
        removeItem,
        getItems,
        setItems,
        setQty,
        merge
    }
}
export default useFGShoppingCart;
