// interfaces shared with ReactFG and Node api
import { AddressRecord, CreditCardSummaryRecord, CreditCardRecord, MenuItemData, TokenRecord, ImageRecord, TextOrImageRecord } from './lib-api-interfaces';

export const shipOrderIdOffset = 500000;        // add this to raw order ID to make ship_order_id (stored in jmc_shiplog)

// verified means it is PayPal and no charges were processed
export enum SubmitOrderReturnStatus { verifiedAndCharged = 'C', verified = 'V', totalsMismatch = 'M', paymentDeclined = 'D' }
// following must agree with ShippableOrder.cs
// saved not used; billed=paid
export enum FGOrderStatus { entered = '0', saved = '1', billed = '2', packing = '3', shipped = '4', canceled = '5' }
export enum FGCustInfoOptions { addresses = 'A', orderInfo = 'O', creditCard = 'C', orderHistory = 'H', testOrderHistory = 'T' }
// following must agree with enum in CommonRewards.cs
export enum RewardsActivityEnum { sale = 'S', voucher = 'V', earnedEmailSent = 'E', closeToNextEmailSent = 'C', none = '?' }
export enum VoucherError { invalidCode = 201, notFound = 202, voucherUsed = 203 }

// following are stored in the sku field for fg5_product_related table
export enum FGHomePageProducts { featuredSku = 1, popularSku = 2, newSku = 3 }
export enum WhereItsMadeEnum {
    usa = 'U',
    fairTrade = 'F',
    europe = 'E',
    canada = 'C',
    latinAmerica = 'L',
    ethical = 'T',
    thailand = 'H',
    japan = 'J',
    australia = 'A',
    overseas = 'O',
    unknown = 'N',
}
export interface ApiContactRecord {
    name: string;
    email: string;
    phone: string;
    order_id: string;
    comment: string;
}
export interface SubmitOrderRecord {
    order: FGOrderRecord;
    shopping_cart: FGOrddtlRecord[],
    paymentMethod: PayMethod;
    cc: CreditCardRecord;
}
export interface SubmitOrderResult {
    status: SubmitOrderReturnStatus;
    verbiage?: string;              // cc result verbiage if cc declined
    order?: FGOrderRecord;        // the corrected order if issues were found
    shopping_cart?: FGOrddtlRecord[]; // the corrected cart if issues were found
    issues: string[];               // the issues that prevented the order from being submitted; zero length if no issues
    order_id?: number;               // valid if order was submitted successfully
}

export interface HomePageRecord {
    carousel: ImageRecord[];
    featured: FGBaseProductRecord[];
    popular: FGBaseProductRecord[];
    newItems: FGBaseProductRecord[];
    menuItems: MenuItemData[];          // not included in dashboard home page call
    [key: string]: any;
}
export enum PayMethod { cc = 'C', paypal = 'P' }
// following is an order as submitted via api from JS client
// the in-memory order record is same except address stored as classes; see useFGOrder for interfface
export interface FGOrderRecord {
    order_id: number;
    cust_id: number;        // int
    voucher_tot: number;    // if nonzero the cart is empty and the order is for a gift certificate
    bill_email: string;     // this is held separate in memory but integrated into bill_address before saving to DB
    bill_address: AddressRecord;
    ship_address: AddressRecord;
    extras: ExtrasRecord;
    totals: TotalsRecord;
}
export interface FGOrderWithCartRecord {
    order: FGOrderRecord;
    shopping_cart: FGOrddtlRecord[];
}

export enum ShipMethodEnum { regular = 'R', expedited = 'E', quoteRequested = 'Q' }
export interface FGFreightQuoteRecord {
    order_id?: number;      // only required when posting a quote
    regular: number;
    expedited: number;
    comment: string;
}
export interface ExtrasRecord {
    gift_message?: string;
    gift_wrap?: boolean;
    comments?: string;
    coupon?: CouponRecord;
    voucher_applied?: VoucherRecord;
    rewards?: RewardsRecord;
    send_voucher_date?: Date;
    ship_method?: ShipMethodEnum;       // zero or missing for "regular"
    freight_quotes?: FGFreightQuoteRecord;    // if order is being loaded after customer requested freight quote
    [key: string]: any
}
export interface CouponRecord {
    coupon: string;
    discount: number;       // int %
    free_item: FreeItemRecord;
}
export interface FreeItemRecord {
    sku_long: number;       // long
    caption: string;
    image: string;          // filename
}
export interface VoucherRecord {
    voucher_code: string;   // base-pin (includes dash)
    balance: number;        // money
}
export interface VoucherRecordFull extends VoucherRecord {
    voucher_id: number;
    pin: number;
    issue_date: Date;
    usage_date: Date;
}

export interface RewardsRecord {
    rewards_id: number;     // same as cust_id in fgdb_maillist
    balance: number;        // money
}
export interface TotalsRecord {
    inv_net: number;        // money
    nontaxable: number;        // money  (total of gift certificates purchased)
    discount: number;        // money 
    sales_tax: number;        // money 
    freight: number;        // money
    fixed_freight: number;  // money
    voucher_applied: number;        // money
    reward_applied: number;        // money
    inv_tot: number;        // money 
}
// passed into register as body
export interface ApiRegisterRecord {
    email: string;
    password: string;
    bill_address: AddressRecord;
    phone?: string;      // if rewards membership requested
}
export interface ApiLoginRecord {
    email: string;
    password: string;
}
// returned from login and register; along with token
export interface FGCustInfoRecord {
    cust_id: number;
    login_email: string;        // use in case default bill address doesn't have an email
    bill_addresses: AddressRecord[];
    ship_addresses: AddressRecord[];
    shopping_cart: FGOrddtlRecord[];
    credit_card: CreditCardSummaryRecord;
    rewards?: RewardsRecord;
    saved_order?: FGOrderRecord;
    order_history?: FGOrderHistoryRecord[];
    bill_address?: AddressRecord;       // returned with order_history
}
export interface FGLoginRecord {
    token: TokenRecord;
    custInfo: FGCustInfoRecord;
}
export interface FGOrderHistoryRecord {
    order_id: number;
    order_date: string;
    inv_net: number;
    ship_address: AddressRecord;
    track_no: string;
    details: FGOrddtlRecord[];
}

export interface GetDashboardProductsResult {
    data?: FGDashboardProductRecord[];    // returned if requested (using length param); Dashboard returned from fgLoadDashboardSku
    start?: number;      // start row #, returned if there is data
    totCount?: number;   // total no. of rows in table, returned if requested
}
export interface GetDashboardSkuResult {
    data: FGDashboardProductRecord | null;
}

export interface ApiGetProductsRecord {
    count: number;
    categoryCaption?: string;
    url?: string;               // only returned if count===1; this is the full url for the product
    data?: FGBaseProductRecord[] | FGProductRecord;
    // if count=1 data=FGProductRecord (suitable for full product page)
    // if count>1 data=FGBaseProductRecord[] (for thumbnail grid) and categoryCaption is passsed
    // if nothing found count=0 and other fields undefined
}
// base record suitable for thumbnail grid
export interface FGBaseProductRecord {
    sku_long: number;   // long
    caption?: string;   // optional for related products
    price?: number;  // money; optional for related products
    url?: string;       // ditto
    image?: ImageRecord;      // same as images[0]; only filename and sizePct are required here
    display_order?: number;
}
export interface YmalRecord {
    sku_long: number;
    image: ImageRecord;
    caption: string;
    price: number;
}
export enum OrderHistoryStatusEnum { inStock = 'S', outOfStock = 'O', discontinued = 'D' }
export interface FGOrddtlRecord extends FGBaseProductRecord {
    qty: number;        // int
    ext?: number;        // money
    status?: OrderHistoryStatusEnum;    // for order history only
}
// used when showing single product
export interface FGProductRecord extends FGBaseProductRecord {
    images?: ImageRecord[];
    videos?: ImageRecord[];
    bullet_points?: string[];
    qoh: number;       // only needed on product "buy" page
    ymal?: YmalRecord[];
    whereItsMade?: WhereItsMadeEnum;
    oos?: boolean;      // not needed in detail (shopping cart) record
}
export interface FGDashboardProductHomeSettings {
    isFeatured: boolean;
    isPopular: boolean;
    isNew: boolean;
    [key: string]: boolean
}
export interface FGDashboardProductRecord extends FGProductRecord {
    isNew: boolean;
    active: boolean;
    vendor: string;
    vendor_id: number;
    // following are for dashboard product editor only
    keywords: string;
    categories: number[];      // list of categories this product belongs to
    homePageSettings: FGDashboardProductHomeSettings;
    add_date: Date;
    mod_date: Date;
}

export interface FGCategoryTreeRecord {
    subcategories?: FGCategoryTreeRecord[];   // always undefined if last subcategory
    categoryId: number;         // key to FGCategoryRecord for retrieving caption and url
    displayOrder?: number;       // from table; for inserting new categories
    // following are maintained by CategoryEditor
    isExpanded?: boolean;
    isChecked?: boolean;
    isIndented?: boolean;        // in this one there is only one level of indentation
    parentId?: number;              // applies only to popup items
    subcategoryChecked?: boolean;    // applies only to main category items; used only inside category editor
}
export interface FGCategoryRecordProperty {
    caption: string;
    url?: string;           // maintained by api and not required for saving
    links?: string[];       // split from linked_urls field
    image_filename?: string;
    productCount?: number;      // returned when called from dashboard menu editor
}
export type FGCategoryRecord = Record<number, FGCategoryRecordProperty>

export interface FGCategoriesApiRecord {
    categories: FGCategoryRecord;
    categoryTree: FGCategoryTreeRecord[];
}

