import React from 'react';
import styled from 'styled-components';

import useCreditCards, { CreditCardEditor } from '../../libs/forms/CreditCardsV2';
import { StyledErrorText } from '../../libs/libSupport';
import { useTokens } from '../../libs/SamState';
import { genericApiError, useFetchApi, usePostApi } from '../../libs/useDataApiV2';
import MasterPage from '../MasterPage';

import { FGCustInfoRecord } from '../../interfaces/fg-api-interfaces';

import api from '../../api-url';
import { useNavigate } from 'react-router-dom';
import { CreditCardRecord } from '../../interfaces/lib-api-interfaces';

const MyWallet: React.FC = () => {
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const [saveCard, setSaveCard] = React.useState(false);

    const { fetch } = useFetchApi();
    const { post } = usePostApi();
    const { getToken } = useTokens();
    const creditCard = useCreditCards();
    const navigate = useNavigate();

    const token = getToken()!.token;

    const gotWallet = (result: FGCustInfoRecord) => {
        creditCard.setCcSummary(result.credit_card);
    }
    React.useEffect(() => {
        setErrorMsg(undefined);
        fetch(api.getCreditCard, token, gotWallet, () => setErrorMsg(genericApiError));
    }, []);
    React.useEffect(() => {
        if (saveCard) {
            setSaveCard(false);
            post(api.saveCreditCard, creditCard.getCreditCard(), () => navigate("/account"), () => setErrorMsg(genericApiError), getToken()!.token);
        }
    }, [saveCard]);

    const saveCreditCard = (cc: CreditCardRecord | null) => {
        if (cc) {
            setSaveCard(true);
        } else {
            navigate("/account");
        }
    }

    if (!creditCard.getCcSummary()) {
        return null;
    }
    return (
        <MasterPage>
            <h1>Manage Your Wallet</h1>
            {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}

            {creditCard.getCcSummary() &&
                <React.Fragment>
                    <p>{"Your wallet " + (creditCard.getFormattedCcSummary() ? ("contains " + creditCard.getFormattedCcSummary()) : "does not contain a credit card")}</p>
                    <h2>Enter a new credit card:</h2>
                    <CreditCardEditor submitCaption="Save this card" cancelCaption="Cancel"
                    onSubmit={saveCreditCard} />
                </React.Fragment>
            }
        </MasterPage>
    )
}
export default MyWallet;
