import React from 'react';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import { Value } from 'react-calendar/dist/cjs/shared/types';

const StyledDateSpan = styled.div`
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13.33px;
    font-weight: 400;
    font-family: Arial,san-serif;
    height: 24px;
    border: 1px solid
    width: 140px;
    text-align: left;
`
const StyledDate = styled.p`
    margin-left: 4px;
`
const StyledDropArrow = styled.p`
    font-size: 14px;
    line-height: 20px;
    width: 20px;
    cursor: pointer;
`
const StyledCalendar = styled.div`
    position: relative;
    width: 350px;
    height: 264px;
    background-color: white;
`
const formatDate = (date: string) => {
    const mDate = dayjs(date);
    if (mDate.isSame(dayjs(), 'd')) {
        return "ASAP";
    }
    return mDate.format("ddd, MMM D, YYYY");
}

interface DateSpanProps {
    value: string;
    name: string;
    setIsOpen: (isOpen: boolean) => void;
}
const DateSpan: React.FC<DateSpanProps> = (props) => {
    const handleClick = () => {
        props.setIsOpen(true);
    }
    return (
        <StyledDateSpan>
            <StyledDate id={props.name}>
                {formatDate(props.value)}
            </StyledDate>
            <StyledDropArrow onClick={handleClick}>
                <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </StyledDropArrow>
        </StyledDateSpan>
    );
}

interface DatePickerProps {
    value: string;
    name: string;
    onChange: (date: string, name: string) => void;
}
const DatePicker: React.FC<DatePickerProps> = (props) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const onChange = (value: Value) => {
        setIsOpen(false);
        const newDate = value as Date;
        props.onChange(newDate.toLocaleDateString(), props.name);
    }
    const value = props.value ? new Date(props.value) : new Date();
    return (
        isOpen ? (
            <StyledCalendar>
            <Calendar
                onChange={onChange}
                value={value}
                minDate={new Date()}
            />
            </StyledCalendar>
        ) : (
                <DateSpan name={props.name} value={value.toString()} setIsOpen={setIsOpen} />
            )
    );
}

export default DatePicker;