import React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AddressType, MenuItemData } from './interfaces/lib-api-interfaces';

import app from './appData';

// routes
import Home from './pages/Home';
import Products from './components/Products';
import { Register } from './components/FGCustomer';
import { Login } from './components/FGCustomer';
import { ResetPassword } from './components/FGCustomer';
import Checkout from './libs/shopping-cart/FGCheckout';
import AccountMain from './components/Account/AccountMain';
import Rewards from './pages/Rewards';
import GiftCertificates from './pages/GiftCertificates';
import Visit from './pages/Visit';
import Contact from './pages/ContactUs';
import Artists from './pages/Artists';
import BetaLogin, { authKey } from './libs/CommonLogin';
import MyWallet from './components/Account/MyWallet';
import MyAddresses from './components/Account/MyAddresses';
import MyOrders from './components/Account/MyOrders';
import FreightQuote from './pages/FreightQuote';
import { SearchResults } from './pages/Search';
import InfoPage from './pages/InfoPage';
import { useGlobalContext, useSessionStore } from './libs/SamState';
import FormMgr from './libs/forms/FormMgr';
import CommonLogin from './libs/CommonLogin';

export const moreMenuItems: MenuItemData =
{
  caption: "MORE...", popupItems: [
    { caption: "About Us", href: "/about-us" },
    { caption: "About the Artists", href: "/artists/index" },      // temporarily removed
    { caption: "Shipping, returns & privacy", href: "/shipping-returns-privacy" },
    { caption: "Where It's Made", href: "/where-its-made" },
    { caption: "What We Make", href: "/what-we-make" },
    { caption: "Visit Us", href: "/visit-us" },
    { caption: "Contact Us", href: "/contact-us" },
    { caption: "Fern's Garden Rewards", href: "/rewards" },
  ]
};

const AppRoutes = () => {
  const { setContext } = useGlobalContext();
  const { getSessionStore } = useSessionStore();

  React.useEffect(() => {
    if (window.location.hostname !== "localhost") {
      const paq = (window as any)._paq;
      paq.push(['setCustomUrl', location.pathname]);
      paq.push(['setDocumentTitle', location.pathname]);
      paq.push(['trackPageView']);
    }
  }, [location]);

  if (app.loginRequired && !getSessionStore(authKey)) {
    return (
      <CommonLogin />
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<InfoPage name="about-us" />} />
        <Route path="/where-its-made" element={<InfoPage name="where-its-made" />} />
        <Route path="/shipping-returns-privacy" element={<InfoPage name="shipping-returns-privacy" />} />
        <Route path="/what-we-make" element={<InfoPage name="what-we-make" />} />
        <Route path="/artists/:url" element={<Artists />} />
        <Route path="/visit-us" element={<Visit />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/rewards" element={<Rewards />} />
        <Route path="/gift-certificates/:param" element={<GiftCertificates />} />
        <Route path="/gift-certificates" element={<GiftCertificates />} />
        <Route path="/freight-quote/:orderId" element={<FreightQuote />} />
        <Route path="/account" element={<AccountMain />} />
        <Route path="/my-orders" element={<MyOrders />} />
        <Route path="/my-bill-addresses" element={<MyAddresses addressType={AddressType.bill} />} />
        <Route path="/my-ship-addresses" element={<MyAddresses addressType={AddressType.ship} />} />
        <Route path="/my-wallet" element={<MyWallet />} />
        <Route path="/reset-password/:encryptedEmail" element={<ResetPassword />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/search" element={<SearchResults />} />
        <Route path="*" element={<Products />} />
      </Routes>
    </BrowserRouter>
  );
}
export default AppRoutes;
