import React from 'react';
import styled from 'styled-components';

import MasterPage from '../components/MasterPage';
import { openInNewTab } from '../libs/libSupport';
import IconButton from '../libs/IconButtonV2';

import app from '../appData';
import { formatImageUrl } from '../libs/ImageFormatter';

const imageAndMapSize = 400;

const MasterContainer = styled.div`
    display: flex;
    max-width: ${2 * imageAndMapSize + 16}px;
    margin-left: auto;
    margin-right: auto;
    border: 8px solid ${app.themes.color};
    margin-bottom: 32px;
    @media screen and (max-width: ${imageAndMapSize * 2}px) {
        flex-direction: column;
        max-width: ${imageAndMapSize}px;
    }
`
const StoreContainer = styled.div`
    max-width: ${imageAndMapSize}px;
    background-color: ${app.themes.color};
    color: ${app.themes.backColor10};
    display: flex;
    flex-direction: column;
    text-align: center;
`
const MapContainer = styled.div`
    position: relative;
    max-width: ${imageAndMapSize}px;
`
const VisitText = styled.p`
    max-width: ${2 * imageAndMapSize + 16}px;
    font-size: 1em;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    line-height: 1.4em;
`
const StoreHoursText = styled.p`
    font-size: 2em;
    margin: 8px;
    font-weight: bold;
`
const DailyHoursText = styled.p`
    margin: 0;
    font-size: 1em;
    line-height: 1.8em;
    font-weight: bold;
`
const PhoneText = styled.p`
    font-size: 1.2em;
    font-weight: bold;
`
const StoreImage = styled.img`
    width: ${imageAndMapSize}px;
    height: ${imageAndMapSize}px;
`
const Visit: React.FC = () => {
    const getBKDirections = () => {
        openInNewTab("https://www.google.com/maps/place/Fern's+Garden/@37.891634,-122.279079,16z/data=!4m5!3m4!1s0x0:0x20aba848451f9e8f!8m2!3d37.8915166!4d-122.2790711?hl=en");
    }
    const buttonStyle = { position: "absolute", right: "20px", top: "8px", width: "140px", height: "50px", fontSize: "22px" };
    return (
        <MasterPage>
            <h1>Visit Us</h1>
            <VisitText>If you live near or have the chance to visit Berkeley, California, we encourage you to stop by our store.
                There you will find hundreds of artisan made products that would not fit on this web site. You will not be disappointed!</VisitText>
            <MasterContainer>
                <StoreContainer>
                    <StoreHoursText>Store hours:</StoreHoursText>
                    <DailyHoursText>Monday 11:00 to 5:00<br />
                        Tuesday 10:30 to 5:30<br />
                        Wednesday 10:30 to 5:30<br />
                        Thursday 10:30 to 5:30<br />
                        Friday 10:30 to 5:30<br />
                        Saturday 10:30 to 5:30<br />
                        Sunday 11:00 to 5:00</DailyHoursText>
                    <PhoneText>(510) 529-4848</PhoneText>
                    <StoreImage src={formatImageUrl("bk_store.jpg", { graphicsSubfolder: "other" })} alt="Our Berkeley store" onClick={getBKDirections} />
                </StoreContainer>
                <MapContainer>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3148.659973865414!2d-122.28126808467898!3d37.891633879738336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085794d2c542715%3A0x20aba848451f9e8f!2sFern&#39;s%20Garden!5e0!3m2!1sen!2sus!4v1617670920444!5m2!1sen!2sus"
                    width={imageAndMapSize + 8} height={724} loading="lazy" />
                <IconButton style={buttonStyle} caption="Directions" onClick={getBKDirections} />
                </MapContainer>

            </MasterContainer>
        </MasterPage>
    )
}
export default Visit;