import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { genericApiError, usePostApi } from '../libs/useDataApiV2';
import { useGlobalContext } from '../libs/SamState';
import AnimatedSearch from '../libs/AnimatedSearch';

import { ApiGetProductsRecord, FGBaseProductRecord, FGProductRecord } from '../interfaces/fg-api-interfaces';
import MasterPage from '../components/MasterPage';
import ProductGrid from '../components/ProductGrid';

import api from '../api-url';
import app from '../appData';

const searchTextKey = "searchText";     // for global context

interface SearchProps {
    center?: boolean;
    foreColor?: string;
}
const Search: React.FC<SearchProps> = (props) => {
    const [message, setMessage] = React.useState<string>();
    const [searchResults, setSearchResults] = React.useState<ApiGetProductsRecord>();

    const { post } = usePostApi();
    const navigate = useNavigate();
    const { setContext, getContext } = useGlobalContext();

    React.useEffect(() => {
        if (searchResults) {
            setSearchResults(undefined);
            navigate("/search", { state: searchResults });
        }
    }, [searchResults]);

    const postSucceeded = (results: ApiGetProductsRecord) => {
        if (results.count === 0) {
            setMessage("No products found");
        } else {
            setMessage(undefined);
            setSearchResults(results);
        }
    }
    const postFailed = () => {
        setMessage(genericApiError);
    }
    const doSearch = (key: string) => {
        setContext(searchTextKey, key);
        setMessage(undefined);
        post(api.search, { searchText: key }, postSucceeded, postFailed);
    }

    return (
        <AnimatedSearch center={props.center} message={message} searchText={getContext(searchTextKey)} 
            doSearch={doSearch} clearSearch={() => setMessage(undefined)} />
    )
}
//-----------------------------------------------------------------
// this is launched from SearchPopup via router after search results come back with at least one result
export const SearchResults: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    React.useEffect(() => {
        window.scrollTo(0, 0);
        const results = (location.state as ApiGetProductsRecord);
        if (results.count === 1) {
            navigate("/" + (results.data as FGProductRecord).sku_long);     // requires another fetch but will display the only search result
        }
    }, []);

    const results = (location.state as ApiGetProductsRecord);

    return (
        <MasterPage>
            <h1>Search Results</h1>
            {results.count > 1 &&
                <ProductGrid products={results.data as FGBaseProductRecord[]} showIdAsSku={true} />
            }
        </MasterPage>
    );
}

export default Search;