import React from 'react';
import styled from 'styled-components';

import SamForm from '../libs/forms/SamFormV5';

import { FormFieldRecord } from '../interfaces/lib-api-interfaces';

const Container = styled.div<{ $width: number; $top: number; $left: number; $backColor: string }>`
    width: ${props => props.$width}px;
    position: fixed;
    top: ${props => props.$top}px;
    left: ${props => props.$left}px;
    box-shadow: 3px 3px 5px 6px #ccc;
    z-index: 3000;
    padding: 16px;
    background-color: ${props => props.$backColor};
`
interface ModalInputProps {
    width: number;
    fields: FormFieldRecord[];
    initialValues?: Record<string, any>;
    allowCancel: boolean;
    backColor?: string;     // defaults to lemonchiffon
    onSubmit: (values: Record<string, any> | null) => void;
}
const ModalInput: React.FC<ModalInputProps> = (props) => {
    return (
        <Container $width={props.width} $top={(window.innerHeight - 60) / 2} $left={(window.innerWidth - props.width - 32) / 2} $backColor={props.backColor ? props.backColor : "lemonchiffon"}>
            <SamForm id="modalInput" fields={props.fields} initialValues={props.initialValues} createCancelButton={props.allowCancel}
                submitButtons={[{ id: "submit", caption: "OK", validate: true }]}
                handleSubmit={props.onSubmit} />
        </Container>
    )
}
export default ModalInput;
