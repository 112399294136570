import app from './appData';

import { FGCustInfoOptions } from './interfaces/fg-api-interfaces';
import { DivisionEnum } from './interfaces/lib-api-interfaces';

const dashboardLogin = "/api/dashboardLogin/";
const validateToken = "/api/validateToken";

// web site
const getHomePage = "/api/fgGetHomePage";
const isTestMode = "/api/getFGTestMode";
const getInfoContent = "/api/getInfoContent/" + DivisionEnum.fg + "/"; // add page url OR section code to get index of section
const getCommonInfo = "/api/getCommonInfo";     // d=division, u=url(s)
const getInfoPage = "/api/getInfoPage/" + app.domain + "/";       // + name

// customers
const getCustInfo = "/api/fgGetCustInfo/";
const getAddresses = getCustInfo + FGCustInfoOptions.addresses;
const getCreditCard = getCustInfo + FGCustInfoOptions.creditCard;
const getCustOrderInfo = "/api/fgGetCustInfo/" + FGCustInfoOptions.orderInfo;
const getCustOrderHistory = "/api/fgGetCustInfo/" + FGCustInfoOptions.orderHistory;
const getCustOrderHistoryTest = "/api/fgGetCustInfo/" + FGCustInfoOptions.testOrderHistory;
const saveAddressBook = "/api/fgSaveAddressBook";
const saveCreditCard = "/api/fgSaveCreditCard";
const contact = "/api/fgContact";
const sendPasswordResetLink = "/api/fgSendPasswordResetLink";
const login = "/api/fgLogin";
const register = "/api/fgRegister";
const registerForRewards = "/api/fgRegisterForRewards";
const resetPassword = "/api/fgResetPassword";
const getVoucherValue = "/api/fgGetVoucherValue";

// products
const getProducts = "/api/fgGetProducts/"; // add "?u=/URL"
const search = "/api/fgSearch";
const fgRequestOosEmail = "/api/fgRequestOosEmail";

// orders
const verifyOrderTotals = "/api/fgVerifyTotals";
const saveOrder = "/api/fgSaveOrder";
const submitOrder = "/api/fgSubmitOrder";
const finishOrder = "/api/fgFinishOrder";
const applyCoupon = "/api/fgGetCouponValue";       // returns coupon value as discount % or $ off
const applyVoucher = "/api/fgGetVoucherValue";     // returns $$$ value of voucher
const loadAnyOrder = "/api/fgLoadAnyOrder/";       // +orderId

export default {
    dashboardLogin,
    validateToken,
    getHomePage,
    getInfoContent,
    getCommonInfo,
    getInfoPage,
    isTestMode,
    getProducts,
    getCustOrderInfo,
    getCustOrderHistory,
    getCustOrderHistoryTest,
    getAddresses,
    getCreditCard,
    verifyOrderTotals,
    saveAddressBook,
    saveCreditCard,
    contact,
    sendPasswordResetLink,
    resetPassword,
    login,
    register,
    registerForRewards,
    getVoucherValue,
    search,
    fgRequestOosEmail,
    saveOrder,
    submitOrder,
    finishOrder,
    applyCoupon,
    applyVoucher,
    loadAnyOrder,
}
