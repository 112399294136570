import React from 'react';
import ReactDOM from "react-dom";       // required for PayPal
import styled from 'styled-components';

import useFGShoppingCart from './useFGShoppingCart';
import useFGOrder from './useFGOrder';
import { StyledErrorText } from '../libSupport';
import { useTokens } from '../SamState';
import { usePostApi, asyncApi } from '../useDataApiV2';
import useCreditCards, { CreditCardEditor, editorWidth } from '../forms/CreditCardsV2';
import IconButton from '../IconButtonV2';

import { SubmitOrderResult, PayMethod, SubmitOrderReturnStatus, SubmitOrderRecord } from '../../interfaces/fg-api-interfaces';
import { CreditCardRecord, CreditCardSummaryRecord } from '../../interfaces/lib-api-interfaces';

import api from '../../api-url';

interface WindowWithPaypal extends Window {
    paypal: any;
}
const MasterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: ${editorWidth}px;
    margin-left: auto;
    margin-right: auto;
    h4 {
        font-size: 20px;
        text-align: left;
    }
`
const PaypalNoteText = styled.p`
    margin: 0;
    font-size: 12px;
    width: 250px;
    text-align: center;
`
interface PayAndSubmitProps {
    isGiftCertificate: boolean;     // determines text of submit button
    validate?: () => boolean;   // for validation or create gift certificate order record; return false to cancel submission, true to proceed with cc submit
    orderCompleted: () => void;   // called upon successful completion (payment has been received) -- parent should clear order and show thank you message
}
const PayAndSubmit: React.FC<PayAndSubmitProps> = (props) => {
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const [submitCCOrder, setSubmitCCOrder] = React.useState<boolean>(false);      // set to true when user has clicked "Buy now" with credit card
    const [paypalCompleted, setPaypalCompleted] = React.useState<boolean>(false);   // set to true after we have returned from paypal authorization site

    // submit order handling on server:
    // if payment method is cc, card is charged and order is completed on server (confirming email, voucher handling, etc.)
    // if payment method is paypal, order is verified and saved but nothing is completed (must call fgFinishOrder upon funds capture)
    const order = useFGOrder();
    const cart = useFGShoppingCart();
    const cc = useCreditCards();
    const { post } = usePostApi();
    const { getToken } = useTokens();

  //  console.log({ order, cart, cc })

    const submitOrderServerErrorMsg = "We're so sorry, but our server is not behaving right now. Please try again later or call 510-529-4848";

    const handleCCOrderSubmitted = (result: SubmitOrderResult) => {
        if (result.status === SubmitOrderReturnStatus.verifiedAndCharged) {
            props.orderCompleted();
        } else if (result.status === SubmitOrderReturnStatus.paymentDeclined) {
            setErrorMsg("We were unable to charge your credit card. Please resubmit with a different card, or use PayPal."
                + (result.verbiage ? (" The message from the card processor was: " + result.verbiage) : ''));
        } else if (result.status === SubmitOrderReturnStatus.totalsMismatch) {
            handleMismatchedTotals(result);
        }
    }
    const handleMismatchedTotals = (result: SubmitOrderResult) => {
        order.setOrder(result.order!);
        cart.setItems(result.shopping_cart!);
        let issues = '';
        result.issues!.forEach(issue => {
            issues += (issue + '; ');
        });
        issues = issues.slice(0, -2);
        setErrorMsg("The total amount of your order was incorrect due to the following: " + issues + ". Please verify the corrected totals at right and resubmit your order.");
    }

    React.useEffect(() => {
        setErrorMsg(undefined);
        const token = getToken() ? getToken()!.token : null;
        if (submitCCOrder) {
            // this will charge card and complete server tasks
            post(api.submitOrder, { order: order.getOrder(), shopping_cart: cart.getItems(), paymentMethod: PayMethod.cc, cc: cc.getCreditCard() } as SubmitOrderRecord,
                handleCCOrderSubmitted, () => setErrorMsg(submitOrderServerErrorMsg), token);
            setSubmitCCOrder(false);
        } else if (paypalCompleted) {
            post(api.finishOrder, { order: order.getOrder(), shopping_cart: cart.getItems() }, () => { }, () => { }, token);
            setPaypalCompleted(false);
            props.orderCompleted();
        }
    }, [submitCCOrder, paypalCompleted]);

    const ccSubmitted = (values: CreditCardRecord | null) => {
        if (!props.validate || props.validate()) {  // onSubmit could still cancel the submission
            setSubmitCCOrder(true);     // trigger the post; credit card editor has stored card # in global context
        }
    }
    //---------- PAYPAL HANDLING -----------------------------
    async function createPaypalOrder(data: any, actions: any) {
        setErrorMsg(undefined);
        if (props.validate && !props.validate()) {  // onSubmit could still cancel the submission
            return;
        }
        // this will verify and save order, and nothing else
        let result: SubmitOrderResult;
        try {
            result = await asyncApi("post", api.submitOrder, { order: order.getOrder(), shopping_cart: cart.getItems(), paymentMethod: PayMethod.paypal }, null);
        } catch {
            setErrorMsg(submitOrderServerErrorMsg);
            return;
        }
        if (result.status === SubmitOrderReturnStatus.totalsMismatch) {
            handleMismatchedTotals(result);
        } else {
            order.setOrderId(result.order_id!);
            // trigger the PayPal authorization login -- returns to paypalApproved or paypalCanceled
            return actions.order.create({
                purchase_units: [
                    {
                        amount: {
                            value: order.getTotals().inv_tot,
                        },
                    },
                ],
            });
        }
    }
    // this is called upon return from PayPal with user's authorization
    async function paypalApproved(data: any, actions: any) {
        try {
            await actions.order.capture();
            setPaypalCompleted(true);           // trigger server tasks and client cleanup and thank you message
        } catch {
            paypalError();
        }
    }

    const paypalCanceled = () => {
        setErrorMsg("Your PayPal payment was canceled. Please try again or use a credit card.");
    }
    const paypalError = () => {
        // don't overwrite error message in case there was an error earlier in the process
        if (!errorMsg) {
            setErrorMsg("We were unable to contact PayPal. Please try again later, or use a credit card.")
        }
    }
    const windowWithPaypal = window as unknown as WindowWithPaypal;
    const PayPalButton = windowWithPaypal.paypal.Buttons.driver("react", { React, ReactDOM });
    //---------- END PAYPAL HANDLING -----------------------------
    return (
        <MasterContainer>
            {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
            {order.getTotals().inv_tot < 0 ? (
                <IconButton style={{fontWeight: "bold"}} caption="Place my order now" icon="fas fa-check" onClick={() => ccSubmitted(null)} />
            ) : (
                <React.Fragment>
                    <CreditCardEditor submitCaption={props.isGiftCertificate ? "Buy gift certificate now" : "Place my order now"} onSubmit={ccSubmitted} />
                    <div style={{ border: "1px solid" }}>
                        <PaypalNoteText>Note: PayPal statement will show charge from Jacob's Musical Chimes</PaypalNoteText>
                        <PayPalButton
                            createOrder={createPaypalOrder}
                            onApprove={paypalApproved}
                            onCancel={paypalCanceled}
                            onError={paypalError}
                            style={{
                                layout: 'horizontal',
                                height: 40,
                                label: "pay",
                                color: "silver"
                            }}
                        />
               </div>
                </React.Fragment>
            )}
        </MasterContainer>
    )
}
export default PayAndSubmit;
