import React from 'react';
import styled from 'styled-components';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { usePostApi, genericApiError } from '../libs/useDataApiV2';
import IconButton from '../libs/IconButtonV2';
import SamForm from '../libs/forms/SamFormV5';
import MasterPage from './MasterPage';
import '../App.css';
import { useTokens, useBookmarks, useSessionStore, useGlobalContext } from '../libs/SamState';
import { StyledErrorText, iPadMaxWidth } from '../libs/libSupport';
import useCreditCards from '../libs/forms/CreditCardsV2';
import useAddressBook from '../libs/useAddressBook';
import AddressEditor from '../libs/forms/AddressEditor';
import useFGOrder from '../libs/shopping-cart/useFGOrder';
import { RewardsSignUp } from '../pages/Rewards';

import { FGLoginRecord, FGCustInfoRecord, ApiRegisterRecord } from '../interfaces/fg-api-interfaces';
import { FormFieldRecord, FormFieldType, AddressType, LoginError, TokenRecord, AddressRecord } from '../interfaces/lib-api-interfaces';

import app from '../appData';
import api from '../api-url';
import FormMgr from '../libs/forms/FormMgr';

const sessionStoreKey = "custInfo";

//----------- USE CUSTOMERS ----------------------------------
const useFGCustomer = () => {
    const { getSessionStore, setSessionStore } = useSessionStore();
    const addressBook = useAddressBook();
    const cc = useCreditCards();

    const finishLogin = (custInfo: FGCustInfoRecord) => {
        setCustInfo(custInfo);
        if (custInfo.ship_addresses) {
            addressBook.setAddresses(AddressType.ship, custInfo.ship_addresses);
        }
        if (custInfo.bill_addresses) {
            addressBook.setAddresses(AddressType.bill, custInfo.bill_addresses);
        }
        if (custInfo.credit_card) {
            cc.setCcSummary(custInfo.credit_card);
        }
    }
    const getCustInfo = (): FGCustInfoRecord => {
        return getSessionStore(sessionStoreKey);
    }
    const setCustInfo = (custInfo: FGCustInfoRecord) => {
        setSessionStore(sessionStoreKey, custInfo);
    }
    // this is done at server when order is submitted
    const clearSavedOrder = () => {
        const custInfo = getCustInfo();
        if (custInfo) {
            custInfo.saved_order = undefined;
            setCustInfo(custInfo);
        }
    }

    return {
        getCustInfo,
        finishLogin,
        clearSavedOrder,
    }
}
//---------------- LOGIN -------------------------------------
const StyledContainer = styled.div<{ $foreColor: string }>`
    color: ${props => props.$foreColor};
    max-width: 800px;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
`
const FormDiv = styled.div`
    width: 59%;
`
const NewCustomerBox = styled.div`
    height: 100%;
    padding: 8px 16px 32px 16px;
    width: 39%;
    background-color: lightGray;
    p {
        text-align: left;
        margin: 0;
    }
    li {
        margin-top: 8px;
    }
    ul {
        text-align: left;
        margin-top: 0;
        margin-bottom: 32px;
    }
    h2 {
        font-size: 18px;
        text-align: left;
    }
`
export const doLogout = (clearToken: () => void, deleteSessionStore: (key: string) => void) => {
    deleteSessionStore(sessionStoreKey);
    clearToken();       // remove token from session and cookies
}

const buttonFontSize = window.matchMedia("(max-width: " + iPadMaxWidth + "px)").matches ? 14 : 20;

export const Login: React.FC = () => {
    const { navigateToBookmark } = useBookmarks();
    const navigate = useNavigate();

    const handleResult = (result: boolean) => {
        if (result) {
            navigateToBookmark();
        }
    }
    return (
        <MasterPage>
            <h1>Sign in</h1>
            <StyledContainer $foreColor={app.themes.color!}>
                <FormDiv>
                    <LoginForm handleResult={handleResult} />
                </FormDiv>
                <NewCustomerBox>
                    <h2>New Customer?</h2>
                    <p>Create an account and you'll be able to:</p>
                    <ul>
                        <li>Join our Rewards program to receive $10 off for every $100 you spend</li>
                        <li>Check out faster</li>
                        <li>Save multiple billing and shipping addresses</li>
                        <li>View your orders</li>
                    </ul>
                    <IconButton style={{ marginLeft: "auto", marginRight: "auto", height: "40px", fontSize: buttonFontSize + "px" }} caption="Create account" icon="fas fa-download"
                        onClick={() => navigate("/register")} />
                </NewCustomerBox>
            </StyledContainer>
        </MasterPage>
    );
}

// error is displayed within the form
const LoginFormContainer = styled.div`
    margin-bottom: 32px;
    text-align: left;
`
const ErrorText = styled.p`
    text-align: center;
    color: red;
    font-size: 16px;
    margin-right: 8px;
`
const ExistingCustNote = styled.p`
    margin-right: 8px;
    line-height: 24px;
`
interface LoginFormProps {
    handleResult: (result: boolean) => void;        // passes false on error or user cancel
}
export const LoginForm: React.FC<LoginFormProps> = (props) => {
    const [loginError, setLoginError] = React.useState<number>(200);
    const [loginValues, setLoginValues] = React.useState<Record<string, any>>();    // when set useEffect can post the login

    const { requestReset, renderConfirmation, resetEmailSent } = useRequestReset();
    const { setToken } = useTokens();
    const { post } = usePostApi();
    const order = useFGOrder();
    const cust = useFGCustomer();
    const { setContext } = useGlobalContext();
    const forms = new FormMgr(setContext);

    React.useEffect(() => {
        if (loginValues) {
            post(api.login, loginValues, successfulPost, failedPost);
            setLoginValues(undefined);
        }
    }, [loginValues]);

    const successfulPost = (result: FGLoginRecord, status: number | undefined) => {
        setLoginError(status as number);
        if (status === 200) {
            setToken(result.token, loginValues!.persist);
            cust.finishLogin(result.custInfo);
            order.setCustOrderInfo(order.getOrder(), result.custInfo);
            if (result.custInfo.shopping_cart) {
                order.mergeCarts(result.custInfo.shopping_cart);
            }
            props.handleResult(true);
        }
    }
    const failedPost = () => {
        setLoginError(500);
    }
    const handleSubmit = (inputs: Record<string, any> | null, id: string) => {
        if (inputs) {
            setLoginValues(inputs);
        } else {
            props.handleResult(false);
        }
    }
    const handleResetRequest = () => {
        requestReset(forms.getValue("login", "email"));
    }

    const formData: FormFieldRecord[] = [
        { name: "email", label: "Email address", validator: { required: true, maxLength: 150 } },
        { name: "password", label: "Password", validator: { isPassword: true, required: true, maxLength: 50 } },
        { name: "persist", label: "Remember me on this device", type: FormFieldType.checkbox },
        { name: "reset", type: FormFieldType.link, label: "Forgot password? Request a reset now." }
    ];

    return (
        <LoginFormContainer>
            {renderConfirmation()}
            <ExistingCustNote>
                Note to existing customers: We have added support for <Link to="/rewards"><u>Fern's Garden Rewards</u></Link> to our web site.
                This customer gratitude program will give you a $10 credit for every $100 you spend. To enable Rewards on fernsgarden.com, sign in to your account and visit
                the <Link to="/rewards"><u>Rewards page</u></Link> to sign up (you can also find it from the "MORE..." button on the main menu). If you don't have
                an account, you can sign up for Rewards at the same time you <Link to="/register"><u>register</u></Link> on fernsgarden.com. If you are a Rewards member at
                our Berkeley store, you must still sign up for <Link to="/rewards"><u>Rewards</u></Link> on this web site to link your membership to your fernsgarden.com account.
            </ExistingCustNote>
            {loginError === LoginError.custNotFound &&
                <ErrorText>We cannot find you in our records. Please try a different email or <Link style={{ color: "red" }} to='/register'><u>create a new account</u></Link>.</ErrorText>
            }
            {loginError === LoginError.invalidPassword &&
                <ErrorText>Invalid email or password</ErrorText>
            }
            {loginError >= 500 && <ErrorText>{genericApiError}</ErrorText>
            }

            <SamForm id="login" fields={formData}
                createCancelButton={true}
                submitButtons={[{ caption: "Sign in", id: "submit", icon: "fas fa-sign-in", validate: true }]}
                linkClicked={handleResetRequest} handleSubmit={handleSubmit} />
        </LoginFormContainer>
    )
}
//---------------- REGISTER -------------------------------
const RegisterContainer = styled.div<{ $foreColor: string }>`
    text-align: center;
    color: ${props => props.$foreColor};
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
`
const RegisterFormDiv = styled.div`
    margin-bottom: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
`
const RegisterResultContainer = styled.div`
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 3px 3px 5px 6px #ccc;
    padding: 8px;
    text-align: center;
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
`
const ButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`
interface LocalApiRegisterRecord extends ApiRegisterRecord {
    persist: boolean;
}
export const Register: React.FC = () => {
    const { setToken } = useTokens();
    const { post } = usePostApi();
    const { navigateToBookmark } = useBookmarks();
    const { requestReset, renderConfirmation, resetEmailSent } = useRequestReset();
    const navigate = useNavigate();
    const { setContext } = useGlobalContext();
    const forms = new FormMgr(setContext);

    const order = useFGOrder();
    const cust = useFGCustomer();

    const [errorMsg, setErrorMsg] = React.useState<string>('');
    const [alreadyRegistered, setAlreadyRegistered] = React.useState<boolean>(false);
    const [registerValues, setRegisterValues] = React.useState<LocalApiRegisterRecord>();

    const passwordFields: FormFieldRecord[] = [
        { name: "email", label: "Email address", width: 47, validator: { required: true, maxLength: 150 } },
        { name: "email_verify", label: "Re-enter email", width: 47, validator: { required: true, maxLength: 150 } },
        { name: "password", label: "Your password", width: 47, validator: { isPassword: true, required: true, maxLength: 20 } },
        { name: "password_verify", label: "Re-enter password", width: 47, validator: { isPassword: true, required: true, maxLength: 20 } },
        { name: "persist", label: "Remember me on this device", type: FormFieldType.checkbox },
    ];

    React.useEffect(() => {
        if (registerValues) {
            post(api.register, registerValues, successfulPost, failedPost);
        }
    }, [registerValues]);

    const successfulPost = (result: FGLoginRecord, status: number | undefined) => {
        // status 201: customer is registered and needs to log in
        // status 202: customer was registered on old site and needs to reset password (email sent)
        // status 200: customer has been registered and result contains token
        if (status === 200) {
            setToken(result.token, registerValues!.persist);
            cust.finishLogin(result.custInfo);
            order.setCustOrderInfo(order.getOrder(), result.custInfo);
            if (result.custInfo.shopping_cart) {
                order.mergeCarts(result.custInfo.shopping_cart);
            }
            navigateToBookmark();
        } else if (status === LoginError.invalidPassword) {
            setAlreadyRegistered(true);     // show the "click to login/click to reset password" window
        } else {
            failedPost('');     // must be a 500 status
        }
    }
    const failedPost = (message: string) => {
        if (message) {
            setErrorMsg(message);
        } else {
            setErrorMsg(genericApiError);
        }
    }
    const handleSubmit = () => {
        if (!forms.validateForm("register") || !forms.validateForm("address")) {
            return;
        }
        const addrValues = forms.getFormValues("address");
        const pwValues = forms.getFormValues("register");
        const rewardsValues = forms.getFormValues("rewards");
        setRegisterValues({ bill_address: addrValues as AddressRecord, phone: rewardsValues.phone, email: pwValues.email, password: pwValues.password, persist: pwValues.persist });
    }
    const handleCancel = () => {
        navigateToBookmark();
    }
    const handleResetRequest = () => {
        requestReset(forms.getValue("register", "email"));
    }
    const validator = (name: string, values: Record<string, any>): string | null => {
        if (name === "password_verify" && values[name] !== values["password"]) {
            return "Passwords do not match";
        } else if (name === "email_verify" && values[name] !== values["email"]) {
            return "Email addresses do not match";
        } else {
            return null;
        }
    }
    const buttonStyle = { margin: "8px" };

    return (
        <MasterPage>
            <h1>Create an Account</h1>
            <RegisterContainer $foreColor={app.themes.color!}>
                {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
                {alreadyRegistered &&
                    <RegisterResultContainer>
                        <p>You registered with this email previously, but the password you entered does not match.</p>
                        <ButtonsContainer>
                            <IconButton style={buttonStyle} caption="Log in with a different password" onClick={() => { navigate("/login") }} />
                            <IconButton style={buttonStyle} caption="Request a password reset" onClick={handleResetRequest} />
                        </ButtonsContainer>
                    </RegisterResultContainer>
                }
                {renderConfirmation()}
                {!alreadyRegistered && !resetEmailSent &&
                    <RegisterFormDiv>
                        <SamForm id="register" fields={passwordFields} customValidator={validator} />
                        <AddressEditor id="address" values={null} excludedFields={["phone", "email", "storefront", "res_del", "is_default", "address_id"]} />
                        <RewardsSignUp addOptional={true} showSubmitButton={false} />
                        <ButtonsContainer>
                            <IconButton style={buttonStyle} caption="Register" icon="fas fa-download" onClick={handleSubmit} />
                            <IconButton style={buttonStyle} caption="Cancel" icon="fas fa-ban" onClick={handleCancel} />
                        </ButtonsContainer>
                    </RegisterFormDiv>
                }
            </RegisterContainer>
        </MasterPage>
    );
}
//---------------- RESET PASSWORD ----------------------
//------- Handle reset request -------------
const ResetConfirmContainer = styled.div`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 3px 3px 5px 6px #ccc;
    padding: 8px;
    text-align: center;
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
`
const useRequestReset = () => {
    const [resetEmailSent, setResetEmailSent] = React.useState<boolean>(false);
    const [requestedResetSent, setRequestedResetSent] = React.useState<boolean>(false);
    const [errorMsg, setErrorMsg] = React.useState<string>();

    const { post } = usePostApi();

    const postReturned = (data: any, status?: number) => {
        if (status !== 200) {
            setErrorMsg("The email you submitted is not in our system.")
        } else {
            setRequestedResetSent(true);
        }
    }
    const requestReset = (email: string) => {
        if (!email) {
            setErrorMsg("Please enter your email before clicking the reset password link.");
        } else {
            setErrorMsg(undefined);
            setResetEmailSent(true);
            post(api.sendPasswordResetLink, { email }, postReturned, () => setErrorMsg(genericApiError));
        }
    }
    const renderConfirmation = () => {
        if (requestedResetSent || errorMsg) {
            return (
                <ResetConfirmContainer>
                    {errorMsg ? (
                        <p>{errorMsg}</p>
                    ) : (
                        <p>We have sent you an email with a link to reset your password. Please check your email and click the link to enter a new password.</p>
                    )}
                </ResetConfirmContainer>
            )
        } else {
            return null;
        }
    }
    return {
        requestReset,
        renderConfirmation,
        resetEmailSent
    }

}
//------- Handle landing page after reset email has been sent --------
const ResetFormDiv = styled.div`
    margin: 16px auto 32px auto;
    width: 500px;
`
export const ResetPassword: React.FC = () => {
    const [resetSucceeded, setResetSucceeded] = React.useState(false);

    const { setToken } = useTokens();
    const { post } = usePostApi();
    const { navigateToBookmark } = useBookmarks();
    const navigate = useNavigate();
    const params = useParams();
    const { setContext } = useGlobalContext();
    const forms = new FormMgr(setContext);

    const encryptedEmail = params.encryptedEmail;

    const formData: FormFieldRecord[] = [
        { name: "password", label: "New password", validator: { isPassword: true, required: true, maxLength: 50 } },
        { name: "password_verify", label: "Re-enter password", validator: { isPassword: true, required: true, maxLength: 50 } },
    ];
    const validator = (name: string, values: Record<string, any>) => {
        if (name === "password_verify" && values[name] !== values["password"]) {
            return "Passwords do not match";
        } else {
            return '';
        }
    }
    const successfulPost = (result: TokenRecord) => {
        alert("Your password update was successful. Thank you!");
        setToken(result);
        navigate('/');
    }
    const failedPost = (message: string) => {
        if (message) {
            alert(message);
        } else {
            alert(genericApiError);
        }
    }
    const handleSubmit = (values: Record<string, any> | null) => {
        if (values) {
            const inputs = forms.getFormValues("resetPassword");
            post(api.resetPassword, { password: inputs.password, encryptedEmail }, successfulPost, failedPost);
        } else {
            navigateToBookmark();
        }
    }

    return (
        <MasterPage>
            <h1>Reset Password</h1>
            <ResetFormDiv>
                <SamForm id="resetPassword" fields={formData}
                    submitButtons={[{ id: "submit", caption: "Set new password", icon: "fas fa-check", validate: true }]}
                    createCancelButton={true}
                    customValidator={validator} handleSubmit={handleSubmit} />
            </ResetFormDiv>
            {resetSucceeded && null}
        </MasterPage>
    )
}

export default useFGCustomer;
