import React from 'react';
import styled from 'styled-components';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';

//import NavBar, { SlidingNavBar } from '../libs/NavBarV5';
import { SlidingNavBar } from '../libs/NavBarV5';
import NavBar from '../libs/NavBarV5';
import { moreMenuItems } from '../Routes';
import { useTokens, useBookmarks, useGlobalContext, useSessionStore, useCookies } from '../libs/SamState';
import { iPadMaxWidth, iPadMinWidth } from '../libs/libSupport';
import Search from '../pages/Search';
import useFGCustomer, { doLogout } from './FGCustomer';
import { useFetchApi, apiStateKey } from '../libs/useDataApiV2';
import { CartIcon } from '../libs/shopping-cart/CartPopup';
import useFGShoppingCart from '../libs/shopping-cart/useFGShoppingCart';
import useFGOrder from '../libs/shopping-cart/useFGOrder';
import IconButton, { ButtonsRow } from '../libs/IconButtonV2';
import ContextMenu, { ContextMenuInfo, ContextMenuItem } from '../libs/SamContextMenu';
import Spinner from '../libs/Spinner';
import useAddressBook from '../libs/useAddressBook';
import { MenuItemData, AddressType } from '../interfaces/lib-api-interfaces';
import { HomePageRecord, FGCustInfoRecord } from '../interfaces/fg-api-interfaces';
import { IconButtonAlignEnum, SliderRecord } from '../interfaces/lib-react-interfaces';
import { formatImageUrl } from '../libs/ImageFormatter';
import FormMgr from '../libs/forms/FormMgr';

import app from '../appData';
import api from '../api-url';

const StyledMasterContainer = styled.div<{ $defaultFonts: string; $foreColor: string; $h1FontFamily: string }>`
    text-align: center;
    font-family: ${props => props.$defaultFonts};
    color: ${props => props.$foreColor};
    a:link {
        text-decoration: none; color: ${props => props.$foreColor}
    }
    a:visited {
        text-decoration: none; color: ${props => props.$foreColor}
    }
    a:hover {
        font-style: italic; text-decoration: underline; color: ${props => props.$foreColor}
    }
    h1 {
        font-family: ${props => props.$h1FontFamily};
        text-align: center;
        font-size: 32px;
        margin-top: 24px;
        margin-bottom: 16px;
        font-weight: bold;
        @media (max-width: ${iPadMinWidth}px) {
            font-size: 18px;
        }
    }
    h3 {
        font-size: 16px;
        font-weight: bold;
        margin-top: 4px;
        margin-bottom: 4px;
        @media (max-width: ${iPadMinWidth}px) {
            font-size: 14px;
    }

`
const StyledContentContainer = styled.div`
    width: 100%;
`
// holds 3 horizontal divs: banner, above-logo (social media, search, login), logo
const StyledPageHeader = styled.div<{ $backColor: string }>`
    display: flex;
    width: 100%;
    flex-direction: column;
    font-size: 14px;
    line-height: 18px;
    background-color: ${props => props.$backColor};
    @media screen and (max-width: ${iPadMinWidth}px) {
        font-size: 10px;
        line-height: 12px;
    }
`
// banner above logo shown in reverse
const StyledBanner = styled.div<{ $bannerFont: string; $backColor: string; $foreColor: string }>`
    font-family: ${props => props.$bannerFont};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.$backColor};
    width: 100%;
    color: ${props => props.$foreColor};
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
    p {
        margin: 0 8px 0 8px;
    }
    @media screen and (max-width: ${iPadMinWidth}px) {
        font-size: 12px;
        line-height: 18px;
    }
`
// social media, search, login, shopping cart icon, etc.
const StyledAboveLogo = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 32px;
    align-items: center;
    margin-bottom: 0;
    margin-top: 12px;
    margin-right: 8px;
`
// groups of items such as social media icons
const AboveLogoGroup = styled.div`
    display: flex;
    justify-content: flex-start;
    height: 100%;
    align-items: center;
    margin-right: 24px;
    @media screen and (max-width: ${iPadMinWidth}px) {
        margin-right: 16px;
    }
`
const StyledLogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
const AboveLogoText = styled.span`
    cursor: pointer;
    :hover
    {
        font-style: italic;
    }
`
const StyledLogo = styled.img`
    max-width: 400px;
    height: auto;
    margin: 12px auto 12px auto;
    cursor: pointer;
    @media screen and (max-width: ${iPadMinWidth}px) {
        max-width: 60%;
    }
`
const FooterPlusSocialMedia = styled.div<{ $foreColor: string; $backColor: string; $serifFont: string }>`
    color: ${props => props.$foreColor};
    background-color: ${props => props.$backColor};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 20px;
    padding-top: 6px;
    border-top: 16px solid;
    border-bottom: 16px solid;
    h2 {
        text-align: left;
        font-weight: bold;
        font-family: ${props => props.$serifFont};
        font-size: 18px;
        margin-bottom: 8px;
    }
`
const StyledFooterContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    margin: 0 auto 0 auto;
`
const FooterListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 8px;
    padding-right: 8px;
    p {
        font-size: 14px;
        line-height: 24px;
        margin: 0;
    }
`
const AddressBox = styled.div`
    cursor: pointer;
    text-align: left;
`
const LocationTitle = styled.p`
    font-weight: bold;
`
const TestModeText = styled.p`
    text-align: center;
    font-weight: bold;
    color: red;
    margin: 0;
`
interface MasterPageProps extends React.PropsWithChildren<{}> {
    forceRedisplay?: () => void;
}
const MasterPage: React.FC<MasterPageProps> = (props) => {
    const [forceRerender, setForceRerender] = React.useState<boolean>(false);
    const [userContextMenuInfo, setUserContextMenuInfo] = React.useState<ContextMenuInfo | null>(null);
    const [loadingHomePage, setLoadingHomePage] = React.useState(false);
    const [loadingOrder, setLoadingOrder] = React.useState(false);

    const navigate = useNavigate();

    const { getToken, clearToken } = useTokens();
    const { fetch } = useFetchApi();
    const { getContext, setContext } = useGlobalContext();

    const cart = useFGShoppingCart();
    const order = useFGOrder();
    const cust = useFGCustomer();
    const addressBook = useAddressBook();
    const { getSessionStore, setSessionStore, deleteSessionStore } = useSessionStore();
    const { setBookmark } = useBookmarks();

    const token = getToken();

    React.useEffect(() => {
        if (!loadingHomePage && !getSessionStore("carousel")) {
            setLoadingHomePage(true);
            fetch(api.getHomePage, null, setHomePageData, setHomePageError);
        }
        //      fetch(api.isTestMode, null, result => setContext("testMode", result), setHomePageError);
        if (token && !order.getBillEmail()) {
            setLoadingOrder(true);
            fetch(api.getCustOrderInfo, getToken()!.token, gotCustInfo,
                () => {
                    doLogout(clearToken, deleteSessionStore);
                    window.location.href = "/";
                });
        }
        cart.testCookies();
         }, []);

    const setHomePageData = (data: HomePageRecord) => {
        //          console.log("home page data:", data)
        const carousel: SliderRecord[] = [];
        data.carousel.forEach((image, index) => {
            carousel.push({ src: formatImageUrl(image.filename!, { graphicsSubfolder: "carousel" }), userData: image.url });
        });
        setLoadingHomePage(false);
        setSessionStore("carousel", carousel);
        // for (let i = 0; i < data.menuItems.length; i++) {
        //     if (i > 1) {
        //         data.menuItems[i].popupItems = [new MenuItemData("Coming soon...", "/")];
        //     }
        // }
        data.menuItems.push(moreMenuItems);
        setSessionStore("menuItems", data.menuItems);
        setSessionStore("featured", data.featured);
        setSessionStore("popular", data.popular);
        setSessionStore("newItems", data.newItems);
    }
    const setHomePageError = (error: string) => {
        setContext("fatalError", true);
    }
    const gotCustInfo = (result: FGCustInfoRecord) => {
        setLoadingOrder(false);
        cust.finishLogin(result);
        order.setCustOrderInfo(order.getOrder(), result);
    }

    // console.log("testing login");
    // if (app.loginRequired && /* window.location.hostname !== "localhost" && */ !getContext(authKey)) {
    //     return (
    //         <Navigate to="/beta-login" />
    //     )
    // }

    // const handleRefresh = () => {
    // }
    // window.addEventListener('beforeunload', handleRefresh);

    const handleLogin = () => {
        setBookmark('');
        navigate("/login");
    }
    const handleRegister = () => {
        setBookmark('');
        navigate("/register");
    }

    const handleLogout = () => {
        doLogout(clearToken, deleteSessionStore);
        setUserContextMenuInfo(null);
        navigate("/");
    }
    const handleMyAccount = () => {
        setUserContextMenuInfo(null);
        if (window.location.href.endsWith("/account")) {
            window.location.reload();
        } else {
            navigate("/account");
        }
    }
    const showUserContextMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
        const target = e.target as HTMLButtonElement;
        const rect = target.getBoundingClientRect();
        const info = new ContextMenuInfo({ x: rect.x, y: rect.y + rect.height }, [
            new ContextMenuItem("Log out", handleLogout, null),
            new ContextMenuItem("My account", handleMyAccount, null)
        ]);
        setUserContextMenuInfo(info);
    }

    const apiState = getContext(apiStateKey);
    const apiStateElapsed = apiState ? (Date.now() - apiState) : 0;
    if (apiStateElapsed) {
        // check back in 1 second to see if we are still busy; if so we can show spinner
        setTimeout(() => {
            //     console.log("setForceRerender: apiStateElapsed=" + apiStateElapsed + ", apiState=" + apiState)
            setForceRerender(state => !state);
        }, 1000);
    }
    //  console.log("apiStateElapsed=" + apiStateElapsed)
    const isSmallWindow = window.matchMedia("(max-width: " + iPadMinWidth + "px)").matches;
    const isUnderIpadMax = window.matchMedia("(max-width: " + iPadMaxWidth + "px)").matches;

    const menuItems: MenuItemData[] = getSessionStore("menuItems");

    const buttonStyle = isSmallWindow ? { fontSize: "12px", height: "22px" } : { fontSize: "14px", height: "28px" };
    const fname = addressBook.getDefaultAddress(AddressType.bill) ? addressBook.getDefaultAddress(AddressType.bill)!.fname : '';
    const footerMenu = menuItems ? menuItems.slice(0, -(menuItems.length - 2)) : null;
    // const footerMenu = menuItems ? menuItems.slice(0, -1) : null;
    return (
        <StyledMasterContainer $defaultFonts={app.themes.sansFonts!} $h1FontFamily={app.themes.serifFonts!} $foreColor={app.themes.color!}>
            {(loadingHomePage || loadingOrder) && <Spinner />}
            <StyledPageHeader $backColor={app.themes.backColor10!}>
                <StyledBanner $foreColor={app.themes.backColor10!} $backColor={app.themes.color!} $bannerFont={app.themes.sansFonts!}>
                    <p dangerouslySetInnerHTML={{ __html: app.banner }} />
                </StyledBanner>
                {isUnderIpadMax &&
                    <ButtonsRow height={22} marginRight={50} align={IconButtonAlignEnum.right}>
                        <Search center={true} />
                    </ButtonsRow>}
                {getContext("testMode") && <TestModeText>TEST MODE</TestModeText>}
                <StyledAboveLogo>
                    {!isUnderIpadMax && <Search center={false} />}
                    {!isSmallWindow &&
                        <AboveLogoGroup>
                            <AboveLogoText onClick={() => navigate("/gift-certificates")}>GIFT CERTIFICATES</AboveLogoText>
                        </AboveLogoGroup>
                    }
                    <AboveLogoGroup>
                        {token ? (
                            <IconButton style={buttonStyle} caption={"Welcome " + fname} icon="fas fa-user" onClick={showUserContextMenu} />
                        ) : (
                            <React.Fragment>
                                <AboveLogoText onClick={handleLogin}>SIGN IN</AboveLogoText>
                                <span>&nbsp;OR&nbsp;</span>
                                <AboveLogoText onClick={handleRegister}>REGISTER</AboveLogoText>
                            </React.Fragment>
                        )}
                    </AboveLogoGroup>
                    {!window.location.href.endsWith("/checkout") &&
                        <AboveLogoGroup>
                            <CartIcon isSmallWindow={isSmallWindow} />
                        </AboveLogoGroup>
                    }
                    {isSmallWindow && menuItems &&
                        <SlidingNavBar menuItems={menuItems} vouchersLink="/gift-certificates" socialMedia={SocialMedia} />
                    }
                </StyledAboveLogo>
                <StyledLogoContainer>
                    <StyledLogo src={formatImageUrl(app.logoUrl, { graphicsSubfolder: "other" })} onClick={() => navigate("/")} />
                </StyledLogoContainer>
            </StyledPageHeader>
            {!isSmallWindow && menuItems && !getSessionStore("hideNavBar") &&
                <NavBar menuItems={menuItems} />}
            <StyledContentContainer>
                {props.children}
            </StyledContentContainer>
            {userContextMenuInfo &&
                <ContextMenu info={userContextMenuInfo} closePopup={() => setUserContextMenuInfo(null)} />
            }
            {(apiStateElapsed > 1000) && <Spinner />}
            <FooterPlusSocialMedia $foreColor={app.themes.color!} $backColor={app.themes.backColor25!} $serifFont={app.themes.serifFonts!}>
                <StyledFooterContainer>
                    <FooterListContainer>
                        <VerticalMenu title="Navigate" menu={moreMenuItems.popupItems!} />
                        <h2>Connect With Us</h2>
                        <SocialMedia />
                    </FooterListContainer>
                    {footerMenu &&
                        <VerticalMenu title="Categories" menu={footerMenu} />
                    }
                    <FooterListContainer>
                        <h2>Info</h2>
                        <LocationTitle>Fern's Garden</LocationTitle>
                        <Link to="/visit-us">
                            <AddressBox>
                                <p>1831 Solano Ave, Ste. A</p>
                                <p>Berkeley, CA 94707</p>
                                <p>510-529-4848</p>
                                <p>info@fernsgarden.com</p>
                            </AddressBox>
                        </Link>
                    </FooterListContainer>
                </StyledFooterContainer>
            </FooterPlusSocialMedia>
        </StyledMasterContainer>
    );
}
// following is in footer for "Navigate" and "Categories"
interface VerticalMenuProps {
    title: string;
    menu: MenuItemData[];
}
const VerticalMenu: React.FC<VerticalMenuProps> = (props) => {
    return (
        <FooterListContainer>
            <h2>{props.title}</h2>
            {props.menu.map(item => {
                let href = item.href;
                if (!href && item.popupItems) {
                    href = item.popupItems[0].href;
                }
                return (
                    <p key={item.caption}><Link to={href ? href : ''}>{item.caption}</Link></p>
                )
            })}
        </FooterListContainer>
    )
}

const SocialMediaGroup = styled.div<{ $justify: string; $backColor: string; $height?: number }>`
    display: flex;
    justify-content: ${props => props.$justify};
    align-items: center;
    background-color: ${props => props.$backColor};
    height: ${props => props.$height}px;
`
const SocialMediaItem = styled.div`
    margin-right: 12px;
    font-size: 24px;
`
interface SocialMediaProps {
    justify?: string;        // flex-start or center; default to flex-start
    backColor?: string;     // default to transparent
    height?: number;
}
export const SocialMedia: React.FC<SocialMediaProps> = (props) => {
    return (
        <SocialMediaGroup $justify={props.justify ? props.justify : "flex-start"} $backColor={props.backColor ? props.backColor : "transparent"} $height={props.height}>
            <SocialMediaItem>
                <a href={app.facebookUrl} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-square" /></a>
            </SocialMediaItem>
            <SocialMediaItem>
                <a href={app.instagramUrl} target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram-square" /></a>
            </SocialMediaItem>
            <SocialMediaItem>
                <a href={app.pinterestUrl} target="_blank" rel="noopener noreferrer"><i className="fab fa-pinterest-square" /></a>
            </SocialMediaItem>
            <SocialMediaItem>
                <a href={app.twitterUrl} target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter-square" /></a>
            </SocialMediaItem>
        </SocialMediaGroup>
    )
}

export default MasterPage;
