import React from 'react';

import MasterPage from '../components/MasterPage';
import Contact, { ContactSectionText } from '../libs/Contact';

import api from '../api-url';

const ContactUs: React.FC = () => {
    const jmcContactText: ContactSectionText[] = [
       {
            header: "Call, fax or email", paragraphs: [
                "Phone (510) 529-4848 (10:30 a.m. to 5:30 p.m. Pacific time, or leave a message)",
                "Fax (510) 529-4833",
                'Email <a href="mailto:info@fernsgarden.com">info@fernsgarden.com</a>'
            ]
        },
        {
            header: "Write", paragraphs: [
                "Fern's Garden",
                "1831 Solano Ave, Ste A",
                "Berkeley, CA 94707"
            ]
        }
    ];
    return (
        <MasterPage>
            <Contact dividerImageFilename={"other/fg_divider.png"} text={jmcContactText} apiUrl={api.contact} />
        </MasterPage>
    )
}
export default ContactUs;

/*
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const [submission, setSubmission] = React.useState<ApiContactRecord>();

    const order = useFGOrder();
    const { post } = usePostApi();

    React.useEffect(() => {
        if (submission) {
            //        post("/api/isAlive", submission, () => alert("Your message has been sent. Thank you for contacting us!"), () => setErrorMsg(genericApiError));
            post(api.contact, submission, () => alert("Your message has been sent. Thank you for contacting us!"), () => setErrorMsg(genericApiError));
            setSubmission(undefined);
            window.location.href = "/";
        }
    }, [submission]);

    const initialValues = {} as ApiContactRecord;
    const billAddress = order.getAddress(AddressType.bill);
    if (billAddress && (billAddress.fname || billAddress.email)) {
        initialValues.name = (billAddress.fname ? billAddress.fname : '') + ' ' + (billAddress.lname ? billAddress.lname : '');
        initialValues.email = billAddress.email;
    }

    const fields: FormFieldRecord[] = [
        { name: "name", label: "Your name", width: 48, validator: { required: true } },
        { name: "email", label: "Your email", width: 48, validator: { required: true } },
        { name: "phone", label: "Your phone", width: 48 },
        { name: "order_id", label: "Order # (if this is about an order)", width: 48 },
        { name: "comment", label: "Message", type: FormFieldType.multiLine, inputHeight: 150, validator: { required: true } }
    ];

    const handleSubmit = (values: Record<string, any> | null) => {
        setErrorMsg(undefined);
        if (values) {
            setSubmission(values as ApiContactRecord);
        }
    }
*/