import { useSessionStore } from '../SamState';
import useFGShoppingCart from './useFGShoppingCart';
import useFGCustomer from '../../components/FGCustomer';
import useAddressBook from '../useAddressBook';

import { calcOrderTotals } from '../api-shared/shopping-cart-calc';

import { FGOrderRecord, TotalsRecord, ExtrasRecord, RewardsRecord, VoucherRecord, CouponRecord, FGOrddtlRecord, FGCustInfoRecord, ShipMethodEnum } from '../../interfaces/fg-api-interfaces';
import { AddressRecord, AddressType } from '../../interfaces/lib-api-interfaces';

/* order (api_fg5.dbo.fg5_order)
CREATE TABLE [dbo].[fg5_order](
    [order_id] [int] IDENTITY(1900,1) NOT NULL,
    [status] [char](1) NOT NULL,
    [cust_id] [int] NULL,
    //-------- FOLLOWING STORED IN MEMORY AS bill_address
    [b_email] [nvarchar](50) NULL,
    [b_fname] [nvarchar](50) NULL,
    [b_lname] [nvarchar](50) NULL,
    [b_company] [nvarchar](50) NULL,
    [b_address1] [nvarchar](50) NULL,
    [b_address2] [nvarchar](50) NULL,
    [b_city] [nvarchar](50) NULL,
    [b_state] [char](2) NULL,
    [b_zip] [nvarchar](50) NULL,
    [b_country] [nvarchar](50) NULL,
    [b_phone] [nvarchar](50) NULL,
    //-------- FOLLOWING STORED IN MEMORY AS ship_address
    [s_email] [nvarchar](50) NULL,
    [s_fname] [nvarchar](50) NULL,
    [s_lname] [nvarchar](50) NULL,
    [s_company] [nvarchar](50) NULL,
    [s_address1] [nvarchar](50) NULL,
    [s_address2] [nvarchar](50) NULL,
    [s_city] [nvarchar](50) NULL,
    [s_state] [char](2) NULL,
    [s_zip] [nvarchar](50) NULL,
    [s_country] [nvarchar](50) NULL,
    [s_phone] [nvarchar](50) NULL,
    //---------------------------------------------------------
    //-------- FOLLOWING STORED IN MEMORY AS extras
    [gift_message] [nvarchar](500) NULL,
    [gift_wrap] [bit] NOT NULL,
    [comments] [nvarchar](500) NULL,
    [coupon] [nvarchar](12) NULL,
    [voucher_code] [char](10) NULL,       // 10 digits: 5 digits + checksum + random 4 digit PIN
    //------------------------------------------------
    //-------- FOLLOWING STORED IN MEMORY AS totals
    [inv_net] [money] NULL,
    [nontaxable] [money] NULL,      // gift certificates purchased
    [discount_pct] [int] NULL,
    [discount] [money] NULL,
    [sales_tax] [money] NULL,
    [freight] [money] NULL,
    [inv_tot] [money] NULL,
    //-------- FOLLOWING USED BY BACKEND ONLY
    [voucher_applied] [money] NULL,
    [cc_tot] [money] NULL,
    [paypal_tot] [money] NULL,
    [card_type] [char](1) NULL,
    [last4] [char](4) NULL,
    [order_date] [datetime] NULL,
    [pack_date] [datetime] NULL,
    [ship_date] [datetime] NULL,
    [shiplog_id] [int] NULL,
    [carrier] [char](1) NULL,
    [ship_via] [nvarchar](15) NULL,
    [track_no] [nvarchar](50) NULL,
*/

/* order in memory (session store)
order 
{
    cust_id: int,
    cust_id: int,
    bill_address: Address (see addressSupport),
    ship_address: Address,
    extras:
        { 
            gift_message: string, 
            gift_wrap: bool,
            comments: string,
            coupon: 
            {
                coupon: string,
                discount: int,
                free_item:
                {
                    sku_long: number,
                    caption: string,
                    image: string
                }
            },
            voucher_applied:
            {
                voucher_code: string, (10 digits)
                balance: money
            }
            rewards:
            {
                balance: money
            }
        }
    totals:
        {
            inv_net: money, 
            nontaxable: money,  (total of gift certificates purchased)
            discount: money, 
            sales_tax: money, 
            freight: money, 
            fixed_freight: money,
            voucher_applied: money,
            reward_applied: money,
            inv_tot: money 
        }
}
*/

const useFGOrder = () => {
    const { getSessionStore, setSessionStore } = useSessionStore();
    const cart = useFGShoppingCart();
    const cust = useFGCustomer();
    const addressBook = useAddressBook();

    const createOrder = () => {
        const totals: TotalsRecord = {
            inv_net: 0,
            nontaxable: 0,
            discount: 0,
            sales_tax: 0,
            freight: 0,
            fixed_freight: 0,
            voucher_applied: 0,
            reward_applied: 0,
            inv_tot: 0,
        };
        const order: FGOrderRecord = {
            order_id: 0, cust_id: 0, voucher_tot: 0, bill_email: '', bill_address: {} as AddressRecord, ship_address: {} as AddressRecord, extras: {} as ExtrasRecord, totals
        };
        setCustOrderInfo(order, cust.getCustInfo());
        setOrder(order);
        return order;
    }
    const clear = () => {
        cust.clearSavedOrder();
        createOrder();
        cart.clear();
    }
    const getOrder = (): FGOrderRecord => {
        let order = getSessionStore("order");
        if (!order) {
            order = createOrder();
        }
        return order;
    }
    const setOrder = (order: FGOrderRecord) => {
        setSessionStore("order", order);
    }
    const setOrderId = (orderId: number) => {
        const order = getOrder();
        order.order_id = orderId;
        setOrder(order);
    }
    const setCustId = (custId: number) => {
        const order = getOrder();
        order.cust_id = custId;
        setOrder(order);
    }
    const setVoucherTot = (amt: number) => {
        const order = getOrder();
        order.voucher_tot = amt;
        if (amt > 0) {
            cart.clear();
        }
        recalcTotalsAndSave(order);
    }
    const getBillEmail = (): string => {
        const order = getOrder();
        return order.bill_email;
    }
    const setBillEmail = (email: string) => {
        const order = getOrder();
        order.bill_email = email;
        setOrder(order);
    }
    // call this from checkout -- do everything except the mergeCarts as that could be confusing
    // does nothing if cust not logged in
    const setCustOrderInfo = (order: FGOrderRecord, info: FGCustInfoRecord) => {
        if (info) {
            if (info.saved_order) {
                setOrder(info.saved_order);
                cart.setItems(info.shopping_cart);
            } else {
                order.cust_id = info.cust_id;
                const shipAddress = addressBook.getDefaultAddress(AddressType.ship);
                const billAddress = addressBook.getDefaultAddress(AddressType.bill);
                if (shipAddress && !haveAddress(AddressType.ship, order)) {
                    setAddress(shipAddress, AddressType.ship, order);
                }
                if (billAddress && !haveAddress(AddressType.bill, order)) {
                    setAddress(billAddress, AddressType.bill, order);
                }
                if (!order.bill_email) {
                    order.bill_email = info.login_email;
                }
                if (info.rewards) {
                    setRewards(info.rewards, order);
                }
                setOrder(order);
            }
        }
    }

    const haveExtras = (): boolean => {
        const extras: ExtrasRecord = getExtras();
        for (const property in extras) {
            if (extras[property as keyof ExtrasRecord]) {
                return true;
            }
        }
        return false;
    }
    const haveAddress = (addressType: AddressType, order?: FGOrderRecord): boolean => {
        const address = getAddress(addressType, order);
        return !!(address.address1 && address.zip);
    }
    const getAddress = (addressType: AddressType, order?: FGOrderRecord): AddressRecord => {
        order = order ? order : getOrder();
        return addressType === AddressType.ship ? order.ship_address : order.bill_address;
    }
    const setAddress = (address: AddressRecord, addressType: AddressType, order?: FGOrderRecord) => {
        order = order ? order : getOrder();
        order[addressType === AddressType.ship ? "ship_address" : "bill_address"] = address;
        if (addressType === AddressType.ship) {
            recalcTotalsAndSave(order);
        } else {
            setOrder(order);
        }
    }
    const setShipMethod = (method: ShipMethodEnum) => {
        const order = getOrder();
        order.extras.ship_method = method;
        if (order.extras.freight_quotes) {
            order.totals.fixed_freight = method === ShipMethodEnum.expedited ? order.extras.freight_quotes.expedited : order.extras.freight_quotes.regular;
        }
        recalcTotalsAndSave(order);
    }
    const getExtras = (): ExtrasRecord => {
        return getOrder().extras;
    }
    const getTotals = (): TotalsRecord => {
        return getOrder().totals;
    }
    // only the fields included in the extras record are set (existing values not changed)
    const setExtras = (extras: ExtrasRecord) => {
        const order = getOrder();
        for (const property in extras) {
            order.extras[property] = extras[property];
        }
        setOrder(order);
    }
    // couponInfo is as returned from api: { coupon: string, discount: int, free_item: {sku_long, caption, image} }
    const applyCoupon = (couponInfo: CouponRecord) => {
        const order = getOrder();
        order.extras.coupon = couponInfo;
        if (couponInfo.free_item) {
            cart.addFreebie(couponInfo.free_item);
        }
        recalcTotalsAndSave(order);
    }
    const cancelCoupon = () => {
        const order = getOrder();
        order.extras.coupon = undefined;
        recalcTotalsAndSave(order);
    }
    // voucherInfo is as returned from api: { voucher: string, balance: money }
    const applyVoucher = (voucherInfo: VoucherRecord) => {
        const order = getOrder();
        order.extras.voucher_applied = voucherInfo;
        recalcTotalsAndSave(order);
    }
    const cancelVoucher = () => {
        const order = getOrder();
        order.extras.voucher_applied = undefined;
        recalcTotalsAndSave(order);
    }
    const setRewards = (rewardsInfo: RewardsRecord, order?: FGOrderRecord) => {
        order = order ? order : getOrder();
        order.extras.rewards = rewardsInfo;
        recalcTotalsAndSave(order);
    }
    // return updated totals
    const recalcTotals = () => {
        const order = getOrder();
        return recalcTotalsAndSave(order);
    }
    // return updated totals
    const recalcTotalsAndSave = (order: FGOrderRecord) => {
        const [totals, shoppingCart] = calcOrderTotals(order, cart.getItems());
        order.totals = totals;
        setOrder(order);
        cart.setItems(shoppingCart);
    }
    // return array of missing items, zero length if order is ready to submit
    const validateOrder = (): string[] => {
        const result = [];
        if (!getBillEmail()) {
            result.push("Customer email");
        }
        if (!haveAddress(AddressType.ship)) {
            result.push("Shipping address");
        }
        if (!haveAddress(AddressType.bill)) {
            result.push("Billing address");
        }
        return result;
    }

    //----------- SHOPPING CART MANAGEMENT -------------
    const addCartItem = (item: FGOrddtlRecord) => {
        cart.addItem(item);
        recalcTotals();
    }
    const removeCartItem = (skuLong: number) => {
        cart.removeItem(skuLong);
        recalcTotals();
    }
    const mergeCarts = (savedCart: FGOrddtlRecord[]) => {
        cart.merge(savedCart);
        recalcTotals();
    }
    const setCartItemQty = (skuLong: number, qty: number) => {
        cart.setQty(skuLong, qty);
        recalcTotals();
    }
    //----------- END SHOPPING CART MANAGEMENT -------------

    return {
        createOrder,
        clear,
        setCustId,
        setVoucherTot,
        setCustOrderInfo,
        setOrderId,
        getOrder,
        setOrder,
        getBillEmail,
        setBillEmail,
        haveAddress,
        haveExtras,
        getAddress,
        setAddress,
        setShipMethod,
        getExtras,
        setExtras,
        setRewards,
        getTotals,
        applyCoupon,
        cancelCoupon,
        applyVoucher,
        cancelVoucher,
        recalcTotals,
        validateOrder,

        addCartItem,
        removeCartItem,
        mergeCarts,
        setCartItemQty
    }
}
export default useFGOrder;

