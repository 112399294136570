import React from 'react';

import MasterPage from "../components/MasterPage"
import InfoPageViewer from "../libs/InfoPageViewer";
import { genericApiError, useFetchApi } from '../libs/useDataApiV2';

import api from '../api-url';
import { InfoPageApiRecord } from '../interfaces/lib-api-interfaces';
import Spinner from '../libs/Spinner';

interface InfoPageProps {
    name: string;
}
const InfoPage: React.FC<InfoPageProps> = (props) => {
    const [infoPage, setInfoPage] = React.useState<InfoPageApiRecord>();
    const [loading, setLoading] = React.useState(false);

    const { fetch } = useFetchApi();

    React.useEffect(() => {
        if (props.name !== infoPage?.name) {
            setLoading(true);
            fetch(api.getInfoPage + props.name, null,
                result => {
                    setLoading(false);
                    setInfoPage(result)
                }, () => alert(genericApiError));
        }
    }, [props.name]);

    return (
        <MasterPage>
            {loading && <Spinner />}
            {infoPage &&
                <InfoPageViewer infoRecord={infoPage} />
            }
        </MasterPage>
    )
}
export default InfoPage;