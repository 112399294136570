import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { useFetchApi, genericApiError, usePostApi } from '../../libs/useDataApiV2';
import { useGlobalContext, useTokens } from '../../libs/SamState';
import addr from '../../libs/api-shared/address-support';
import { StyledErrorText } from '../../libs/libSupport';
import IconButton from '../../libs/IconButtonV2';
import useFGShoppingCart from '../../libs/shopping-cart/useFGShoppingCart';
import { AddressRecord } from '../../interfaces/lib-api-interfaces';
import { showCartPopup } from '../../libs/shopping-cart/CartPopup';
import { calcRewardApplied } from '../../libs/api-shared/shopping-cart-calc';
import MasterPage from '../MasterPage';

import { FGCustInfoRecord, FGOrderHistoryRecord, OrderHistoryStatusEnum, RewardsRecord } from '../../interfaces/fg-api-interfaces';
import { ImageSizeEnum } from '../../interfaces/lib-api-interfaces';

import api from '../../api-url';
import { formatImageUrl } from '../../libs/ImageFormatter';

const MasterContainer = styled.div`
    display: inline-flex;
    max-width: 360px;
    flex-direction: column;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    padding: 8px;
    p {
        margin-top: 0;
        margin-bottom: 4px;
    }
    a:link {
        text-decoration: underline;
    }
`
const OrderBox = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid;
    align-items: flex-start;
    margin-bottom: 16px;
    padding: 8px;
`
const LabelText = styled.span<{ $marginLeft?: number }>`
    font-weight: bold;
    margin-left: ${props => props.$marginLeft}px;
    margin-right: 4px;
`
const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const DetailRow = styled.div`
    display: flex;
    align-items: center;
    border-top: 1px solid #ccc;
    padding: 8px;
`
const ImageContainer = styled.div<{ $height: number }>`
    display flex;
    justify-content: center;
    align-items: center;
    height: ${props => props.$height}px;
    width: ${props => props.$height}px;
    margin-right: 4px;
`
const Image = styled.img<{ $height: number }>`
    max-height: ${props => props.$height}px;
    max-width: ${props => props.$height}px;
    height: auto;
    width: auto;
    cursor: pointer;
`
const DetailText = styled.div<{ $width: number }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${props => props.$width}px;
    margin-top: 4px;
    p {
        font-size: 13px;
        line-height: 16px;
        text-align: center;
    }
`
const DiscontinuedText = styled.span`
    font-size: 12px;
    line-height: 14px;
`
const ButtonBox = styled.div<{ $width: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: ${props => props.$width}px;
`
const MyOrders: React.FC = (props) => {
    const { fetch } = useFetchApi();
    const { post } = usePostApi();
    const { getToken } = useTokens();
    const { setContext } = useGlobalContext();
    const cart = useFGShoppingCart();
    const [orderHistory, setOrderHistory] = React.useState<FGOrderHistoryRecord[]>();
    const [rewards, setRewards] = React.useState<RewardsRecord>();
    const [billAddress, setBillAddress] = React.useState<AddressRecord>();
    const [statusMsg, setStatusMsg] = React.useState<string>();
    const [oosEmailSku, setOosEmailSku] = React.useState<number>();

    const orderFactsPadding = 12;
    const imageHeight = 50;
    const detailTextWidth = 200;
    const buttonBoxWidth = 150;

    React.useEffect(() => {
     //   fetch(api.getCustOrderHistoryTest, getToken()!.token, (result: FGCustInfoRecord) => {
            fetch(api.getCustOrderHistory, getToken()!.token, (result: FGCustInfoRecord) => {
            setOrderHistory(result.order_history);
            setRewards(result.rewards);
            setBillAddress(result.bill_address);
        }, () => setStatusMsg(genericApiError));
    }, []);
    React.useEffect(() => {
        if (oosEmailSku) {
            setStatusMsg(undefined);
            post(api.fgRequestOosEmail, { name: billAddress!.fname + ' ' + billAddress!.lname, email: billAddress!.email, sku_long: oosEmailSku },
                () => setStatusMsg("You will receive an email when this item is back in stock"), () => setStatusMsg(genericApiError));
            setOosEmailSku(undefined);
        }
    }, [oosEmailSku]);

    let rewardsText = '';
    if (rewards && rewards.rewards_id) {
        if (rewards.balance >= 100) {
            const amt =
                rewardsText = "You have earned a $" + calcRewardApplied(rewards.balance) + " reward on your next purchase";
        } else {
            rewardsText = "You have $" + (100 - rewards.balance) + " to go until your next reward";
        }
    }

    const detailButtonClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
        const target = e.target as HTMLButtonElement;
        const indexes = target.id.split(',');
        const item = orderHistory![parseInt(indexes[0])].details[parseInt(indexes[1])];
        if (item.status === OrderHistoryStatusEnum.inStock) {
            // locate this item's detail record
            cart.addItem(item);
            showCartPopup(setContext, true);
        } else {
            // cust wants in stock email
            setOosEmailSku(item.sku_long);      // useEffect will send the email
        }
    }
    const imageClicked = (e: React.MouseEvent<HTMLImageElement>) => {
        const target = e.target as HTMLImageElement;
        const indexes = target.id.split(',');
        const item = orderHistory![parseInt(indexes[0])].details[parseInt(indexes[1])];
        // open in a new window.
        window.open('/' + item.sku_long, '_blank');
    }

    return (
        <MasterPage>
            <h1>Order History</h1>
            {statusMsg && <StyledErrorText>{statusMsg}</StyledErrorText>}
            {rewards && rewards.rewards_id && (
                rewards.balance >= 100 ? (
                    <p>You have earned a ${calcRewardApplied(rewards.balance)} <Link style={{textDecoration: "underline"}} to="/rewards" target="_blank" rel="noopener noreferrer">reward</Link>&nbsp;on your next purchase</p>
                ) : (
                    <p>You have ${100 - rewards.balance} to go until your next <Link style={{textDecoration: "underline"}} to="/rewards"target="_blank" rel="noopener noreferrer">reward</Link></p>
                )
            )
            }
            {orderHistory &&
                <MasterContainer>
                    {orderHistory.map((record, orderIndex) => {
                        return (
                            <OrderBox key={record.order_id}>
                                <div>
                                    <LabelText>Date:</LabelText>
                                    <span>{dayjs(record.order_date).format("MM/DD/YYYY")}</span>
                                    <LabelText $marginLeft={orderFactsPadding}>Amount:</LabelText>
                                    <span>$</span><span>{record.inv_net.toFixed(2)}</span>
                                </div>
                                {record.track_no &&
                                    <div>
                                        <LabelText>Tracking:</LabelText>
                                        <a href={"https://google.com/search?q=" + record.track_no} target="_blank" rel="noopener noreferrer">{record.track_no}</a>
                                    </div>
                                }
                                <div>
                                    <LabelText>Ship to:<br /></LabelText>
                                    <AddressBox address={record.ship_address} />
                                </div>
                                <DetailsContainer>
                                    {record.details.map((detail, detailIndex) => {
                                        return (
                                            <DetailRow key={detail.sku_long}>
                                                <ImageContainer $$height={imageHeight}>
                                                    {detail.image!.filename &&
                                                        <Image id={orderIndex + ',' + detailIndex} $height={imageHeight - 2} 
                                                            src={formatImageUrl(detail.image!.filename, { sizeDesignator: ImageSizeEnum.full })} onClick={imageClicked} />
                                                    }
                                                </ImageContainer>
                                                <DetailText $width={detailTextWidth}>
                                                    <p>{detail.caption}</p>
                                                    <p>{detail.qty + ' @ ' + detail.price!.toFixed(2)}</p>
                                                </DetailText>
                                                <ButtonBox $width={buttonBoxWidth}>
                                                    {detail.status === OrderHistoryStatusEnum.discontinued ? (
                                                        <DiscontinuedText></DiscontinuedText>
                                                    ) : (
                                                        <IconButton id={orderIndex + ',' + detailIndex}
                                                            caption={detail.status === OrderHistoryStatusEnum.inStock ? "Buy it again" : "Email me when in stock"}
                                                            onClick={detailButtonClicked} />
                                                    )
                                                    }
                                                </ButtonBox>
                                            </DetailRow>
                                        )
                                    })}
                                </DetailsContainer>
                            </OrderBox>
                        )
                    })}
                </MasterContainer>
            }
        </MasterPage>
    )
}
const AddressBoxContainer = styled.div`
    p {
        margin: 0;
        margin-left: 16px;
    }
`
interface AddressBoxProps {
    address: AddressRecord;
}
const AddressBox: React.FC<AddressBoxProps> = (props) => {
    const address = addr.toArray(props.address);
    return (
        <AddressBoxContainer>
            {address.map((text, index) => {
                return (
                    <p key={index}>{text}</p>
                )
            })}
        </AddressBoxContainer>
    )
}
export default MyOrders;