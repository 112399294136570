import React from 'react';
import styled from 'styled-components';
import { Link, useNavigate, useParams } from 'react-router-dom';

import MasterPage from '../components/MasterPage';
import SamForm from '../libs/forms/SamFormV5';
import { usePostApi, genericApiError } from '../libs/useDataApiV2';
import PayAndSubmit from '../libs/shopping-cart/PayAndSubmit';
import useFGOrder from '../libs/shopping-cart/useFGOrder';
import useFGShoppingCart from '../libs/shopping-cart/useFGShoppingCart';
import { StyledErrorText } from '../libs/libSupport';
import ThankYou from '../components/ThankYou';
import { isAllNumeric } from '../libs/libSupport';

import { FormFieldRecord, FormFieldType, AddressType, AddressRecord } from '../interfaces/lib-api-interfaces';

import api from '../api-url';
import { useGlobalContext } from '../libs/SamState';
import FormMgr from '../libs/forms/FormMgr';
import useFGCustomer from '../components/FGCustomer';

// return null if valid, message if not
// code is in standard format (nnnnnn-nnnn) or BC format (4 sets of 3 chars with dashes)
export const isValidVoucherCode = (code: string): string | null => {
    // lifted from api to check validity before submitting
    const vcode = code.replaceAll('-', '');
    if (isAllNumeric(vcode)) {
        const voucherParts = code.split('-');
        const checkDigit = voucherParts[0][voucherParts[0].length - 1];
        const voucherBase = voucherParts[0].slice(0, -1);
        //    console.log("voucher_code=" + voucher_code + ", voucherBase=" + voucherBase + ", checkDigit=" + checkDigit + ", pin=" + pin + ", calculated checkdigit=" + calculateChecksum(voucherBase));
        if (isNaN(parseInt(voucherBase)) || isNaN(parseInt(voucherParts[1])) || checkDigit !== calculateChecksum(voucherBase)) {
            return "Gift certificate number is not valid";
        }
    } else if (vcode.length !== 12) {
        return "Please enter exactly 12 characters";
    }
    return null;
}
const calculateChecksum = (value: string): string => {
    let sum = 0;
    let evenOddModulo = 0;
    for (let i = 0; i < value.length; i++) {
        if (i % 2 === evenOddModulo)
            sum += (value[i].charCodeAt(0) - '0'.charCodeAt(0)) * 3;
        else
            sum += (value[i].charCodeAt(0) - '0'.charCodeAt(0));
    }
    sum = 10 - sum % 10;
    if (sum === 10)
        sum = 0;
    return sum + '';
}

const MasterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
    h4 {
        font-size: 22px;
        margin-top: 12px;
        margin-bottom: 4px;
        margin-left: 12px;
    }
`
const RewardsMsg = styled.p`
    margin: 0;
    margin-bottom: 12px;
    width: 360px;
    text-align: center;
    font-size: 12px;
`
const NavBar = styled.div`
    display: flex;
    justify-content: center;
    height: 60px;
    align-items: center;
`
const NavLink = styled.span<{ $isClickable: boolean }>`
    cursor: ${props => props.$isClickable ? "pointer" : null};
    margin-right: 20px;
    text-decoration: ${props => props.$isClickable ? "underline" : null};
    :hover
    {
        font-style: ${props => props.$isClickable ? "italic" : "regular"};
    }
`
const RedeemStepList = styled.ol`
    max-width: 700px;
    text-align: left;
    h2 {
        margin-bottom: 16px;
    }
    h3 {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
    }
    li {
        margin-bottom: 8px;
        line-height: 22px;
    }
`
const BalanceForm = styled.div`
    margin: 16px auto 0 auto;
    width: 70%;

`
const CartNotEmptyText = styled.p`
    text-align: center;
    line-height: 24px;
    padding: 12px;
    border: 2px solid red;
`
enum GCPageEnum { purchase = 'P', redeem = 'R', balance = 'B' }
const GiftCertificates: React.FC = () => {
    const params = useParams();
    const param = params.param as string;
    const navigate = useNavigate();

    const [formShown, setFormShown] = React.useState<GCPageEnum>(GCPageEnum.purchase);
    const [balanceMsg, setBalanceMsg] = React.useState<string>();
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const [cartNotEmptyMsg, setCartNotEmptyMsg] = React.useState<string>();
    const [showThankYou, setShowThankYou] = React.useState(false);

    const { post } = usePostApi();
    const order = useFGOrder();
    const cart = useFGShoppingCart();
    const { setContext } = useGlobalContext();
    const forms = new FormMgr(setContext);
    const cust = useFGCustomer();

    const formId = "purchase";
    const custInfo = cust.getCustInfo();

    let custName = '';
    let custEmail = '';
    if (custInfo && custInfo.bill_addresses[0]) {
        custName = custInfo.bill_addresses[0].fname + ' ' + (custInfo.bill_addresses[0].lname ?? '');
        custEmail = custInfo.login_email;
    }
    const purchaseFields: FormFieldRecord[] = [
        { name: "b_name", label: "Your name", width: 47, validator: { required: true }, initialValue: custName },
        { name: "b_email", label: "Your email", width: 47, validator: { required: true }, initialValue: custEmail },
        { name: "s_name", label: "Recipient's name", width: 47, validator: { required: true } },
        { name: "s_email", label: "Recipient's email", width: 47, validator: { required: true } },
        { name: "amt", type: FormFieldType.decimal, width: 20, label: "Amount", validator: { required: true } },
        { name: "sendDate", type: FormFieldType.date, label: "Send email on", width: 75 },
        { name: "message", type: FormFieldType.multiLine, size: { widthPct: 98, height: 100 }, placeholder: "Enter an optional message for the recipient here" }
    ];
    const balanceFields: FormFieldRecord[] = [
        { name: "voucher", label: "Gift certificate:", type: FormFieldType.button, validator: { maxLength: 15 }, button: { caption: "Check balance", icon: "fas fa-check" } },
    ];

    React.useEffect(() => {
        if (param === "redeem" && formShown !== GCPageEnum.redeem) {
            setFormShown(GCPageEnum.redeem);
        }
    });
    React.useEffect(() => {
        if (cart.itemCount() > 0) {
            setCartNotEmptyMsg("NOTE: Your cart is not empty. You cannot purchase other items along with your gift certificate, so the items in your cart will be removed. "
                + "If you do not want to lose these items, please save your cart (log-in required) or purchase them now and return here later to buy the gift certificate.")
        }
    }, []);

    // following called from credit editor when user says "Buy Gift Certificate" -- editor will post the order upon return
    const submitOrder = (): boolean => {
        if (!forms.validateForm(formId)) {
            return false;       // return false to cancel submission
        }
        const values = forms.getFormValues(formId);
        // got the fields we need: go ahead and place the order in memory so it will be posted upon return from this function
        order.clear();
        order.setVoucherTot(values.amt);
        order.setAddress({ fname: values.b_name } as AddressRecord, AddressType.bill);
        order.setBillEmail(values.b_email);
        order.setAddress({ fname: values.s_name, email: values.s_email } as AddressRecord, AddressType.ship);
        order.setExtras({ gift_message: values.message, send_voucher_date: values.sendDate ? new Date(values.sendDate) : new Date() });
        return true;
    }

    const orderCompleted = () => {
        order.clear();
        setShowThankYou(true);
    }

    const gotBalance = (result: { voucher_code: string, balance: number } | string) => {
        if (typeof result === "string") {
            setBalanceMsg(result);
        } else {
            setBalanceMsg("This gift certificate has a balance of $" + result.balance.toFixed(2));
        }
    }
    const inlineClicked = (fieldName: string, value: string) => {
        const msg = isValidVoucherCode(value);
        if (msg) {
            setErrorMsg(msg);
        } else {
            post(api.getVoucherValue, { voucher_code: value }, gotBalance, () => setErrorMsg(genericApiError))
        }
    }
    const navClicked = (page: GCPageEnum) => {
        navigate("/gift-certificates");
        setFormShown(page);
        setBalanceMsg(undefined);
    }
    return (
        <MasterPage>
            <MasterContainer>
                <h1>Gift Certificates</h1>
                <NavBar>
                    <NavLink $isClickable={formShown !== GCPageEnum.purchase} onClick={() => navClicked(GCPageEnum.purchase)}>Purchase Gift Certificate</NavLink>
                    <NavLink $isClickable={formShown !== GCPageEnum.redeem} onClick={() => navClicked(GCPageEnum.redeem)}>Redeem Gift Certificate</NavLink>
                    <NavLink $isClickable={formShown !== GCPageEnum.balance} onClick={() => navClicked(GCPageEnum.balance)}>Check Gift Certificate Balance</NavLink>
                </NavBar>
                {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
                {formShown === GCPageEnum.purchase &&
                    <React.Fragment>
                        {cartNotEmptyMsg && <CartNotEmptyText>{cartNotEmptyMsg}</CartNotEmptyText>}
                        <SamForm id={formId} fields={purchaseFields} />
                        <h4>Order total: ${parseFloat(forms.getValue(formId, "amt")).toFixed(2)}</h4>
                        {order.getExtras().rewards && <RewardsMsg>Note to Rewards members: Fern's Garden Rewards cannot be used to purchase a gift certificate,
                            but the amount of your purchase will be added to your reward balance.</RewardsMsg>
                        }
                        <PayAndSubmit isGiftCertificate={true} validate={submitOrder} orderCompleted={orderCompleted} />
                    </React.Fragment>
                }
                {formShown === GCPageEnum.redeem &&
                    <RedeemStepList>
                        <h2>Redeem a Gift Certificate</h2>
                        <h3>You may use your gift certificate here online, or at our <Link to="/visit"><u>Berkeley store</u></Link>. To use it at the store,
                            just print your gift certificate email and give it to the sales associate when you check out.
                            To redeem your gift certificate online, follow the simple steps below.</h3>
                        <ol>
                            <li>Browse the store and add items to your cart as usual.</li>
                            <li>Hover over the cart icon to view your shopping cart, and click the "Check Out" button.</li>
                            <li>Enter your email and "Continue as guest" or log in if you have an account.</li>
                            <li>Enter your shipping and billing addresses</li>
                            <li>Under "Extras" enter the gift certicate number from the email you received and click "Apply." It should have 6 digits followed by a dash
                                and 4 digits (e.g. "123456-1234")</li>
                            <li>If the gift certificate value is not enough to cover your order amount, enter your credit card and click the "Place my order now" button.
                                If the order amount is less than the gift certificate value, you will be emailed a new gift certificate for the difference.</li>
                            <li>Thank you for supporting the artists of America and the world!</li>
                        </ol>
                    </RedeemStepList>
                }
                {formShown === GCPageEnum.balance &&
                    <BalanceForm>
                        <SamForm id="balance" fields={balanceFields} inlineButtonClicked={inlineClicked} />
                    </BalanceForm>
                }
                {balanceMsg && <p style={{ fontWeight: "bold" }}>{balanceMsg}</p>}
                {showThankYou && <ThankYou isGiftCertificate={true} />}
            </MasterContainer>
        </MasterPage>
    )
}

/*
// if standard format return 3 elements: base, pin and checkdigit
// if bc format return 4 elements, each 3 chars
const parseVoucherCode = (voucherCode: string): string[] => {
    const parts = voucherCode.split('-');
    if (parts.length === 4) {
        return parts;
    }
    parts[0] = parts[0] ? parts[0].slice(0, -1) : '';
    parts[1] = parts[1] ? parts[1] : '';
    const checkdigit = parts[0] ? parts[0][parts[0].length - 1] : '';
    parts.push(checkdigit);
    return parts;
}
*/
export default GiftCertificates;


