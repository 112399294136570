import React from 'react';
import styled from 'styled-components';

import ProductGrid from './ProductGrid';

import { FGBaseProductRecord } from '../interfaces/fg-api-interfaces';

import app from '../appData';

// includes header and images list
// h2 is main header
const StyledFeaturedContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    margin-bottom: 60px;
    width: 100%;
    max-width: 1200px;
    h2 {
        font-size: 25px;
        font-family: ${app.themes.serifFonts};
        line-height: 28px;
        text-align: center;
    }
`
interface FeaturedProductGridProps {
    header: string;
    featured: FGBaseProductRecord[];
    imageSize?: number;      // pixels; images are squared
}
const FeaturedProductGrid: React.FC<FeaturedProductGridProps> = (props) => {
    return (
        <StyledFeaturedContainer>
            <h2>{props.header}</h2>
            <ProductGrid products={props.featured} showIdAsSku={true} imageSize={props.imageSize} cellPadding={16} />
        </StyledFeaturedContainer>
    );
}
export default FeaturedProductGrid;
