import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import useFGShoppingCart from './useFGShoppingCart';
import useFGOrder from './useFGOrder';
import { useGlobalContext, useTokens } from '../SamState';
import ShoppingCartList from './ShoppingCartListV2';
import { usePostApi, genericApiError } from '../useDataApiV2';
import IconButton from '../IconButtonV2';

import { ShoppingCartListRecord } from '../../interfaces/lib-react-interfaces';
import { ShipMethodEnum, SubmitOrderResult } from '../../interfaces/fg-api-interfaces';
import { ImageSizeEnum } from '../../interfaces/lib-api-interfaces';

import app from '../../appData';
import api from '../../api-url';
import { formatImageUrl } from '../ImageFormatter';

const TotalsContainer = styled.div`
    margin-top: 8px;
`
interface CartPopupProps {
    positionAbsolute?: boolean;
    closeClicked?: () => void;      // close button shown if this is true OR positionAbsolute true
    showCheckoutButton?: boolean;
    totalChanged?: () => void;        // when this is called make sure to redisplay
    SaveCart?: React.FC;
    scrollToTop?: boolean;      // forces scroll to top (needed for mobile devices so popup doesn't show off screen)
}
const CartPopup: React.FC<CartPopupProps> = (props) => {
    const { setContext } = useGlobalContext();
    const [savingCart] = React.useState<boolean>(false);
    const [closePopup, setClosePopup] = React.useState<boolean>(false);
    const [forceRerender, setForceReRender] = React.useState(false);

    const { getToken } = useTokens();
    const cart = useFGShoppingCart();
    const order = useFGOrder();
    const navigate = useNavigate();
    const token = getToken();
    const { renderStatus, saveClicked } = useSaveOrder();

  //  console.log("CartPopup freight=" + order.getOrder().totals.freight);
    
    React.useEffect(() => {
        if (closePopup) {
            showCartPopup(setContext, false);
            setClosePopup(false);
            if (props.closeClicked) {
                props.closeClicked();
            }
        }
    });
    const handleCheckout = () => {
        navigate('/checkout');
    }
    const qtyChanged = (skuLong: string, qty: number) => {
        order.setCartItemQty(parseInt(skuLong), qty);
        setForceReRender(state => !state);
        props.totalChanged && props.totalChanged();
    }
    const handleRemove = (skuLong: string) => {
        order.removeCartItem(parseInt(skuLong));
        setForceReRender(state => !state);
        props.totalChanged && props.totalChanged();
    }
    const renderTotals = () => {
        const totals = order.getTotals();
        const isExpedited = order.getExtras() && order.getExtras().ship_method === ShipMethodEnum.expedited;
        return (
            <TotalsContainer>
                <TotalsRow label="Subtotal:" amt={totals.inv_net} showDollarSign={true} />
                <TotalsRow label="Less coupon savings*:" amt={-totals.discount} />
                <TotalsRow label="Plus tax:" amt={totals.sales_tax} />
                <TotalsRow label={"Plus " + (isExpedited ? "expedited ": '') + "shipping:"} amt={totals.freight} />
                <TotalsRow label="Less gift certificate*:" amt={-totals.voucher_applied} />
                <TotalsRow label="Less Fern's Garden Reward:" amt={-totals.reward_applied} />
                <TotalsRow label="Order total:" amt={totals.inv_tot} showDollarSign={true} fontBold={true} />
                <CouponText>*If you have a coupon or gift certificate, please enter them under "Extras" at checkout.</CouponText>
            </TotalsContainer>
        );
    }

    const items: ShoppingCartListRecord[] = [];
    cart.getItems().forEach(item => {
        items.push({ caption: item.caption!, id: item.sku_long + '', imageFilename: item.image!.filename!, qty: item.qty, price: item.price! });
    });

    return (
        <ShoppingCartList items={items} positionAbsolute={props.positionAbsolute} renderTotals={renderTotals} scrollToTop={props.scrollToTop}
            saveOrderClicked={token ? saveClicked : undefined} renderSaveOrderStatus={renderStatus}
            cartItemQtyChanged={qtyChanged} cartItemRemoved={handleRemove} checkoutClicked={props.showCheckoutButton ? handleCheckout : undefined} 
            closeClicked={props.positionAbsolute || props.closeClicked ? () => setClosePopup(true) : undefined} />
    )
}
const StyledTotalsRow = styled.div<{ $fontWeight: string }>`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    height: 32px;
    margin-right: 8px;
    font-weight: ${props => props.$fontWeight};
`
const TotalsLabel = styled.span`
    width: 310px;
    text-align: right;
    margin-right: 4px;
`
const TotalsAmt = styled.span<{ $color: string }>`
    flex: 1;
    text-align: right;
    color: ${props => props.$color};
    margin-right: 8px;
`
const CouponText = styled.p`
    margin-left: 16px;
    margin-right: 16px;
`
interface TotalsRowProps {
    fontBold?: boolean;
    label: string;
    amt: number;
    showDollarSign?: boolean;
}
const TotalsRow: React.FC<TotalsRowProps> = (props) => {
    return (
        <StyledTotalsRow $fontWeight={props.fontBold ? "bold" : "regular"}>
            <TotalsLabel>{props.label}</TotalsLabel>
            <TotalsAmt $color={props.amt < 0 ? "red" : (app.themes.color ?? '')}>{(props.showDollarSign ? "$" : '') + Math.abs(props.amt).toFixed(2)}</TotalsAmt>
        </StyledTotalsRow>
    )
}
//-----------------------------------------------------------
// following displays a one line summary with button to edit card
// used on mobile devices fixed at bottom of screen
const CartSummaryBoxContainer = styled.div<{ $backColor: string }>`
    position: fixed;
    top: ${window.innerHeight - 60}px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 40px;
    width: 90%;
    max-width: 350px;
    border: 1px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    background-color: ${props => props.$backColor};
    p {
        font-size: 20px;
    }
`
const ImageBox = styled.img`
    max-height: 40px;
    max-width: 50px;
    height: auto;
    width: auto;
`
interface CartSummaryBoxProps {
    viewCartClicked: () => void;
}
export const CartSummaryBox: React.FC<CartSummaryBoxProps> = (props) => {
    const cart = useFGShoppingCart();
    const order = useFGOrder();

    const totals = order.getTotals();
    const pieceCount = cart.pieceCount();

    return (
        <CartSummaryBoxContainer $backColor={app.themes.backColor10 ?? ''}>
            <ImageBox src={formatImageUrl(cart.getItems()[0].image!.filename!, { sizeDesignator: ImageSizeEnum.full })} />
            <p>{pieceCount + " item" + (pieceCount > 1 ? "s": '')}</p>
            <IconButton caption="Show details" icon="fas fa-shopping-cart" onClick={props.viewCartClicked} />
            <p><span>$</span>{totals.inv_tot.toFixed(2)}</p>
        </CartSummaryBoxContainer>
    )
}
//----------------------------------------------------------------
const useSaveOrder = () => {
    const { post, isPostLoading } = usePostApi();
    const { getToken } = useTokens();
    const [message, setMessage] = React.useState<string | null>(null);
    const order = useFGOrder();
    const cart = useFGShoppingCart();

    const orderSaved = (result: SubmitOrderResult) => {
        if (result.order) {
            order.setOrder(result.order!);
            cart.setItems(result.shopping_cart!);
        } else {
            order.setOrderId(result.order_id!);
        }
        setMessage("Shopping cart saved");
        setTimeout(() => setMessage(null), 2000);
    }
    const saveFailed = () => {
        setMessage(genericApiError);
    }

    const saveClicked = () => {
        post(api.saveOrder, { order: order.getOrder(), shopping_cart: cart.getItems() }, orderSaved, saveFailed, getToken()!.token);
    }

    const renderStatus = () => {
        return (
            <React.Fragment>
                {message && <p>{message}</p>}
            </React.Fragment>
        )
    }
    return {
        saveClicked,
        renderStatus
    }
}
// pass true to show popup, false to hide
// popup can be hidden but not shown if we are checking out
export const showCartPopup = (setContext: (key: string, value: any) => void, show: boolean) => {
    if (!show || !window.location.href.endsWith("/checkout")) {
        if (show) {
            setContext("cartPopup", Date.now());
        } else {
            setContext("cartPopup", null);
        }
    }
}
const isPopupShown = (getContext: (key: string) => any) => {
    return getContext("cartPopup");
}

const StyledCartIconContainer = styled.div`
    position: relative;
    cursor: pointer;
`
const StyledCartIcon = styled.div`
    font-size: 34px;
`
const StyledCartItemCounter = styled.span`
    color: white;
    position: absolute;
    top: 40%;
    left: 55%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-weight: bold;
`
interface CartIconProps {
    isSmallWindow: boolean;
}
export const CartIcon: React.FC<CartIconProps> = (props) => {
    const { getContext, setContext } = useGlobalContext();
    const [redisplay, setRedisplay] = React.useState<number>(0);
    
    const cart = useFGShoppingCart();
    const order = useFGOrder();

    const forceRedisplay = () => {
        setRedisplay(state => state + 1);
    }
    const onHover = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isPopupShown(getContext)) {
            order.recalcTotals();
            showCartPopup(setContext, true);
        }
    }
    if (cart.itemCount() === 0) {
        return null;
    }
    return (
        <StyledCartIconContainer onClick={() => showCartPopup(setContext, true)}>
            <StyledCartItemCounter>{cart.pieceCount()}</StyledCartItemCounter>
            <StyledCartIcon onMouseOver={props.isSmallWindow ? undefined : onHover} onClick={props.isSmallWindow ? onHover : undefined}>
                <i className="fas fa-shopping-cart" />
            </StyledCartIcon>
            {isPopupShown(getContext) &&
                <CartPopup positionAbsolute={true} showCheckoutButton={true} totalChanged={forceRedisplay} />
            }
        </StyledCartIconContainer>
    )
}

export default CartPopup;