import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import IconButton, { ButtonsRow } from './IconButtonV2';

import app from '../appData';
import CloseDialogBar from './CloseDialogBar';

const GrayBG = styled.div<{ $noBackground?: boolean }>`
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    ${props => !props.$noBackground && "background: rgba(0, 0, 0, 0.6)"};
    z-index: 1000;
`
const Modal = styled.div<{ $backColor?: string; $isAutosizing: boolean }>`
    background-color: ${props => props.$backColor ?? "white"};
    position: fixed;
    left: ${props => props.$isAutosizing ? "0" : "50%"};
    top: ${props => props.$isAutosizing ? "200vh" : "50%"};
    transform: ${props => props.$isAutosizing ? "none" : "translate(-50%, -50%)"}; 
    z-index: 1001;
`
const ModalContent = styled.div<{ $width: number; $minHeight?: number }>`
    overflow: auto;
    width: ${props => props.$width ? (props.$width + "px") : "unset"};
    min-height: ${props => props.$minHeight}px;
    box-shadow: 3px 3px 5px 6px #ccc;
`
export interface SamModalProps extends PropsWithChildren {
    noBackground?: boolean;
    backColor?: string;
    // note: if autosized width is between min and max, it is used; if min=max it is used without size test
    minWidth?: number;      // default to 300
    maxWidth?: number;      // default to 600
    minHeight?: number;
    component?: React.FC;
    componentProps?: Record<string, any>;   // pass with component; required if component is valid
    closeBarColor?: string;             // only used if closeBarclicked is valid
    closeBarClicked?: () => void;       // pass this to show red X in upper right corner
}
const SamModal: React.FC<SamModalProps> = (props) => {
    const [width, setWidth] = React.useState(props.minWidth && props.maxWidth && props.minWidth === props.maxWidth ? props.minWidth : 0);

    const modalRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

    React.useEffect(() => {
        if (width === 0) {
            setWidth(modalRef.current.getBoundingClientRect().width + 20);
        }
    }, []);

    const modalRoot = document.getElementById("modal-root")!;
    if (!modalRoot) {
        console.log("Html does not contain modal-root div");
        return null;
    }
    return ReactDOM.createPortal(
        <>
            <GrayBG $noBackground={props.noBackground} />
            <Modal ref={modalRef} $isAutosizing={width === 0} $backColor={props.backColor}>
                <ModalContent $width={width} $minHeight={props.minHeight}>
                        {props.closeBarClicked && <CloseDialogBar color={props.closeBarColor} onClose={props.closeBarClicked} />}
                        {props.component ? (
                            <props.component {...props.componentProps} />
                        ) : (
                            <>
                                {props.children}
                            </>
                        )}
                </ModalContent>
            </Modal>
        </>,
        modalRoot
    );
}
//-------------------------------------------------------------
const MessageBoxContainer = styled.div<{ $width: number }>`
    width: ${props => props.$width}px;
    height: 250px;
    background-color: ${app.themes.backColor10};
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`
export enum ModalMessageResponseFlags { none = 0, yes = 1, no = 2, ok = 4, continue = 8, continueShopping = 16, home = 32, checkout = 64, addToCart = 128, cancel = 256 }   // these determine which buttons are shown
export interface ModalMessageBoxProps {
    caption: string;
    responseFlags: ModalMessageResponseFlags;
    width?: number;
    onSubmit: (result: ModalMessageResponseFlags) => void;
}
// NOTE: To set styles place them in app.themes.modalStyles
export const ModalMessageBox: React.FC<ModalMessageBoxProps> = (props) => {
    const width = props.width ?? 250;
    return (
        <SamModal>
            <MessageBoxContainer style={"modalStyles" in app.themes ? app.themes.modalStyles! : {}} $width={width}>
                <p dangerouslySetInnerHTML={{ __html: props.caption }} />
                <ButtonsRow>
                    {props.responseFlags & ModalMessageResponseFlags.yes &&
                        <IconButton caption="Yes" icon="fas fa-check" onClick={() => props.onSubmit(ModalMessageResponseFlags.yes)} />
                    }
                    {props.responseFlags & ModalMessageResponseFlags.no &&
                        <IconButton caption="No" icon="fas fa-ban" onClick={() => props.onSubmit(ModalMessageResponseFlags.no)} />
                    }
                    {props.responseFlags & ModalMessageResponseFlags.ok &&
                        <IconButton caption="OK" icon="fas fa-check" onClick={() => props.onSubmit(ModalMessageResponseFlags.ok)} />
                    }
                    {props.responseFlags & ModalMessageResponseFlags.addToCart &&
                        <IconButton caption="Add to cart" icon="fas fa-shopping-cart" onClick={() => props.onSubmit(ModalMessageResponseFlags.addToCart)} />
                    }
                    {props.responseFlags & ModalMessageResponseFlags.checkout &&
                        <IconButton caption="Proceed to checkout" icon="fas fa-shopping-cart" onClick={() => props.onSubmit(ModalMessageResponseFlags.checkout)} />
                    }
                    {props.responseFlags & ModalMessageResponseFlags.continue &&
                        <IconButton caption="Continue" icon="fas fa-check" onClick={() => props.onSubmit(ModalMessageResponseFlags.continue)} />
                    }
                    {props.responseFlags & ModalMessageResponseFlags.continueShopping &&
                        <IconButton caption="Continue shopping" icon="fas fa-check" onClick={() => props.onSubmit(ModalMessageResponseFlags.continueShopping)} />
                    }
                    {props.responseFlags & ModalMessageResponseFlags.home &&
                        <IconButton caption="Return to home page" icon="fas fa-home" onClick={() => props.onSubmit(ModalMessageResponseFlags.home)} />
                    }
                    {props.responseFlags & ModalMessageResponseFlags.cancel &&
                        <IconButton caption="Cancel" icon="fas fa-ban" onClick={() => props.onSubmit(ModalMessageResponseFlags.cancel)} />
                    }
                </ButtonsRow>
            </MessageBoxContainer>
        </SamModal>
    )
}
//-------------------------------------------------------------
const InputContainer = styled.div<{ $inputWidth: number }>`
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    input {
        margin-left: 8px;
        width: ${props => props.$inputWidth}px;
    }
`

interface ModalInputStringProps {
    caption: string;
    inputWidth?: number;        // default to 250
    minWidth?: number;
    minHeight?: number;
    default?: string;
    customButton?: { caption: string; icon?: string };
    onSubmit: (input: string | null) => void;
    onCustomButtonClick?: () => void;
}
export const ModalInputString: React.FC<ModalInputStringProps> = (props) => {
    const inputRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

    React.useEffect(() => {
        inputRef.current.focus();
    }, []);

    // CAUTION: handling Enter key may cause unexpected problems with setting selection
    const keyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            submitClicked();
        }
    }
    const submitClicked = () => {
        props.onSubmit(inputRef.current!.value);
    }
    const cancelClicked = () => {
        props.onSubmit(null);
    }
    return (
        <SamModal minWidth={props.minWidth} minHeight={props.minHeight}>
            <InputContainer $inputWidth={props.inputWidth ? props.inputWidth : 250}>
                <label>{props.caption}</label>
                <input ref={inputRef} onKeyDown={keyDown} defaultValue={props.default ? props.default : ''} />
            </InputContainer>
            <ButtonsRow>
                <IconButton caption="OK" icon="fas fa-check" onClick={submitClicked} />
                <IconButton caption="Cancel" icon="fas fa-ban" onClick={cancelClicked} />
                {props.customButton && <IconButton {...props.customButton} onClick={props.onCustomButtonClick} />}
            </ButtonsRow>
        </SamModal>
    )
}

export default SamModal;