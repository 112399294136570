import React from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import MasterPage from '../components/MasterPage';
import { useFetchApi, genericApiError } from '../libs/useDataApiV2';
import { StyledErrorText } from '../libs/libSupport';
import IconButton from '../libs/IconButtonV2';

import { InfoIndexRecord, InfoSectionTypeEnum } from '../interfaces/lib-api-interfaces';

import api from '../api-url';
import { formatImageOrVideoSrcWithFile } from '../libs/ImageFormatter';
import InfoContent from '../libs/InfoContent';

const Artists: React.FC = () => {
    const params = useParams();
    const url = params.url as string;
    return (
        <MasterPage>
            <h1>About the Artists</h1>
            {url === "index" ? (
                <ArtistsIndex />
            ) : (
                <React.Fragment>
                    <IconButton style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "16px" }} caption='Back to "About the Artists" index' onClick={() => window.location.href = "/artists/index"} />
                   <InfoContent url={url} />
                </React.Fragment>
            )}
        </MasterPage>
    )
}
//--------------------------------------
const IndexContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
`
const ArtistsIndex: React.FC = () => {
    const [data, setData] = React.useState<InfoIndexRecord[]>();
    const [errorMsg, setErrorMsg] = React.useState<string>();

    const { fetch } = useFetchApi();

    React.useEffect(() => {
        fetch(api.getInfoContent + InfoSectionTypeEnum.artists, null, (result: InfoIndexRecord[]) => setData(result), () => setErrorMsg(genericApiError))
    }, []);

    return (
        <IndexContainer>
            {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
            {data &&
                data.map(record => {
                    return (
                        <DisplayIndexRecord key={record.url} record={record} />
                    )
                })}
        </IndexContainer>
    )
}
const IndexRecordContainer = styled.div`
    display: flex;
    box-shadow: 3px 3px 5px 6px #ccc;
    margin-bottom: 20px;
    cursor: pointer;
`
const IndexCell = styled.div<{ $justify: string }>`
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: ${props => props.$justify};
    p {
        font-size: 13px;
        line-height: 18px;
        text-align: left;
    }
    div {
        display: flex;
        justify-content: center;
        width: 95%;
        height: 127px;
        margin-top: 8px;
        margin-bottom: 8px;
    }
    img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }
`
interface DisplayIndexRecordProps {
    record: InfoIndexRecord;
}
const DisplayIndexRecord: React.FC<DisplayIndexRecordProps> = (props) => {
    const navigate = useNavigate();
    const clicked = () => {
        navigate("/artists/" + props.record.url);
    }
    return (
        <IndexRecordContainer onClick={clicked}>
            <IndexCell $justify="center">
                <h2>{props.record.title}</h2>
            </IndexCell>
            <IndexCell $justify="center">
                <div>
                    {props.record.image && <img src={formatImageOrVideoSrcWithFile(props.record.image, { graphicsSubfolder: props.record.graphics_subfolder })} />}
                </div>
            </IndexCell>
            <IndexCell $justify="left">
                <p>{props.record.summary}</p>
            </IndexCell>
        </IndexRecordContainer>
    )
}
//--------------------------------------
export default Artists;
