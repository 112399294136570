import React from 'react';
import styled from 'styled-components';

import { usePostApi, genericApiError } from './useDataApiV2';
import { useGlobalContext, useCookies, useSessionStore } from './SamState';
import { ApiLoginRecord, FormFieldRecord, LoginError, TokenRecord } from '../interfaces/lib-api-interfaces';
import Spinner from './Spinner';
import SamForm from './forms/SamFormV5';
import IconButton from './IconButtonV2';
import FormMgr from './forms/FormMgr';
import { StyledErrorText } from './libSupport';

import api from '../api-url';
import app from '../appData';

export const authKey = "beta";        // stored in global state on success

const LoginForm = styled.div`
    width: 300px;
    margin: auto;
    display: flex; 
    flex-direction: column;
    align-items: center;
`

// if MasterPage finds token in global context this is skipped
// here if token is in cookies, it is sent to server; if valid token is placed in global context; if not valid credentials are requested
const CommonLogin: React.FC = () => {
    const [validating, setValidating] = React.useState(true);       // start with this message
    const [requestingCredentials, setRequestingCredentials] = React.useState(false);    // true if validation fails
    const [posting, setPosting] = React.useState(false);
    const [loginRecord, setLoginRecord] = React.useState<ApiLoginRecord>();
    const [errorMsg, setErrorMsg] = React.useState<string>();

    const { post } = usePostApi();
    const { getSessionStore, setSessionStore } = useSessionStore();
    const { getCookie, setCookie } = useCookies();
    const forms = new FormMgr(useGlobalContext().setContext);

    React.useEffect(() => {
        const token: TokenRecord | null = getCookie(authKey);
        if (token) {
            // token in cookie; validate it
            setPosting(true);
            setErrorMsg(undefined);
            post(api.validateToken, {token: token.token}, result => {
                setPosting(false);
                if (result) {
                    // token was valid
                    setCookie(authKey, token, 60);
                    setSessionStore(authKey, token);
                    setSessionStore("token", token);        // activate for dashboard use also
                } else {
                    setValidating(false);
                    setRequestingCredentials(true);
                }
            }, () => setErrorMsg(genericApiError));
        } else {
            setValidating(false);
            setRequestingCredentials(true);
        }
    }, []);

    React.useEffect(() => {
        if (loginRecord) {
            setPosting(true);
            setErrorMsg(undefined);
            post(api.dashboardLogin + app.domain, loginRecord,
                (token: TokenRecord, status: number | undefined) => {
                    setPosting(false);
                    setLoginRecord(undefined);
                    if (status === LoginError.noError) {
                        setCookie(authKey, token, 60);
                        setSessionStore(authKey, token);         // keep it here so we don't have to log in constantly
                        setSessionStore("token", token);        // activate for dashboard use also
                    } else {
                        setErrorMsg("Not found");
                    }
                }, () => setErrorMsg(genericApiError));
        }
    }, [loginRecord]);

    const formData: FormFieldRecord[] = [
        { name: "user_name", label: "User name", width: 99, validator: { required: true, maxLength: 50 } },
        { name: "password", label: "Password", width: 99, validator: { isPassword: true, required: true, maxLength: 50 } },
    ];

    const handleSubmit = () => {
        const values = forms.getFormValues("login");
        setLoginRecord(values as ApiLoginRecord);
    }

    if (getSessionStore(authKey)) {
        return null;
    } else if (getSessionStore("token")) {
        setSessionStore(authKey, getSessionStore("token"));
        return null;
    }

    return (
        <>
            {posting && <Spinner />}
            {validating && <p>Authorizing...</p>}
            {requestingCredentials &&
                <LoginForm>
                    <SamForm id="login" fields={formData} />
                    {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
                    <IconButton style={{ width: "100px", marginTop: "16px" }} caption="Sign in" icon="fas fa-check" onClick={handleSubmit} />
                </LoginForm>}
        </>
    )
}
export default CommonLogin;
