import React from 'react';
import styled from 'styled-components';

import { iPhoneWidth, iPadMaxWidth, getNearestParentId } from '../libs/libSupport';
import IconButton, { ButtonsRow } from '../libs/IconButtonV2';
import Magnifier from '../libs/SamMagnifyV3';
import useFGShoppingCart from '../libs/shopping-cart/useFGShoppingCart';
import useFGOrder from '../libs/shopping-cart/useFGOrder';
import { useGlobalContext } from '../libs/SamState';
import { showCartPopup } from '../libs/shopping-cart/CartPopup';
import PhotoViewer from '../libs/PhotoViewerWithModal';
import ModalInput from '../libs/ModalInput';
import { ModalMessageBox, ModalMessageResponseFlags } from '../libs/SamModalV2';
import { usePostApi, genericApiError } from '../libs/useDataApiV2';
import RifmNumeric from '../libs/forms/RifmNumeric';
import FeaturedProductGrid from './FeaturedProductGrid';

import { FGProductRecord, WhereItsMadeEnum } from '../interfaces/fg-api-interfaces';
import { FormFieldRecord, AddressType, FormFieldType, ImageRecord, ImageSizeEnum, VideoStreamSource } from '../interfaces/lib-api-interfaces';

import api from '../api-url';
import app from '../appData';
import { hideNavBarKey } from '../libs/NavBarV5';
import { formatImageUrl, formatVideoIframeSrc } from '../libs/ImageFormatter';

/* props:
    product = {
        caption: string
        price_cents: int
        sku_long: long
        bullet_points: string (newline delimited)
        related: long[] -- skus
        images: string[] -- element 0 is main image filename
        videos: [{ youtube_id: string, caption: string }]
    }
*/
/*  |----MasterContainer---------------------------|
    |-----------ImageAndTextContainer-------|
    |  ImageContainer | TextContainer       |
    |---------------------------------------|
    |-------------------------------------------|    
    |               VideoContainer              |
    --------------------------------------------|
    |               RelatedContainer            |
    |-------------------------------------------|
*/
const MasterContainer = styled.div<{ $maxWidth: number }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    max-width: ${props => props.$maxWidth}px;
    margin-left: auto;
    margin-right: auto;
    h2 {    /* price */
        font-size: 24px;
        margin-top: 8px;
        margin-bottom: 1em;
        font-weight: normal;
        text-align: center;
    }
    h3 { /* quantity label */
        font-size: 20px;
        margin-bottom: 12px;
        font-weight: normal;
        text-align: center;
    }
    h4 { /* stock status header */
        margin: 0;
        margin-bottom: 4px;
        font-size: 24px;
        font-weight: bold;
    }
    li {    /* bullet points */
        font-size: 18px;
        margin-top: 8px;
    }
    @media (max-width: ${iPhoneWidth}px) {
        h1 {
            font-size: 18px;
            margin-top: .5em;
            margin-bottom: .5em;
        }
        h2 {
            font-size: 18px;
            margin-top: .5em;
        }
        h3 {
            font-size: 16px;
        }
        li {
            font-size: 14px;
            margin-top: 4px;
        }
        p {
            font-size: 14px;
            margin: 0;
            text-align: center;
        }
    }
`
const ImageAndTextContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`
const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 50%;
    max-width: 700px;
    @media (max-width: ${iPhoneWidth}px) {
        max-width: ${iPhoneWidth - 20}px;
    }
`
const AltImagesContainer = styled.div<{ $imgHeight: number, $imgPadding: number }>`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
    cursor: pointer;
    img {
        height: ${props => props.$imgHeight}px;
        width: auto;
        padding: ${props => props.$imgPadding}px;
        border: 1px solid;
        margin-right: 8px;
    }
`
const TextContainer = styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
`
const WhereItsMadeText = styled.p`
    font-style: italic;
`
const QtyContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
const BulletPointText = styled.ul`
    text-align: left;
`
const VideoGrid = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
    width: 100%;
`
const VideoContainer = styled.figure`
    padding: .5%;
    width: 45%;
    iframe {
        aspect-ratio: 16/9;
        width: 100%;
    }
    figcaption {
        margin: 8px;
        text-align: center;
    }
`

interface ProductPageProps {
    product: FGProductRecord;
    //   forceRerender: () => void;
}
const ProductPage: React.FC<ProductPageProps> = (props) => {
    const [mainImageIndex, setMainImageIndex] = React.useState<number>(0);      // index into images
    const [containerWidth, setContainerWidth] = React.useState<number>(0);
    const [imageContainerWidth, setImageContainerWidth] = React.useState<number>(0);
    const [qty, setQty] = React.useState<number>(1);
    const [showUnderstock, setShowUnderstock] = React.useState(false);

    const cart = useFGShoppingCart();
    const order = useFGOrder();
    const { setContext } = useGlobalContext();

    const containerRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
    const imageContainerRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

    React.useEffect(() => {
        window.scrollTo(0, 0);
        setContainerWidth(containerRef.current.getBoundingClientRect().width);
        //    if (imageContainerRef.current) {
        setImageContainerWidth(imageContainerRef.current!.getBoundingClientRect().width);
        //     }
    }, []);

    const maxWidth = 1200;
    const altImagePadding = 8;
    const altImageHeight = 73;
    const ymalImageSize = 200;

    let buttonStyles = { marginLeft: "auto", marginRight: "auto", marginTop: "16px", marginBottom: "16px", width: "70%", height: "48px", fontSize: "24px" };
    if (window.matchMedia("(max-width: " + iPhoneWidth + "px)").matches) {
        buttonStyles.marginTop = "8px";
        buttonStyles.height = "36px";
        buttonStyles.fontSize = "18px";
        buttonStyles.width = "90%";
    }

    const handleAltImageClick = (e: React.MouseEvent<HTMLImageElement>) => {
        setMainImageIndex(parseInt(getNearestParentId(e.target as HTMLElement).id));
    }
    const handleEnlargedChanged = (isEnlarged: boolean) => {
        setContext(hideNavBarKey, isEnlarged);
    }
    const handleAddToCart = (forceAdd: boolean) => {
        if (!forceAdd && props.product.qoh < qty) {
            setShowUnderstock(true);
        } else {
            order.addCartItem({
                sku_long: props.product.sku_long, caption: props.product.caption, price: props.product.price,
                image: props.product.images ? props.product.images[0] : undefined, qty
            });
            showCartPopup(setContext, true);
        }
    }

    const showUnderstockCaption = "Our inventory count shows we only have " + (props.product.qoh - 4) + " of these left in stock. Please adjust your order, " +
        'or you can <a href="/contact-us">contact us</a> to ask when we might be getting more into stock.';

    const showUnderstockSubmit = (response: ModalMessageResponseFlags) => {
        setShowUnderstock(false);
        if (response === ModalMessageResponseFlags.addToCart) {
            handleAddToCart(true);
        }
    }
    const formatWhereItsMade = (source: WhereItsMadeEnum): string => {
        switch (source) {
            case WhereItsMadeEnum.australia: return "Made in Australia"; break;
            case WhereItsMadeEnum.canada: return "Made in Canada"; break;
            case WhereItsMadeEnum.ethical: return "Ethically made"; break;
            case WhereItsMadeEnum.europe: return "Made in Europe"; break;
            case WhereItsMadeEnum.fairTrade: return "Fair Traded"; break;
            case WhereItsMadeEnum.japan: return "Made in Japan"; break;
            case WhereItsMadeEnum.latinAmerica: return "Ethically made in Latin America"; break;
            case WhereItsMadeEnum.overseas: return "Made Overseas"; break;
            case WhereItsMadeEnum.thailand: return "Ethically made in Thailand"; break;
            case WhereItsMadeEnum.usa: return "Made in USA"; break;
        }
        return '';
    }

    // console.log("ProductPage: product:", props.product)

    if (window.matchMedia("(max-width: " + iPadMaxWidth + "px)").matches) {
        // we are on a mobile device
        return (
            <MasterContainer ref={containerRef} $maxWidth={maxWidth}>
                <h1>{props.product.caption}</h1>
                <WhereItsMadeText>{formatWhereItsMade(props.product.whereItsMade!)}</WhereItsMadeText>
                <h2>${(props.product.price!).toFixed(2)}</h2>
                <ImageContainer ref={imageContainerRef}>
                    <Magnifier mainImgSrc={formatImageUrl(props.product.images![mainImageIndex].filename!, { sizeDesignator: ImageSizeEnum.full })}
                        width={imageContainerWidth} height={imageContainerWidth} />
                    {props.product.images!.length > 1 &&
                        <AltImagesContainer $imgHeight={altImageHeight} $imgPadding={altImagePadding}>
                            {props.product.images!.map((image, index) => {
                                return (
                                    <img key={image.filename} id={index + ''} onClick={handleAltImageClick}
                                        src={formatImageUrl(image.filename!, { sizeDesignator: ImageSizeEnum.full })} alt={props.product.caption} />
                                )
                            })
                            }
                        </AltImagesContainer>}
                </ImageContainer>
                <InventoryBlock oos={props.product.oos} qoh={props.product.qoh} sku_long={props.product.sku_long} />
                {!props.product.oos &&
                    <React.Fragment>
                        <h3>Quantity</h3>
                        <QtyContainer>
                            <RifmNumeric name={props.product.caption!} fieldType={FormFieldType.int} initialValue={1} showChevrons={true} allowNegative={false} allowZero={false}
                                textAlign="center" fontSize={24} width={50} onChange={(newQty: number | string) => setQty(newQty as number)} />
                        </QtyContainer>
                        <IconButton caption="Add to cart" onClick={() => handleAddToCart(false)} icon="fas fa-shopping-cart" style={buttonStyles} />
                    </React.Fragment>
                }
                <BulletPointText>
                    {props.product.bullet_points!.map((text, index) => {
                        //       console.log(text)
                        return (
                            (text && text !== "<p></p>") ? <li key={index} dangerouslySetInnerHTML={{ __html: text }} /> : null
                        )
                    })}
                </BulletPointText>

                {props.product.videos && props.product.videos.length > 0 &&
                    <VideoGrid>
                        {props.product.videos.map((video) => {
                            return (
                                video.stream_id &&
                                <VideoContainer key={video.filename}>
                                    <iframe title="video" allow="web-share"
                                        src={formatVideoIframeSrc(VideoStreamSource.youtube, video.stream_id)} allowFullScreen></iframe>
                                    <figcaption>{video.caption}</figcaption>
                                </VideoContainer>
                            )
                        })}
                    </VideoGrid>
                }
                {showUnderstock &&
                    <ModalMessageBox
                        caption={showUnderstockCaption}
                        responseFlags={ModalMessageResponseFlags.addToCart | ModalMessageResponseFlags.cancel}
                        onSubmit={showUnderstockSubmit}
                    />
                }
            </MasterContainer>
        );
    } else {
        // desktop size
        const images: string[] = [];
        props.product.images!.forEach(image => {
            images.push(image.filename!);
        })
        return (
            <MasterContainer ref={containerRef} $maxWidth={maxWidth}>
                <ImageAndTextContainer>
                    <ImageContainer ref={imageContainerRef}>
                        <PhotoViewer photos={images} size={imageContainerWidth} onEnlargedChanged={handleEnlargedChanged} />
                    </ImageContainer>
                    <TextContainer>
                        <h1>{props.product.caption}</h1>
                        <WhereItsMadeText>{formatWhereItsMade(props.product.whereItsMade!)}</WhereItsMadeText>
                        <h2>${(props.product.price!).toFixed(2)}</h2>
                        <InventoryBlock oos={props.product.oos} qoh={props.product.qoh} sku_long={props.product.sku_long} />
                        {!props.product.oos &&
                            <React.Fragment>
                                {cart.isCartEnabled() ? (
                                    <React.Fragment>
                                        <h3>Quantity</h3>
                                        <QtyContainer>
                                            <RifmNumeric name={props.product.caption!} fieldType={FormFieldType.int} initialValue={1} showChevrons={true}
                                                allowNegative={false} allowZero={false} textAlign="center" fontSize={24} width={50} height={36}
                                                onChange={(newQty: number | string) => setQty(newQty as number)} />
                                        </QtyContainer>
                                        <IconButton caption="Add to cart" onClick={() => handleAddToCart(false)} icon="fas fa-shopping-cart" style={buttonStyles} />
                                    </React.Fragment>
                                ) : (
                                    <p><b>Set your browser to allow cookies to add item to your cart</b></p>
                                )}
                            </React.Fragment>}
                        <BulletPointText>
                            {props.product.bullet_points!.map((text, index) => {
                                return (
                                    (text && text !== "<p></p>") ? <li key={index} dangerouslySetInnerHTML={{ __html: text }} /> : null
                                )
                            })}
                        </BulletPointText>
                    </TextContainer>
                </ImageAndTextContainer>
                {props.product.videos && props.product.videos.length > 0 &&
                    <VideoGrid>
                        {props.product.videos.map((video) => {
                            return (
                                video.stream_id &&
                                <VideoContainer key={video.filename}>
                                    <iframe title="video" allow="web-share"
                                        src={formatVideoIframeSrc(VideoStreamSource.youtube, video.stream_id)} allowFullScreen></iframe>
                                    <figcaption>{video.caption}</figcaption>
                                </VideoContainer>
                            )
                        })}
                    </VideoGrid>
                }
                {props.product.ymal &&
                    <FeaturedProductGrid imageSize={ymalImageSize} header="You Might Also Like..." featured={props.product.ymal} />
                }
                {showUnderstock &&
                    <ModalMessageBox width={400}
                        caption={showUnderstockCaption}
                        responseFlags={ModalMessageResponseFlags.addToCart | ModalMessageResponseFlags.cancel}
                        onSubmit={showUnderstockSubmit}
                    />
                }
            </MasterContainer>
        );
    }
}
//                {showUnderstock && <ShowUnderstock qoh={props.product.qoh} showUnderstockSubmit={showUnderstockSubmit} />}

const SendEmailLinkText = styled.span`
    text-decoration: underline;
    cursor: pointer;
`
interface InventoryBlockProps {
    oos: boolean | undefined;
    qoh: number;
    sku_long: number;
}
const InventoryBlock: React.FC<InventoryBlockProps> = (props) => {
    const { post } = usePostApi();
    const order = useFGOrder();
    const [showOosDlg, setShowOosDlg] = React.useState(false);
    const [oosEmailRequestRcvd, setOosEmailRequestRcvd] = React.useState(false);

    const oosDlgFields: FormFieldRecord[] = [
        { name: "name", label: "Name" },
        { name: "email", label: "Email" }
    ];

    const sendOosEmail = (values: Record<string, any> | null) => {
        if (values) {
            post(api.fgRequestOosEmail, { name: values.name, email: values.email, sku_long: props.sku_long }, () => setOosEmailRequestRcvd(true), () => alert(genericApiError));
        }
        setShowOosDlg(false);
    }

    if (showOosDlg) {
        return (
            <ModalInput width={300} fields={oosDlgFields} allowCancel={true} backColor={app.themes.backColor10}
                initialValues={{ email: order.getBillEmail(), name: order.getAddress(AddressType.bill).fname }} onSubmit={sendOosEmail} />
        )
    } else if (props.oos) {
        return (
            oosEmailRequestRcvd ? (
                <p>You will receive an email as soon as this item is back in stock.</p>
            ) : (
                <React.Fragment>
                    <h4>This item is out of stock</h4>
                    <ButtonsRow>
                        <IconButton caption="Email me when back in stock" onClick={() => setShowOosDlg(true)} />
                    </ButtonsRow>
                </React.Fragment>
            )
        )
    }
    // else if (props.qoh < 5) {
    //     return (
    //         <h4>{props.qoh} left in stock</h4>
    //     )
    // }
    return null;
}
export default ProductPage;
