import { DivisionEnum } from './interfaces/lib-api-interfaces';
import { AppThemesRecord } from './interfaces/lib-react-interfaces';

const betaMode = true;    // when true we check server for test mode

const isDashboard = false;
const serverIp = 'https://fernsgarden.com';
const domain = "fernsgarden.com";
const loginRequired = false;

const adminCustId = 1;
const productGraphicsBaseUrl = (window.location.hostname === "localhost" ? process.env.PUBLIC_URL : serverIp) + "/graphics";

// themes is a standard object for passing to custom components
/* typical uses:
    backColor10 - input fields, forecolor when background is reversed
    backColor25 - buttons, logo, dropdown menus
    backColor50 - captions on grids (e.g., store near you)
*/
const sansFonts = "Roboto,Arial,Helvetica,sans-serif";
const color = "#4a5929";
const backColor50 = "#d3d584";  //  583 at 50%
const themes: AppThemesRecord = {
  serifFonts: "Volkhov,'Times New Roman',Times,George,Palatino,Garamond",
  sansFonts: sansFonts,
  color, // Pantone 574,
  backColor50,
  backColor25: "#e9ecc2",  //  583 at 25%
  backColor10: "#f6f8e6", // 583 at 10%
  navbarStyles: {
    bannerHoverStyles: {
      color,
      backgroundColor: backColor50
    },
    // popupStyles: {

    // },
    // popupHoverStyles: {

    // }
  },
  /*
  infoPageStyles: {
    fontSize: "14px",
    lineHeight: "22px",
    maxWidth: "900px",
    textAlign: "left"
  },
  navBarStyles {
      bannerStyles?: Record<string, string>;
    popupStyles?: Record<string, string>;
    bannerHoverStyles?: Record<string, string>;
    popupHoverStyles?: Record<string, string>;
  }
  */
  modalStyles: { fontSize: "16px", fontFamily: sansFonts, lineHeight: "24px", padding: "16px" }
};

const banner = `<b>FREE SHIPPING ON ALL ORDERS OVER $25</b>`;
const logoUrl = "logo_with_tagline.jpg";
const altLogo = "Fern's Garden";
const division = DivisionEnum.fg;
const companyName = "Fern's Garden";
const companyPhone = "(510) 529-4848";
const facebookUrl = "https://www.facebook.com/FernsGardenGallery/";
const pinterestUrl = "https://www.pinterest.com/fernsgarden/boards/";
const instagramUrl = "https://www.instagram.com/fernsgarden/";
const twitterUrl = "https://twitter.com/fernsgarden";

export default {
  betaMode,
  loginRequired,
  isDashboard,
  serverIp,
  domain,
  adminCustId,
  productGraphicsBaseUrl,
  themes,
  banner,
  logoUrl,
  altLogo,
  division,
  companyName,
  companyPhone,
  facebookUrl,
  pinterestUrl,
  instagramUrl,
  twitterUrl,

}
